import React,{useState} from 'react';
import './MemberConnectAgent.css';
import MemberConnectAgentDetails from '../components/MemberConnectAgentDetails';

function MemberConnectAgent() {
    const [title, setTitle] = useState('Member Connect');

  return (
    <>
         <section className='ContentMemberConnectAgent'>
        <MemberConnectAgentDetails/>
        </section>      
    </>
  );
}

export default MemberConnectAgent;
