import  common_axios  from "./commonAxios";

let baseURL =   common_axios.defaults.baseURL;

//fetch data program tab MTP
export const fetch_program_dashboard_summery_list = (labelValue,startDate,endDate,labelDepth) => {
    console.log("fetch_program_dashboard_summery_list",baseURL);
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_program_dashboard_summery_list?labelValue='+labelValue+'&startDate='+startDate+'&endDate='+endDate+'&labelDepth='+labelDepth);
    return dataPass;
};

export const fetch_frequency_selected_service_data = (labelValue,labelDepth) => {
    console.log("fetch_frequency_selected_service_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_frequency_selected_service_data?labelValue='+labelValue+'&labelDepth='+labelDepth);
    return dataPass;
};



//fetch role master data MTP
export const fetch_role_data = () => {
    console.log("fetch_role_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_role_data');
    return dataPass;
};

//fetch program master data MTP
export const fetch_program_data = () => {
    console.log("fetch_program_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_program_data');
    return dataPass;
};

//fetch service master data MTP

export const fetch_service_data = () => {
    console.log("fetch_service_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_service_data');
    return dataPass;
};


export const fetch_admin_user_data = () => {
    console.log("fetch_admin_user_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_admin_user_data');
    return dataPass;
};


//To get admin user information
export const mtp_fetch_admin_user_data = (userId) => {
    console.log("mtp_fetch_admin_user_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'mtp/mtp_fetch_admin_user_data?userId='+userId);
    return dataPass;
};

//To check username already exist.
export const mtp_check_username_available = (userName) => {
    console.log("mtp_check_username_available",baseURL);
    var dataPass = common_axios.get( baseURL + 'mtp/mtp_check_username_available?userName='+userName);
    return dataPass;
};

//To get pathway data with frequency services
export const fetch_pathway_frequency_data = (pathwayId) => {
    let params = {'pathwayId':pathwayId};
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_pathway_frequency_data_v2',params);
    return dataPass;
};

//To get member communication data
export const fetch_member_communication_data = () => {
    console.log("fetch_member_communication_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_member_communication_data');
    return dataPass;
};

//To get whatsapp template data
export const fetch_whatsapp_template_data = () => {
    console.log("fetch_whatsapp_template_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_whatsapp_template_data');
    return dataPass;
};

//To get selected whatapp template data
export const single_whatsapp_template = (templateId) => {
    console.log("get_single_whatsapp_template",templateId);
    var dataPass = common_axios.get( baseURL + 'mtp/single_whatsapp_template?templateId='+templateId);
    return dataPass;
};

//get condition from program data
export const fetch_program_condition_data = (programId) => {
    console.log("fetch_program_condition_data",programId);
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_program_condition_data?programId='+programId);
    return dataPass;
};

// get pathway data from condition
export const fetch_program_condition_pathway_data = (conditionId) => {
    console.log("fetch_program_condition_pathway_data",conditionId);
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_program_condition_pathway_data?conditionId='+conditionId);
    return dataPass;
};


//post data user creation MTP
export const mtp_user_creation_data = (formData) => {
    console.log("mtp_user_creation_data",formData);
    var dataPass = common_axios.post( baseURL + 'mtp/mtp_user_creation_data',formData);
    return dataPass;
};

//post data for program (codition,pathway) frequency creation MTP
export const program_creation_frequency_data = (formData) => {
    console.log("program_creation_frequency_data",formData);
    var dataPass = common_axios.post( baseURL + 'mtp/program_creation_frequency_data',formData);
    return dataPass;
};

//create frequency configuration(services data assign) template MTP
export const frequency_configuration_template_creation = (formData) => {
    console.log("frequency_configuration_template_creation",formData);
    var dataPass = common_axios.post( baseURL + 'mtp/frequency_configuration_template_creation',formData);
    return dataPass;
};



//Update data admin user MTP
export const mtp_admin_user_update_data = (userId,formData) => {
    console.log("mtp_admin_user_update_data",formData);
    var dataPass = common_axios.post( baseURL + 'mtp/mtp_admin_user_update_data?userId='+userId,formData);
    return dataPass;
};


export const fetch_ad_users_list = (searchString) => {
    console.log("mtp_admin_user_update_data",searchString);
    var requestBody={searchString};
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_ad_users',requestBody);
    return dataPass;
};
export const add_new_services = (formData) => {
    console.log("add_new_services",formData);
    var dataPass = common_axios.post( baseURL + 'mtp/add_new_services',formData);
    return dataPass;
};

export const frequency_service_update = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/frequency_service_update_v2',formData);
    return dataPass;
};

export const fetch_single_frequency_configuration_template = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_single_frequency_configuration_template',formData);
    return dataPass;
};

export const fetchCategoryData = () => {
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_category_master');
    return dataPass;
};

export const addService = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/new_service_add',formData);
    return dataPass;
};

export const fetchAllDataService = () => {
    var dataPass = common_axios.get( baseURL + 'mtp/show_service_list');
    return dataPass;
};

export const fetchServiceRequestTypeList = () => {
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_service_request_type_list');
    return dataPass;
};

export const fetchAllDataCategoryService = () => {
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_category_service_list');
    return dataPass;
};

export const addProgramConditionPathway = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/add_program_condition_pathway',formData);
    return dataPass;
};

export const editService = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/new_service_edit',formData);
    return dataPass;
};

export const fetchAllDeleteDataService = (serviceId) => {
    let params = {'serviceId':serviceId}
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_delete_data_service',params);
    return dataPass;
};

export const fetchAllUserDataService = (serviceId,pathwayServiceId) => {
    let params = {'serviceId':serviceId,'pathwayServiceId':pathwayServiceId}
    var dataPass = common_axios.post( baseURL + 'mtp/user_view_data_service',params);
    return dataPass;
};

export const fetchAllUserDeleteDataServiceLength = (serviceId,pathwayServiceId,pathwayMonth) => {
    console.log("pathwayMonth",pathwayMonth);
    let params = {'serviceId':serviceId,'pathwayServiceId':pathwayServiceId,'pathwayMonth':pathwayMonth}
    var dataPass = common_axios.post( baseURL + 'mtp/user_delete_data_service_length',params);
    return dataPass;
};

export const fetchAllUserDeleteDataService = (serviceDeleteData) => {
    let params = {'serviceId':serviceDeleteData.serviceId,'pathwayServiceId':serviceDeleteData.pathwayServiceId,'memberServiceRelId':serviceDeleteData.memberServiceRelId,'pathwayMonth':serviceDeleteData.pathwayMonth}
    var dataPass = common_axios.post( baseURL + 'mtp/user_delete_data_service',params);
    return dataPass;
};

export const fetchUserExistService = () => {
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_user_exist_service');
    return dataPass;
};

export const fetchLinkUserDataService = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_link_user_data',formData);
    return dataPass;
};

//add program
export const addNewProgram = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/add_new_program',formData);
    return dataPass;
};

//fetch program data
export const editFetchNewProgram = (programId) => {
    let params = {'programId':programId}
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_edit_program',params);
    return dataPass;
};

//edit program
export const editNewProgram = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/edit_new_program',formData);
    return dataPass;
};

//delete program
export const removeNewProgram = (programId) => {
    let params = {'programId':programId}
    var dataPass = common_axios.post( baseURL + 'mtp/program_delete_data',params);
    return dataPass;
};

//add condition
export const AddNewCondition = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/add_new_condition',formData);
    return dataPass;
};

//fetch condition data 
export const editFetchNewCondition = (conditionId) => {
    let params = {'conditionId':conditionId}
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_edit_condition',params);
    return dataPass;
}

//edit condition
export const editNewCondition = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/edit_new_condition',formData);
    return dataPass;
};

//delete condition
export const removeNewCondition = (conditionId) => {
    let params = {'conditionId':conditionId}
    var dataPass = common_axios.post( baseURL + 'mtp/condition_delete_data',params);
    return dataPass;
};

//Add pathway
export const AddNewPathway = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/add_new_pathway',formData);
    return dataPass;
};

//edit pathway
export const editNewPathway = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/edit_new_pathway',formData);
    return dataPass;
};

//fetch pathway data 
export const editFetchNewPathway = (pathway) => {
    let params = {'pathwayId':pathway}
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_edit_pathway',params);
    return dataPass;
}


//delete pathway 
export const deleteNewPathway = (pathwayId) => {
    let params = {'pathwayId':pathwayId}
    var dataPass = common_axios.post( baseURL + 'mtp/pathway_delete_data',params);
    return dataPass;
};

//fetch draft program data
export const draft_program_data = () => {
    var dataPass = common_axios.get( baseURL + 'mtp/draft_program_data');
    return dataPass;
};

//publish program
export const publishNewProgram = (programId) => {
    let params = {'programId':programId}
    var dataPass = common_axios.post( baseURL + 'mtp/publish_new_program',params);
    return dataPass;
};


//publish conditions
export const publishNewCondition = (conditionId) => {
    let params = {'conditionId':conditionId}
    var dataPass = common_axios.post( baseURL + 'mtp/publish_new_condition',params);
    return dataPass;
};

//publish pathway
export const publishNewPathway = (pathwayId) => {
    let params = {'pathwayId':pathwayId}
    var dataPass = common_axios.post( baseURL + 'mtp/publish_new_pathway',params);
    return dataPass;
};

//display error message condition
export const displayMessageCondition = (conditionId) => {
    let params = {'conditionId':conditionId}
    var dataPass = common_axios.post( baseURL + 'mtp/display_message_condition',params);
    return dataPass;
};

//display error message pathway
export const displayMessagePathway = (pathwayId) => {
    let params = {'pathwayId':pathwayId}
    var dataPass = common_axios.post( baseURL + 'mtp/display_message_pathway',params);
    return dataPass;
};











