import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import MembersManagedBar from './componenents/DashboardGraphs/MembersManagedBar';
import ServiceUtilizationRatePie from './componenents/DashboardGraphs/ServiceUtilizationRatePie';
import ComparisonScheduledbar from './componenents/DashboardGraphs/ComparisonScheduledbar';
import AppointmentAdherenceRateGauge from './componenents/DashboardGraphs/AppointmentAdherenceRateGauge';
import TaskCompletionRateBar from './componenents/DashboardGraphs/TaskCompletionRateBar';
import FollowUpCompletionRateLine from './componenents/DashboardGraphs/FollowUpCompletionRateLine';


function DashboardGraphs() {

    const renderCn1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members assigned to care navigators.
        </Tooltip>
    );

    const renderCn2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of care navigators.
        </Tooltip>
    );

    const renderCn3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Average number of members enrolled per care navigator.
        </Tooltip>
    );

    const renderCn4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Proportion of utilized vs. non-utilized services.
        </Tooltip>
    );

    const renderCn5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
          Number of scheduled vs. completed calls.
        </Tooltip>
    );

    const renderCn6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
          Adherence to scheduled appointments.
        </Tooltip>
    );

    const renderCn7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
         Task completion rate for each care navigator.
        </Tooltip>
    );

    const renderCn8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
         Completion rate of scheduled follow-ups.
        </Tooltip>
    );

  return (
    <>
      <section className="ContentMemberConnectAgent mb-5 pb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">

              <div className="dashboard-graph-columns">


                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Members Managed</span> 
                        <OverlayTrigger placement="top" overlay={renderCn1Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <MembersManagedBar/>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Care Navigators</span> 
                        <OverlayTrigger placement="top" overlay={renderCn2Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>
                    <div className='dashboard-graph-box-content'>
                        58
                    </div>
                    <h3 className='dashboard-graph-subtitle'>Total Care Navigators</h3>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Average Member Enrollment per Care Navigator</span> 
                        <OverlayTrigger placement="top" overlay={renderCn3Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>
                    <div className='dashboard-graph-box-content'>
                        58
                    </div>
                    <h3 className='dashboard-graph-subtitle'>Total members enrolled</h3>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Service Utilization Rate</span> 
                        <OverlayTrigger placement="top" overlay={renderCn4Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <ServiceUtilizationRatePie/>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Comparison Calls</span> 
                        <OverlayTrigger placement="top" overlay={renderCn5Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <ComparisonScheduledbar/>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Appointment Adherence Rate</span> 
                        <OverlayTrigger placement="top" overlay={renderCn6Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <AppointmentAdherenceRateGauge/>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Task Completion Rate</span> 
                        <OverlayTrigger placement="top" overlay={renderCn7Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <TaskCompletionRateBar/>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Follow-Up Completion Rate</span> 
                        <OverlayTrigger placement="top" overlay={renderCn8Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <FollowUpCompletionRateLine/>
                </div>
               

              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DashboardGraphs;
