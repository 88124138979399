import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

exporting(Highcharts);

const FollowUpCompletionRateLine = () => {
  // Updated example data for follow-up completion rates
  const followUpData = [
    { month: 'Jan', completionRate: 75 },
    { month: 'Feb', completionRate: 80 },
    { month: 'Mar', completionRate: 78 },
    { month: 'Apr', completionRate: 85 },
    { month: 'May', completionRate: 90 },
    { month: 'Jun', completionRate: 88 }
  ];

  const optionsCN8 = {
    chart: {
      type: 'line',
      height: 220,
      spacing: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 15,
        depth: 50,
        viewDistance: 25 // Adjusted viewDistance for better 3D effect
      }
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    xAxis: {
      categories: followUpData.map(data => data.month),
      gridLineWidth: 0
    },
    yAxis: {
      title: {
        text: null
      }
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.x + '</b><br/>' +
          'Completion Rate: ' + this.y + '%';
      }
    },
    series: [{
      name: 'Follow-Up Completion Rate',
      data: followUpData.map(data => data.completionRate),
      color: '#4caf50'
    }],
    credits: {
      enabled: false
    },
    legend: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsCN8}
      />
    </div>
  );
};

export default FollowUpCompletionRateLine;
