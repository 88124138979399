import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import './PathNotResolved.css';


function PathNotResolved() {

    

  return (
    <>
    <section className='PathNotResolved'>
        <div className='row'>
            <div className='col-md-12'>
            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/glwh3/hjthzlv2se.png" className="img-fluid logo-ksa" alt="" />
            </div>
            <div className='col-md-12 text-center'>
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/da5hg/gu7h5arhbj.png" className='banner-img-noaccess' alt="" />
                <h1 className='title my-4'>Path not resolved</h1>
                <Link className='btn btn-primary add-new-program-btn' to='/logout'>Back to Dashboard</Link>
            </div>
            <div className='col-md-12 text-end'>
            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/s2ghb/6sxs8s44bs.png" alt=""  style={{height:"42px"}}/>
                
            </div>
        </div>
    </section>

        {/* <section className='LoginPage'>
            <div className='language-setting'>
                <LanguageSetting/>
            </div>
            <div className="card-login">
                <div className="card-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 ps-md-0 pe-md-5 en-ar-text-align">
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/glwh3/hjthzlv2se.png" className="img-fluid logo-ksa" alt="" />
                                <h1 className="login-title mb-4 ">Bupa Medical Team Platform</h1>
                                <h4>You dont have Access</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
    </>
  );
}

export default PathNotResolved;
