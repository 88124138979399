import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from "../../../../../node_modules/highcharts/highcharts-3d";
// import 'bootstrap/dist/css/bootstrap.css';
import { fetchLineData } from "../../../../services/cn/Service";
import './Line.css';

import highchartsMore from 'highcharts/highcharts-more'; // Import highcharts-more module
import highchartsExporting from 'highcharts/modules/exporting'; // Import exporting module


highcharts3d(Highcharts);
highchartsMore(Highcharts);
highchartsExporting(Highcharts);

const Line = (props) => {
    let ct;
    const [apiResult, setApiResult] = useState([]);

    const lineGraphTitle = (props.lineGraphTitle !== "") ? props.lineGraphTitle : "";
    const lineGraphType = props.lineGraphType;
    const extraParams = (props.extraParams) ? props.extraParams : {};
    const userId = (props.userId) ? props.userId : "";
    const refreshVar = (props.refreshVar) ? props.refreshVar : new Date().getTime();

    extraParams['userId'] = userId;
    useEffect(() => {
        getLineData();
    }, [lineGraphType, refreshVar])

    const getLineData = async () => {
        const request = axios.CancelToken.source()
        ct = {
            cancelToken: request.token
        }
        await fetchLineData(lineGraphType, extraParams, ct)
            .then((response) => {
                if (response.data) {
                    setApiResult(response.data.data);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel()
    }

    const options = {
        chart: {
            type: 'line',
            height: '250px'
        },
        tooltip: {
            enabled: false
        },
        title: {
            text: null,
            align: 'left'
        },
        xAxis: {
            categories: (apiResult.xAxis) ? apiResult.xAxis : [],
        },
        series: (apiResult.series) ? apiResult.series : [],
        credits: {
            enabled: false
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            },
            labels: {
                enabled: false
            },
            gridLineWidth: 0
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: '450'
                    }
                }
            ]
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            line: {
                lineWidth: 2,
                dashstyle: 'ShortDot',
                showInLegend: false,
                states: {
                    hover: {
                        lineWidth: 3
                    }
                },
                marker: {
                    enabled: true
                }
            },

            series: {
                animation: false,
                dataLabels: {
                    enabled: true,
                    style: {
                        textOutline: 'none'
                    }
                }
            }
        },
        exporting: {
            enabled: false
        }
    };

    return (
        <div style={{ position: "relative" }} className='mt-2'>
            {
                (lineGraphType === "weight_history" || lineGraphType === "hba1c_history") &&
                <div className="card-text row align-items-center">
                    <div className='col-auto'>
                        {
                            lineGraphType === "weight_history" &&
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/naw45/5jl8k9t48u.png" style={{ height: "36px" }} alt="" />
                        }
                        {
                            lineGraphType === "hba1c_history" &&
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3at4j/roxhmav3rh.png" style={{ height: "36px" }} alt="" />
                        }
                    </div>
                    <div className='col ps-0'>
                        <h5 className='card-title'>{lineGraphTitle}</h5>
                        <h5 className='card-subtitle'>{apiResult.heading} <span style={{ color: apiResult.color }}>{apiResult.colorText}</span></h5>
                    </div>
                </div>
            }

            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            {
                (lineGraphType === "weight_history" || lineGraphType === "hba1c_history") &&
                <div className='col-12 mt-3 text-center'>
                    <h5 className='card-subtitle mb-3'><i className={`bi ${apiResult.arrow}`}></i> {apiResult.subText}</h5>
                </div>
            }
        </div>
    );
};

export default Line;