import React, { useState, useRef, useEffect } from 'react';
import { useUserTabs } from '../components/common/UserTabsContext';
import axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Dashboard.css'
import TopBar from '../components/topbar/TopBar';
import UsersList from '../components/userslist/UsersList';
import Pie from '../components/graphs/Pie';
import Column from '../components/graphs/Column';
import WorkingPlansAndBreaks from '../components/workingplansandbreaks/WorkingPlansAndBreaks';
import TodaysAppointmentProgressBar from './TodaysAppointmentProgressBar';
import ContentNewReports from './ContentNewReports';
import ContentOtherTasks from './ContentOtherTasks';
import AppointmentSchedule from './AppointmentSchedule';
import { Link } from 'react-router-dom';
import { fetchTodayAppointmentsList } from "../components/services/Service";
import './AppointmentsReport.css';

const ContentAppointments = ({ onUserClick }) => {
    const [todaysAppointmentListResult, setTodaysAppointmentListResult] = useState([]);
    useEffect(() => {
        getTodaysAppointemntsList();
    }, [])

    const getTodaysAppointemntsList = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchTodayAppointmentsList(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setTodaysAppointmentListResult(response.data.data.appointments);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    {/* <div className="no-data-box">
                        <h5>No appointments right now.</h5>
                        <h6 className='card-title1 '>Your appointments will appear here.</h6>
                    </div> */}
                    {/* <div className='col-md-12 px-0'>
                        <h2>No appointments right now.</h2>
                        <h3>Here are some tasks for you to complete today.</h3>
                    </div> */}
                    <div className='col-md-12 px-0'>
                        <h2>Appointments</h2>
                        <h3>{`${todaysAppointmentListResult.length > 0 ? "Here are some appointments for you to complete today" : "No appointments for today"}`}</h3>
                    </div>
                    <div className='col-md-12 px-0 mt-2' style={{ maxHeight: "392px", overflowX: "hidden", overflowY: "scroll" }}>
                        {
                            todaysAppointmentListResult.map((item, index) => {
                                return <div key={index} className='row align-items-center mb-2'>
                                    <div className='col-auto'>
                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/tula8/flf474t0yh.png" className="icon-appointments-report" alt="" />
                                    </div>
                                    <div className='col ps-0'>
                                        <Link to={'/members/' + encodeURIComponent(item.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: item.userId, name: item.memberName })}><h4>{item.desc}</h4></Link>
                                        {/*<p>First consultation New member.</p>*/}
                                    </div>
                                    <div className='col-3'>
                                        <p className='text-end'>Today <br /> {item.time}</p>
                                    </div>
                                </div>
                            })
                        }


                        {/*<div className='row align-items-center mb-2'>
                            <div className='col-auto'>
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/tula8/flf474t0yh.png" className="icon-appointments-report" alt="" />
                            </div>
                            <div className='col ps-0'>
                                <h4>Consultation with Aamina at</h4>
                                <p>3rd month Follow up consultation.</p>
                            </div>
                            <div className='col-3'>
                                <p className='text-end'>Today <br /> 4:00 pm</p>
                            </div>
                        </div>

                        <div className='row align-items-center mb-2'>
                            <div className='col-auto'>
                                <img src={require("../../images/icon-birthday.png")} className="icon-appointments-report" alt="" />
                            </div>
                            <div className='col ps-0'>
                                <h4>Consultation with Kareema</h4>
                                <p>3rd month Follow up consultation.</p>
                            </div>
                            <div className='col-3 '>
                                <p className='text-end'>Tomorrow <br /> 4:00 pm</p>
                            </div>
                        </div>

                        <div className='row align-items-center mb-2'>
                            <div className='col-auto'>
                                <img src={require("../../images/icon-doctor.png")} className="icon-appointments-report" alt="" />
                            </div>
                            <div className='col ps-0'>
                                <h4>Consultation with Kareema</h4>
                                <p>3rd month follow up consultation.</p>
                            </div>
                            <div className='col-3'>
                                <p className='text-end'>Tomorrow <br /> 4:00 pm</p>
                            </div>
                        </div>

                        <div className='row align-items-center mb-2'>
                            <div className='col-auto'>
                                <img src={require("../../images/icon-doctor.png")} className="icon-appointments-report" alt="" />
                            </div>
                            <div className='col ps-0'>
                                <h4>Consultation with Kareema</h4>
                                <p>3rd month follow up consultation.</p>
                            </div>
                            <div className='col-3'>
                                <p className='text-end'>Tomorrow <br /> 4:00 pm</p>
                            </div>
                        </div>*/}


                    </div>
                </div>
            </div>
        </>
    );
};

export default ContentAppointments;
