import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);

const ObservationsBar = () => {
  // Updated static data
  const programs = ['Module 1', 'Module 2', 'Module 3', 'Module 4'];
  const observationsData = [12, 19, 8, 5];
  const colors = ['#007BFF', '#5D70E3', '#371F89', '#8295FA'];

  const yAxisJson = {
    title: {
      text: ''
    }
  };

  const tooltipJson = {
    shared: true,
    valueSuffix: ' observations'
  };

  const columnData = [{
    name: 'Number of Observations',
    data: observationsData,
    colorByPoint: true,
    colors: colors
  }];

  const options1 = {
    chart: {
      type: 'column',
      height: 220,
      spacing: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 15,
        depth: 50,
        viewDistance: 25 
      }
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    xAxis: {
      categories: programs,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 20,
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: columnData,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options1}
      />
    </div>
  );
};

export default ObservationsBar;
