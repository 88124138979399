import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { displayRemoveMemberList} from '../../../services/rcm/Service';
import { CustomLoader, decruptCipherJson } from "../../utils/CommonLibrary";
import moment from "moment";
import Tooltip from 'react-bootstrap/Tooltip';
import { decryptData } from "../../utils/Utils";
import RemoveMemberDateTable from "./RemoveMemberDateTable";
import { Link } from "react-router-dom";

function RCMRemoveUntouchList() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    const [loading, setLoading] = useState('');
    const [logData, setLogData] = useState([]);


    useEffect(() => {
        fetchData();
    }, [startDate,endDate]);
    
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    
    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };  

    const fetchData = async () => {
        setLoading(true);
        displayRemoveMemberList(formatDate(startDate),formatDate(endDate))
        .then((response) => {
            // let showData = decruptCipherJson(response.data.data);
            setLogData(response.data.data.listQueryResult);
            setLoading(false);
        });
    }

   
    
    return (
        <>
            <section className='ReportsPrograms'>
                <div className="container-fluid px-0 mb-3 ">
                    <div className="row align-items-center pb-3 sticky-top-bar">
                        <div className="col-md ">
                            <h1 className="main-title">Untouched Members Cleanup</h1>
                        </div>
                        <div className="col-md text-end">
                        <Link to="/remove-untouch" className="btn btn-primary add-new-program-btn">
                            <i className="bi bi-person-plus-fill me-2"></i> Remove Untouched Members
                        </Link>
                    </div>
                    </div>
                    
                    <div className='reports-programs mb-3'>
                        <div className=" row align-items-center">
                        <div className="col-md-auto">
                                <img src="https://storage.googleapis.com/ksabupatest/2024/10/17/chdlz/x8hjchkoxj.png" alt="Invited Members icon" style={{height:"140px"}}/>
                            </div>
                            <div className="col-md">
                                <p className="mb-0">Identify and remove members who were mistakenly uploaded and have had no engagement since upload.</p>
                            </div>
                        </div>
                    </div>

                    <div className='reports-programs mb-3'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <RemoveMemberDateTable logData={logData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            { loading ? <CustomLoader /> : null }
        </>
    );
}

export default RCMRemoveUntouchList;
