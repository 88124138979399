import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const TemplateItem = ({ template, onSelect, isSelected }) => (
  <div 
    className={`tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-mb-2 tw-cursor-pointer hover:tw-bg-gray-100 ${isSelected ? 'tw-bg-blue-100' : ''}`}
    onClick={() => onSelect(template)}
  >
    {template.name}
  </div>
);

const TemplateMockup = ({ template, type }) => {
  const mockupContent = (
    <div className="tw-p-4 tw-bg-white tw-rounded-xl tw-overflow-hidden tw-w-[272px] tw-h-[572px]">
      <div className="tw-mb-2 tw-text-sm tw-font-bold">Today</div>
      <div className="tw-bg-blue-100 tw-p-2 tw-rounded-lg tw-mb-2">
        <p>{template.body}</p>
        {template.footer && <p className="tw-mt-2 tw-text-sm tw-text-gray-600">{template.footer}</p>}
        {template.buttons && template.buttons.map((button, index) => (
          <button key={index} className="tw-bg-blue-500 tw-text-white tw-px-2 tw-py-1 tw-rounded tw-mt-2 tw-text-sm">
            {button.text}
          </button>
        ))}
      </div>
    </div>
  );

  if (type === 'ios') {
    return (
      <div className="tw-relative tw-mx-auto tw-border-gray-800 tw-bg-gray-800 tw-border-[14px] tw-rounded-[2.5rem] tw-h-[600px] tw-w-[300px] tw-shadow-xl">
        <div className="tw-w-[148px] tw-h-[18px] tw-bg-gray-800 tw-top-0 tw-rounded-b-[1rem] tw-left-1/2 tw--translate-x-1/2 tw-absolute"></div>
        <div className="tw-h-[46px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--start-[17px] tw-top-[124px] tw-rounded-s-lg"></div>
        <div className="tw-h-[46px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--start-[17px] tw-top-[178px] tw-rounded-s-lg"></div>
        <div className="tw-h-[64px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--end-[17px] tw-top-[142px] tw-rounded-e-lg"></div>
        <div className="tw-rounded-[2rem] tw-overflow-hidden tw-w-[272px] tw-h-[572px] tw-bg-white">
          {mockupContent}
        </div>
      </div>
    );
  } else {
    return (
      <div className="tw-relative tw-mx-auto tw-border-gray-800 tw-bg-gray-800 tw-border-[14px] tw-rounded-xl tw-h-[600px] tw-w-[300px] tw-shadow-xl">
        <div className="tw-w-[148px] tw-h-[18px] tw-bg-gray-800 tw-top-0 tw-rounded-b-[1rem] tw-left-1/2 tw--translate-x-1/2 tw-absolute"></div>
        <div className="tw-h-[32px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--start-[17px] tw-top-[72px] tw-rounded-s-lg"></div>
        <div className="tw-h-[46px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--start-[17px] tw-top-[124px] tw-rounded-s-lg"></div>
        <div className="tw-h-[46px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--start-[17px] tw-top-[178px] tw-rounded-s-lg"></div>
        <div className="tw-h-[64px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--end-[17px] tw-top-[142px] tw-rounded-e-lg"></div>
        <div className="tw-rounded-xl tw-overflow-hidden tw-w-[272px] tw-h-[572px] tw-bg-white">
          {mockupContent}
        </div>
      </div>
    );
  }
};

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-z-[9999]">
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-w-11/12 tw-max-w-4xl tw-h-[80vh] tw-flex tw-flex-col tw-relative">
        {children}
      </div>
    </div>,
    document.body
  );
};

const CreateMessage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [previewType, setPreviewType] = useState('android');

  const templates = [
    { name: 'coach_message_1', body: 'Hi {{1}}, I am your coach {{2}} we will be doing your lifestyle changes on here. Please let me know if you have any diet restrictions or allergies.' },
    { name: 'coach_message_2_ut', body: 'Good morning, Ready to make some lifestyle changes?' },
    { name: 'live_welcome_1_rev', body: "Hello 👋\n\nYou're on the verge of a significant health breakthrough!\n\nWith GOQii, you're tapping into a comprehensive health platform that will support you every step of the way on your {{1}} journey. 🌟\n\nImagine easy access to personalised health insights, coaching & a supportive community—all within the GOQii app! This journey is about more than just managing diabetes; it's about embracing a vibrant, healthier life. 💪 🌿\n\nReady to start this new chapter together? Let's do this! 🚀", footer: '#BETHEFORCE', buttons: [{ text: 'Download GOQii', url: 'https://link.interakt.ai/api/wa/{{1}}' }] },
  ];

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template);
  };

  const handleChooseTemplate = () => {
    // Here you would typically save the selected template or perform any other necessary actions
    setIsModalOpen(false);
  };

  return (
    <div className="tw-relative">
      <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Message</h3>
      <div className="tw-border tw-border-gray-300 tw-rounded tw-p-4 tw-mb-4 tw-text-center tw-text-gray-500">
        {selectedTemplate ? selectedTemplate.name : 'No Template Selected yet'}
      </div>
      <button 
        className="tw-bg-blue-600 tw-text-white tw-px-4 tw-py-2 tw-rounded"
        onClick={handleOpenModal}
      >
        Choose template
      </button>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h2 className="tw-text-xl tw-font-bold">Select template</h2>
          <button 
            className="tw-bg-white tw-text-gray-800 tw-px-4 tw-py-2 tw-rounded tw-border tw-border-gray-300"
            onClick={handleCloseModal}
          >
            ✕
          </button>
        </div>
        <div className="tw-flex tw-space-x-4 tw-flex-grow tw-overflow-hidden">
          <div className="tw-w-1/3 tw-flex tw-flex-col">
            <input 
              type="text" 
              placeholder="Search" 
              className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-mb-4"
            />
            <div className="tw-overflow-y-auto tw-flex-grow">
              {templates.map((template, index) => (
                <TemplateItem 
                  key={index} 
                  template={template} 
                  onSelect={handleSelectTemplate}
                  isSelected={selectedTemplate && selectedTemplate.name === template.name}
                />
              ))}
            </div>
          </div>
          <div className="tw-w-2/3 tw-overflow-y-auto">
            {selectedTemplate && (
              <>
                <div className="tw-mb-4">
                  <h3 className="tw-font-bold tw-mb-2">Body:</h3>
                  <p>{selectedTemplate.body}</p>
                  {selectedTemplate.footer && (
                    <>
                      <h3 className="tw-font-bold tw-mt-4 tw-mb-2">Footer:</h3>
                      <p>{selectedTemplate.footer}</p>
                    </>
                  )}
                  {selectedTemplate.buttons && (
                    <>
                      <h3 className="tw-font-bold tw-mt-4 tw-mb-2">Buttons:</h3>
                      {selectedTemplate.buttons.map((button, index) => (
                        <p key={index}>{button.text}: {button.url}</p>
                      ))}
                    </>
                  )}
                </div>
                <div>
                  <h3 className="tw-font-bold tw-mb-2">Preview</h3>
                  <div className="tw-flex tw-space-x-2 tw-mb-4">
                    <button 
                      className={`tw-px-3 tw-py-1 tw-rounded ${previewType === 'android' ? 'tw-bg-blue-500 tw-text-white' : 'tw-bg-gray-200'}`}
                      onClick={() => setPreviewType('android')}
                    >
                      Android
                    </button>
                    <button 
                      className={`tw-px-3 tw-py-1 tw-rounded ${previewType === 'ios' ? 'tw-bg-blue-500 tw-text-white' : 'tw-bg-gray-200'}`}
                      onClick={() => setPreviewType('ios')}
                    >
                      iOS
                    </button>
                  </div>
                  <TemplateMockup template={selectedTemplate} type={previewType} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="tw-mt-6 tw-flex tw-justify-center tw-gap-4">
          <button 
            className="tw-bg-blue-600 tw-text-white tw-px-4 tw-py-2 tw-rounded"
            // onClick={handleChooseTemplate}
          >
            Create Template
          </button>
          <button 
            className="tw-bg-blue-600 tw-text-white tw-px-4 tw-py-2 tw-rounded"
            onClick={handleChooseTemplate}
          >
            Choose Template
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateMessage;