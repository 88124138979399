import React from 'react';
import './AppointmentsReport.css';

function ContentNewReports() {
    
    return (
        <>
         <div className='container-fluid'>
            <div className='row'>
                    {/* <div className="no-data-box">
                        <h5>No data yet.</h5>
                        <h6 className='card-title1 '>View the latest reports from investigations and referrals here.</h6>
                    </div> */}
                <div className='col-md-12 px-0'>
                    <h2>Phase 2</h2>
                    <h3>View the latest reports from investigations and referrals here.</h3>
                </div>
                {/* <div className='col-md-12 px-0'>
                    <h2>New Reports</h2>
                    <h3>Here are some tasks for you to complete today.</h3>
                </div> */}

                {/* <div className='col-md-12 px-0 mt-2' style={{maxHeight:"392px", overflowX:"hidden", overflowY:"scroll"}}>

                    <div className='row align-items-center mb-2'>
                        <div className='col-auto'>
                            <img src={require("../../images/icon-reports.png")} className="icon-appointments-report" alt="" />
                        </div>
                        <div className='col ps-0'>
                            <h4>Health Risk assessment available for Azma</h4>
                        </div>
                        <div className='col-auto'>
                            <img src={require("../../images/icon-statistics.png")} className="icon-statistics-report" alt="" />
                        </div>
                    </div>

                    <div className='row align-items-center mb-2'>
                        <div className='col-auto'>
                            <img src={require("../../images/icon-reports.png")} className="icon-appointments-report" alt="" />
                        </div>
                        <div className='col ps-0'>
                            <h4>Lipid profile reports available for Sania</h4>
                        </div>
                        <div className='col-auto'>
                            <img src={require("../../images/icon-statistics.png")} className="icon-statistics-report" alt="" />
                        </div>
                    </div>

                    <div className='row align-items-center mb-2'>
                        <div className='col-auto'>
                            <img src={require("../../images/icon-reports.png")} className="icon-appointments-report" alt="" />
                        </div>
                        <div className='col ps-0'>
                            <h4>Serum Creatinine+EGFR reports available for Ashima</h4>
                        </div>
                        <div className='col-auto'>
                            <img src={require("../../images/icon-statistics.png")} className="icon-statistics-report" alt="" />
                        </div>
                    </div>

                </div> */}
            </div>
         </div>
        </>
    );
};

export default ContentNewReports;
