import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { displayUploadedCheckerReport} from '../../../services/rcm/Service';
import { CustomLoader, decruptCipherJson } from "../../utils/CommonLibrary";
import moment from "moment";
import Tooltip from 'react-bootstrap/Tooltip';
import { decryptData } from "../../utils/Utils";
import FileCheckerDateTable from "./FileCheckerDateTable";

function RCMFileChecker() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    const [loading, setLoading] = useState('');
    const [logData, setLogData] = useState([]);


    const renderRCM1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of member records uploaded by the RCM team.
        </Tooltip>
    );

    const renderRCM2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total count of member records successfully migrated/uploaded into the system.
        </Tooltip>
    );

    const renderRCM3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total count of member records that failed during the upload process.
        </Tooltip>
    );

    const renderRCM4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members who have been contacted.
        </Tooltip>
    );

    const renderRCM5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
             Members who have not yet been contacted.
        </Tooltip>
    );

    const renderRCM6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members successfully reached by agents.
        </Tooltip>
    );

    const renderRCM7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members marked as invalid data by agents.
        </Tooltip>
    );

      
    useEffect(() => {
        fetchData();
    }, [startDate,endDate]);
    
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    
    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };  

    const fetchData = async () => {
        setLoading(true);
        displayUploadedCheckerReport(formatDate(startDate),formatDate(endDate))
        .then((response) => {
            let showData = decruptCipherJson(response.data.data);
            setLogData(showData.responseData);
            setLoading(false);
        });
    }

   
    
    return (
        <>
            <section className='ReportsPrograms'>
                <div className="container-fluid px-0 mb-3 ">
                    <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md ">
                            <h1 className="main-title">Data Review & Approval</h1>
                        </div>
                    </div>
                    <div className='reports-programs mb-3'>
                        <div className=" row align-items-center">
                        <div className="col-md-auto">
                                <img src="https://storage.googleapis.com/ksabupatest/2024/10/17/jh3jv/fo4134lj81.png" alt="Invited Members icon" style={{height:"140px"}}/>
                            </div>
                            <div className="col-md">
                                <p className="mb-0">Enables a structured review process where one user submits data and another approves or rejects it. Approvers can review key insights to ensure data accuracy and accountability, maintaining operational efficiency.</p>
                            </div>
                        </div>
                    </div>

                    <div className='reports-programs mb-3'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <FileCheckerDateTable logData={logData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            { loading ? <CustomLoader /> : null }
        </>
    );
}

export default RCMFileChecker;
