import React from 'react';
import './ContentMemberDeEnrollment.css';
import ContentMemberDeEnrollmentDetails from './ContentMemberDeEnrollment/ContentMemberDeEnrollmentDetails';

function ContentMemberDeEnrollment() {

  return (
    <>
        <section className='ContentMemberDeEnrollment'>
            <ContentMemberDeEnrollmentDetails />
        </section>
    </>
  );
}

export default ContentMemberDeEnrollment;
