import React, { useState } from 'react';
import ContentConfigurationList from '../components/ConfigurationList';




function MemberConfigurationListPage() {

  const [title, setTitle] = useState('Configure Enrollment Communications');
 
return (
    <>
    <section className='ContentPrograms'>
        <ContentConfigurationList/>
        
      </section>
    </>
  );
}

export default MemberConfigurationListPage;
