import React, { useState, useMemo, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineNotification } from "react-icons/ai";
import { FaTag, FaUser, FaRocket } from "react-icons/fa";
import { SlGraph } from "react-icons/sl";
import { MdOutlineInfo } from "react-icons/md";
import NewCampaignModal from "./NewCampaignModal";

const CustomTooltip = ({ children, tooltip }) => {
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef(null);
  const tooltipRef = useRef(null);

  const showTooltip = () => {
    clearTimeout(timeoutRef.current);
    setIsVisible(true);
  };

  const hideTooltip = () => {
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, 300); // 300ms delay before hiding
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div className="tw-relative tw-inline-block">
      <div
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>
      {isVisible && (
        <div
          ref={tooltipRef}
          className="tw-absolute tw-z-20 tw-w-48 tw-px-2 tw-py-1 tw--mt-1 tw-text-sm tw-leading-tight tw-text-white tw-transform tw--translate-y-full tw-bg-gray-700 tw-rounded-lg tw-shadow-lg"
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          {tooltip}
        </div>
      )}
    </div>
  );
};
  

const Notification = () => {
  const [activeTab, setActiveTab] = useState("One Time");
  const [activeFilter, setActiveFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState({ value: "", type: "" });
  const [stateFilter, setStateFilter] = useState({ value: "" });
  const [tagFilter, setTagFilter] = useState({ value: "", type: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tabs = ["One Time", "Ongoing", "API Campaign"];

  const InfoIcon = ({ tooltip }) => (
    <CustomTooltip tooltip={tooltip}>
      <MdOutlineInfo className="tw-inline-block tw-ml-1 tw-text-gray-500 tw-cursor-help" />
    </CustomTooltip>
  );

  // Dummy data for the One Time tab
  const oneTimeDummyData = [
    {
      name: "no user story 08",
      createdBy: "Parwage Alam",
      category: "MARKETING",
      state: "Completed",
      attempted: 67,
      sent: 65,
      delivered: 61,
      read: 44,
      replied: 1,
      dateSetLive: "08/08/2024",
    },
    {
      name: "Sunny 08",
      createdBy: "Parwage Alam",
      category: "MARKETING",
      state: "Completed",
      attempted: 1555,
      sent: 1346,
      delivered: 1346,
      read: 915,
      replied: 0,
      dateSetLive: "08/08/2024",
    },
    // Add more dummy data here...
  ];

  // Dummy data for the Ongoing tab (empty for this example)
  const ongoingDummyData = [];

  const filteredData = useMemo(() => {
    const dataToFilter =
      activeTab === "One Time" ? oneTimeDummyData : ongoingDummyData;
    return dataToFilter.filter((item) => {
      const nameMatch =
        nameFilter.value === "" ||
        (nameFilter.type === "is" && item.name === nameFilter.value) ||
        (nameFilter.type === "contains" &&
          item.name.includes(nameFilter.value));
      const stateMatch =
        stateFilter.value === "" || item.state === stateFilter.value;
      const tagMatch =
        tagFilter.value === "" || item.category === tagFilter.value;
      return nameMatch && stateMatch && tagMatch;
    });
  }, [activeTab, nameFilter, stateFilter, tagFilter]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredData.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredData, currentPage]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleFilterClick = (filter) => {
    setActiveFilter(activeFilter === filter ? null : filter);
  };

  const handleCampaignClick = (campaignId) => {
    navigate(`/campaign-details/${campaignId}`);
  };

  const renderFilters = () => (
    <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4 tw-mb-4">
    <div className="tw-flex tw-items-center tw-gap-2 tw-text-lg tw-font-semibold tw-bg-gray-200 tw-p-3 tw-rounded tw-cursor-pointer">
      <AiOutlineNotification className="tw-text-2xl tw-font-semibold" />{" "}
      Campaigns
    </div>

    {/* Name Filter */}
    <div className="tw-relative">
      <div
        onClick={() => handleFilterClick("name")}
        className="tw-flex tw-items-center tw-gap-2 tw-text-lg tw-font-semibold tw-bg-gray-200 tw-p-3 tw-rounded tw-cursor-pointer"
      >
        <FaUser className="tw-text-xl tw-font-semibold" /> Name
      </div>
      {activeFilter === "name" && (
        <div className="tw-absolute tw-top-12 tw-left-0 tw-w-64 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-lg tw-p-4 tw-z-10">
            {["is", "is not", "contains", "doesn't contain"].map((type) => (
              <div
                key={type}
                className="tw-flex tw-items-center tw-justify-start tw-gap-2 tw-mb-2"
              >
                <input
                  type="radio"
                  checked={nameFilter.type === type}
                  onChange={() => setNameFilter((prev) => ({ ...prev, type }))}
                  className="tw-form-radio"
                />
                <span className="tw-text-sm">{type}</span>
              </div>
            ))}
            {nameFilter.type && (
              <input
                type="text"
                className="tw-w-full tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-mt-2"
                value={nameFilter.value}
                onChange={(e) =>
                  setNameFilter((prev) => ({ ...prev, value: e.target.value }))
                }
                placeholder="Enter name..."
              />
            )}
            <div className="tw-mt-4 tw-text-center">
              <button
                className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-blue-600 hover:tw-scale-105 tw-transition-all tw-duration-200"
                onClick={() => setActiveFilter(null)}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>

       {/* State Filter */}
       <div className="tw-relative">
        <div
          onClick={() => handleFilterClick("state")}
          className="tw-flex tw-items-center tw-gap-2 tw-text-lg tw-font-semibold tw-bg-gray-200 tw-p-3 tw-rounded tw-cursor-pointer"
        >
          <SlGraph className="tw-text-xl tw-font-semibold" /> State is
        </div>
        {activeFilter === "state" && (
          <div className="tw-absolute tw-top-12 tw-left-0 tw-w-48 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-lg tw-z-10">
            {["Completed", "In Progress", "Scheduled"].map((state) => (
              <div
                key={state}
                className="tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-cursor-pointer"
                onClick={() => {
                  setStateFilter({ value: state });
                  setActiveFilter(null);
                }}
              >
                {state}
              </div>
            ))}
          </div>
        )}
      </div>

     {/* Tag Filter */}
     {/* <div className="tw-relative">
        <div
          onClick={() => handleFilterClick("tag")}
          className="tw-flex tw-items-center tw-gap-2 tw-text-lg tw-font-semibold tw-bg-gray-200 tw-p-3 tw-rounded tw-cursor-pointer"
        >
          <FaTag className="tw-text-xl tw-font-semibold" /> Tags
        </div>
        {activeFilter === "tag" && (
          <div className="tw-absolute tw-top-12 tw-left-0 tw-w-64 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-lg tw-p-4 tw-z-10">
            {["is", "is not"].map((type) => (
              <div
                key={type}
                className="tw-flex tw-items-center tw-justify-start tw-gap-2 tw-mb-2"
              >
                <input
                  type="radio"
                  checked={tagFilter.type === type}
                  onChange={() => setTagFilter((prev) => ({ ...prev, type }))}
                  className="tw-form-radio"
                />
                <span className="tw-text-sm">{type}</span>
              </div>
            ))}
            {tagFilter.type && (
              <select
                className="tw-w-full tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-mt-2"
                value={tagFilter.value}
                onChange={(e) =>
                  setTagFilter((prev) => ({ ...prev, value: e.target.value }))
                }
              >
                <option value="">Select a category</option>
                <option value="MARKETING">MARKETING</option>
                <option value="SALES">SALES</option>
                <option value="SUPPORT">SUPPORT</option>
              </select>
            )}
            <div className="tw-mt-4 tw-text-center">
              <button
                className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-blue-600 hover:tw-scale-105 tw-transition-all tw-duration-200"
                onClick={() => setActiveFilter(null)}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );

  const renderTable = (data) => (
    <div className="tw-bg-white tw-z-0 tw-relative tw-rounded-lg tw-shadow-lg tw-overflow-x-auto">
      <table className="tw-w-full tw-min-w-max">
        <thead>
          <tr className="tw-bg-gray-50">
            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
              Name
            </th>
            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
              Created By
            </th>
            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
              Category
            </th>
            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
              State
            </th>
            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
              Attempted
              <InfoIcon tooltip="Total number of message attempts" />
            </th>
            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
              Sent
              <InfoIcon tooltip="Number of messages successfully sent" />
            </th>
            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
              Delivered (% of Sent)
              <InfoIcon tooltip="Number and percentage of messages delivered to recipients" />
            </th>
            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
              Read (% of Sent)
              <InfoIcon tooltip="Number and percentage of messages read by recipients" />
            </th>
            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
              Replied (% of Sent)
              <InfoIcon tooltip="Number and percentage of messages that received a reply" />
            </th>
            <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
              Date Set Live
            </th>
          </tr>
        </thead>
        <tbody className="tw-bg-white tw-divide-y tw-divide-gray-200">
          {data.length === 0 ? (
            <tr>
              <td
                colSpan="10"
                className="tw-px-6 tw-py-4 tw-text-center tw-relative"
              >
                <div className="tw-bg-gray-200 tw-rounded-full p-4 tw-m-auto tw-inline-block tw-relative">
                  <FaRocket className="tw-text-3xl tw-text-gray-400 " />
                  <span className="tw-absolute tw-top-0 tw-right-0 tw-bg-red-500 tw-text-white tw-rounded-full tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-text-xs tw-font-bold">
                    0
                  </span>
                </div>
                <p className="tw-text-lg tw-font-semibold tw-mb-2">
                  You have no campaigns at the moment
                </p>
                <p className="tw-text-sm tw-text-gray-500">
                  Curious about how to set up campaigns?
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.interakt.shop/resource-center/whatsapp-message-notification-template-steps-to-start/"
                    className="tw-text-blue-500 hover:tw-text-blue-700 tw-ml-1"
                  >
                    Give a quick read to understand more
                  </a>
                </p>
              </td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr
                key={index}
                className={`tw-cursor-pointer ${index % 2 === 0 ? "tw-bg-white" : "tw-bg-gray-50"}`}
                onClick={() => handleCampaignClick(index)}
              >
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                  {item.name}
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                  {item.createdBy}
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                  {item.category}
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                  <span
                    className={`tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full ${
                      item.state === "Completed"
                        ? "tw-bg-blue-100 tw-text-blue-800"
                        : item.state === "In Progress"
                        ? "tw-bg-yellow-100 tw-text-yellow-800"
                        : "tw-bg-gray-100 tw-text-gray-800"
                    }`}
                  >
                    {item.state}
                  </span>
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
                  {item.attempted}
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
                  {item.sent}
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
                  {`${item.delivered} (${Math.round(
                    (item.delivered / item.sent) * 100
                  )}%)`}
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
                  {`${item.read} (${Math.round(
                    (item.read / item.sent) * 100
                  )}%)`}
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
                  {`${item.replied} (${Math.round(
                    (item.replied / item.sent) * 100
                  )}%)`}
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
                  {item.dateSetLive}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="tw-p-4 sm:tw-p-6">
      <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-center tw-mb-6">
        <div className="tw-text-2xl sm:tw-text-3xl tw-font-semibold tw-flex tw-items-center tw-gap-4 sm:tw-gap-6 tw-mb-4 sm:tw-mb-0">
          <AiOutlineNotification />
          <h1 className="">Notification</h1>
        </div>
        <button onClick={() => setIsModalOpen(true)} className="tw-text-lg sm:tw-text-xl tw-bg-sky-600 tw-text-white tw-p-2 sm:tw-p-3 tw-rounded-lg hover:tw-bg-sky-800 hover:tw-scale-105 hover:tw-font-semibold tw-transition-all tw-duration-100">
          + New Campaign
        </button>
      </div>
      <div className="tw-flex tw-flex-col tw-justify-between tw-gap-6 tw-mb-6">
        <div>
          <p className="tw-text-sm sm:tw-text-base">
            Set up your first notifications campaign and engage with your users.
          </p>
          <p className="tw-text-sm sm:tw-text-base">
            Don't know how? Click on <span>Learn More</span>
          </p>
        </div>

        <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-font-semibold tw-text-xs sm:tw-text-sm">
          <span className="tw-text-gray-400">Account status</span>
          <div className="tw-h-2 tw-w-2 sm:tw-h-3 sm:tw-w-3 tw-rounded-full tw-bg-green-500"></div>
          <span>Healthy</span>
          <InfoIcon tooltip="tooltip" />
          <span className="tw-text-gray-400">|</span>
          <span className="tw-text-gray-400">Notification Limit</span>
          <span className="tw-underline tw-text-blue-600 tw-cursor-pointer">
            <a target="_blank" href="https://business.facebook.com/wa/manage/phone-numbers/?business_id=928974617157828&waba_id=482588863202870">Check Limit</a>
          </span>
        </div>
      </div>

      <div className="tw-text-sm tw-font-medium tw-text-center tw-text-gray-500  tw-overflow-x-auto">
        <ul className="tw-flex tw-flex-nowrap tw--mb-px">
          {tabs.map((tab) => (
            <li key={tab} className="tw-me-2">
              <a
                href="#"
                onClick={() => setActiveTab(tab)}
                className={`tw-inline-block tw-p-2 sm:tw-p-4  tw-rounded-t-lg ${
                  activeTab === tab
                    ? "tw-text-blue-600 tw-border-blue-600 focus:tw-border-blue-600 focus:tw-border-b-4 tw-border-b-4"
                    : "tw-border-transparent tw-text-gray-500 hover:tw-text-gray-600 hover:tw-border-gray-300 hover:tw-border-b-4"
                } tw-transition-colors tw-duration-300 tw-whitespace-nowrap`}
                aria-current={activeTab === tab ? "page" : undefined}
              >
                {tab}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Tab content */}
      <div className="tw-pt-4">
        {activeTab === "One Time" && (
          <div>
            {renderFilters()}
            {renderTable(paginatedData)}
            {/* Pagination */}
            {filteredData.length > itemsPerPage && (
              <div className="tw-flex tw-justify-center tw-mt-4">
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className="tw-px-2 sm:tw-px-4 tw-py-1 sm:tw-py-2 tw-bg-gray-200 tw-rounded-l-lg disabled:tw-opacity-50 tw-text-sm sm:tw-text-base"
                >
                  Previous
                </button>
                <span className="tw-px-2 sm:tw-px-4 tw-py-1 sm:tw-py-2 tw-bg-gray-100 tw-text-sm sm:tw-text-base">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className="tw-px-2 sm:tw-px-4 tw-py-1 sm:tw-py-2 tw-bg-gray-200 tw-rounded-r-lg disabled:tw-opacity-50 tw-text-sm sm:tw-text-base"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        )}
        {activeTab === "Ongoing" && (
          <div>
            {renderFilters()}
            {renderTable(ongoingDummyData)}
          </div>
        )}
        {activeTab === "API Campaign" && (
          <div>
            {renderFilters()}
            <div className="tw-flex tw-justify-center tw-items-center tw-h-64">
              <p className="tw-text-center tw-text-sm sm:tw-text-lg tw-text-gray-600">
                API campaigns can only be created via APIs. 
                <a target="_blank" href="https://www.interakt.shop/resource-center/api-campaign-on-whatsapp/" className="tw-text-blue-500 hover:tw-text-blue-700 tw-ml-1">
                  Find more details here
                </a>
              </p>
            </div>
          </div>
        )}
      </div>
      <NewCampaignModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default Notification;