import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';

function CompletedChallengesSlider() {


  return (
    <>
      <>
      <Swiper
       slidesPerView={3}
       spaceBetween={10}
       pagination={{
         clickable: true,
       }}
       modules={[Pagination]}
        className="myCompletedChallengesSlider"
      >
        <SwiperSlide>
            <div className='challenges-box challenge-ended'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/xuda7/74cjh854ws.png" alt="Walkathon Warriors" className="challenge-logo mb-2"/>
                        <h2 className="challengeTitle">Daily 10k Challenge</h2>
                        <h3 className="challengeSubTitle">Take 10k steps daily</h3>
                        <h3 className="challengeSubTitle">Participants : <span>252</span></h3>
                        <h3 className="challengeSubTitle">Nov 26 to Dec 30, 2024</h3>
                    </div>
                    <div className='col-md-12 mt-3'>
                    <button type="button" className="small-btn btn btn-outline-secondary">View Leaderboard</button>
                    <button type="button" className="small-btn btn btn-outline-success">View Team Leaderboard</button>
                    </div>
                </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
            <div className='challenges-box challenge-ended'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/xuda7/74cjh854ws.png" alt="Walkathon Warriors" className="challenge-logo mb-2"/>
                        <h2 className="challengeTitle">Daily 10k Challenge</h2>
                        <h3 className="challengeSubTitle">Take 10k steps daily</h3>
                        <h3 className="challengeSubTitle">Participants : <span>252</span></h3>
                        <h3 className="challengeSubTitle">Nov 26 to Dec 30, 2024</h3>
                    </div>
                    <div className='col-md-12 mt-3'>
                    <button type="button" className="small-btn btn btn-outline-secondary">View Leaderboard</button>
                    <button type="button" className="small-btn btn btn-outline-success">View Team Leaderboard</button>
                    </div>
                </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
            <div className='challenges-box challenge-ended'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/xuda7/74cjh854ws.png" alt="Walkathon Warriors" className="challenge-logo mb-2"/>
                        <h2 className="challengeTitle">Daily 10k Challenge</h2>
                        <h3 className="challengeSubTitle">Take 10k steps daily</h3>
                        <h3 className="challengeSubTitle">Participants : <span>252</span></h3>
                        <h3 className="challengeSubTitle">Nov 26 to Dec 30, 2024</h3>
                    </div>
                    <div className='col-md-12 mt-3'>
                    <button type="button" className="small-btn btn btn-outline-secondary">View Leaderboard</button>
                    <button type="button" className="small-btn btn btn-outline-success">View Team Leaderboard</button>
                    </div>
                </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
            <div className='challenges-box challenge-ended'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/xuda7/74cjh854ws.png" alt="Walkathon Warriors" className="challenge-logo mb-2"/>
                        <h2 className="challengeTitle">Daily 10k Challenge</h2>
                        <h3 className="challengeSubTitle">Take 10k steps daily</h3>
                        <h3 className="challengeSubTitle">Participants : <span>252</span></h3>
                        <h3 className="challengeSubTitle">Nov 26 to Dec 30, 2024</h3>
                    </div>
                    <div className='col-md-12 mt-3'>
                    <button type="button" className="small-btn btn btn-outline-secondary">View Leaderboard</button>
                    <button type="button" className="small-btn btn btn-outline-success">View Team Leaderboard</button>
                    </div>
                </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
            <div className='challenges-box challenge-ended'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/xuda7/74cjh854ws.png" alt="Walkathon Warriors" className="challenge-logo mb-2"/>
                        <h2 className="challengeTitle">Daily 10k Challenge</h2>
                        <h3 className="challengeSubTitle">Take 10k steps daily</h3>
                        <h3 className="challengeSubTitle">Participants : <span>252</span></h3>
                        <h3 className="challengeSubTitle">Nov 26 to Dec 30, 2024</h3>
                    </div>
                    <div className='col-md-12 mt-3'>
                    <button type="button" className="small-btn btn btn-outline-secondary">View Leaderboard</button>
                    <button type="button" className="small-btn btn btn-outline-success">View Team Leaderboard</button>
                    </div>
                </div>
            </div>
        </SwiperSlide>

      </Swiper>
    </>
    </>
  );
}

export default CompletedChallengesSlider;
