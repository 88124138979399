import React, { useState } from 'react';
import './ContentManagementPage.css';
import Sidenavmenubar from './componenents/Sidenavmenubar';
import Navbar from './componenents/Navbar';
import ContentManagement from './componenents/ContentManagement';


function ContentManagementPage() {

  const [title, setTitle] = useState('Content Management');

  return (
    <>
        <ContentManagement/>
    </>
  );
}

export default ContentManagementPage;
