import React from 'react'; 
import Pagination from '../../utils/Pagination';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils/Utils';



const Table = ({
    data, 
    currentPage, 
    perPage, 
    totalPages, 
    totalEntries, 
    setCurrentPage, 
    setError, 
    setPerPage,
    fetchAllObservations,
    sortConfig,
    onSort
}) => {
    // Remove local sort state since it's now managed by parent
    const SortIndicator = ({ field }) => {
        if (sortConfig.field !== field) {
            return <i className="fa fa-sort text-muted"></i>;
        }
        return sortConfig.direction === 'asc' ? 
            <i className="fa fa-sort-asc"></i> : 
            <i className="fa fa-sort-desc"></i>;
    };

    function handlePageChange(page){ 
        setCurrentPage(page); 
    }

    return (
        <>
            <div className='table-responsive'>
                <table className='table table-striped mb-0'>
                    <thead>
                        <tr>
                            <th 
                                className='td-styled-ob' 
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('observation_id')}
                            >
                                Observation ID
                                <SortIndicator field="observation_id" />
                            </th>
                            <th className='td-styled-ob'>Title</th>
                            <th 
                                className='td-styled-ob'
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('status')}
                            >
                                Status
                                <SortIndicator field="status" />
                            </th>
                            <th 
                                className='td-styled-ob'
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('priority')}
                            >
                                Priority
                                <SortIndicator field="priority" />
                            </th>
                            <th className='td-styled-ob'>Observation Date</th>
                            <th className='td-styled-ob'>Last Action Date</th>
                            <th className='td-styled-ob'>Current Responsibility</th>
                            <th className='td-styled-ob'>Observed By</th>
                            <th className='td-styled-ob'>Application Name</th>
                            <th className='td-styled-ob'>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td className='td-styled-ob-text'>{item.id || 'N/A'}</td>
                                <td className='td-styled-ob-text'>{item.title}</td>
                                <td className='td-styled-ob-text'>{item.status}</td>
                                <td className='td-styled-ob-text'>{capitalizeFirstLetter(item.priority)}</td>
                                <td className='td-styled-ob-text' style={{whiteSpace:'break-spaces', width:'100px'}}>{item.createdDate}</td>
                                <td className='td-styled-ob-text' style={{whiteSpace:'break-spaces', width:'100px'}}>{item.lastActionDate}</td>
                                <td className='td-styled-ob-text'>{capitalizeFirstLetter(item.current_responsibility)}</td>
                                <td className='td-styled-ob-text'>{capitalizeFirstLetter(item.firstname) + ' ' + capitalizeFirstLetter(item.lastname)}</td>
                                <td className='td-styled-ob-text'>{item.dashboardName}</td>
                                <td className='td-styled-ob-text'>
                                    <Link style={{textDecoration: 'none'}} to={`/observations/${item.observation_id}`} className='mb-0 me-2 tw-text-black'>
                                        <i className="fa fa-eye"></i>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                
               
            </div>

            { 
                totalPages > 0 && (
                    <div className="pagination-container">
                        <div className="rows-per-page">
                            <label>Rows per page: </label>
                            <select value={perPage} onChange={(e) => setPerPage(Number(e.target.value))}>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                            </select>
                        </div>

                        <div className="records-info">
                            {`${(currentPage - 1) * perPage + 1}-${Math.min(currentPage * perPage, totalEntries)} of ${totalEntries} records`}
                        </div>

                        <div className="pagination-controls">
                            <button 
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(1)}
                            >
                                <i className='bx bx-first-page'></i>
                            </button>
                            <button  
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                <i className='bx bx-chevron-left'></i>
                            </button>
                            <button  
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                <i className='bx bx-chevron-right'></i>
                            </button>
                            <button  
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(totalPages)}
                            >
                                <i className='bx bx-last-page'></i>
                            </button>
                        </div>
                    </div>
                )
            }
         {/* <Pagination currentPage={currentPage} totalEntries={totalEntries} totalPages={totalPages} handlePageChange={handlePageChange} limit={10}  />    */}
         </>
    )
}

export default Table
