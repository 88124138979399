import React, { useState } from 'react';
import './CreateChallenges.css';
import { Link } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';


function CreateChallenges() {

  const [html, setHtml] = useState('');

  function onChange(content) {
      setHtml(content);
  }

  const modules = {
      toolbar: {
          container: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, 
              {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']                                         
          ],
      }
  };



  const editorWrapperStyle = {
      height: '160px',
      marginBottom:'40px'
  };

  return (
    <>
      <section className='CreateChallenges'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Create Challenge</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='create-challenges mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/v134j/3jx23ey4d1.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo. Vivamus et interdum felis, ut vulputate tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo.</p>
              </div>
            </div>
          </div>

          <div className='create-challenges p-4'>
            
            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Title</label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Enter Challenge Title"/>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Sub Text</label>
                    <textarea type="text" className="form-control input-field-ui" rows="3" id="" placeholder="Message…"></textarea>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                  <div className='row'>
                    <label className="form-label sub-title">Rules Image</label>
                    <div className='col pe-1'>
                      <input type="file" className="form-control input-field-ui" id="" placeholder="Enter Challenge Title"/>
                    </div>
                    <div className='col-auto ps-0'>
                      <button type="button" id="xlsxSubmit" className="btn btn-success uplaod-file-btn">Upload File</button>
                    </div>
                  </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                  <div className='row'>
                    <label className="form-label sub-title">Challenge Image</label>
                    <div className='col pe-1'>
                      <input type="file" className="form-control input-field-ui" id="" placeholder="Enter Challenge Title"/>
                    </div>
                    <div className='col-auto ps-0'>
                      <button type="button" id="xlsxSubmit" className="btn btn-success uplaod-file-btn">Upload File</button>
                    </div>
                  </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                  <div className='row'>
                    <label className="form-label sub-title">Badge Logo</label>
                    <div className='col pe-1'>
                      <input type="file" className="form-control input-field-ui" id="" placeholder="Enter Challenge Title"/>
                    </div>
                    <div className='col-auto ps-0'>
                      <button type="button" id="xlsxSubmit" className="btn btn-success uplaod-file-btn">Upload File</button>
                    </div>
                  </div>
                </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <div className="row">
                    <div className="col-md">
                        <label className="form-label sub-title">Start Date</label>
                        <input type="date" className="form-control input-field-ui" id="" placeholder="Start Date"/>
                    </div>
                    <div className="col-md">
                        <label className="form-label sub-title">End Date</label>
                        <input type="date" className="form-control input-field-ui" id="" placeholder="End Date"/>
                    </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Challenge Activity type</label>
                    <select className="form-select input-field-ui " id="" name="" aria-label="Default select example">
                      <option value="">Select Activity</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      </select>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Select Task</label>
                </div>

                <div className="col-md-7 mb-2">
                      <div className="row align-items-center">
                        <div className="col-md-auto">
                            <label className="form-label sub-title mb-0">1.</label>
                        </div>
                        <div className="col-md-4 ps-0">
                          <select className="form-select input-field-ui " id="" name="" aria-label="Default select example">
                            <option value="">Select Task</option>
                            <option value="1">Food</option>
                            <option value="2">Steps</option>
                            <option value="3">Quiz</option>
                          </select>
                        </div>
                        <div className="col-md-auto pe-2">
                          <label className="form-label mb-0">Logs</label>
                        </div>
                        <div className="col-md ps-0">
                          <input type="text" className="form-control input-field-ui" id=""/>
                        </div>
                        <div className="col-md-auto pe-2">
                          <label className="form-label mb-0">Points</label>
                        </div>
                        <div className="col-md ps-0">
                          <input type="text" className="form-control input-field-ui" id=""/>
                        </div>
                        <div className="col-md-1">
                          <button className="btn text-danger"><i className="bi bi-trash"></i></button>
                        </div>
                      </div>
                </div>

                <div className="col-md-7 mb-2">
                      <div className="row align-items-center">
                        <div className="col-md-auto">
                            <label className="form-label sub-title mb-0">2.</label>
                        </div>
                        <div className="col-md-4 ps-0">
                          <select className="form-select input-field-ui " id="" name="" aria-label="Default select example">
                            <option value="">Select Task</option>
                            <option value="1">Food</option>
                            <option value="2">Steps</option>
                            <option value="3">Quiz</option>
                          </select>
                        </div>
                        <div className="col-md-auto pe-2">
                          <label className="form-label mb-0">Logs</label>
                        </div>
                        <div className="col-md ps-0">
                          <input type="text" className="form-control input-field-ui" id=""/>
                        </div>
                        <div className="col-md-auto pe-2">
                          <label className="form-label mb-0">Points</label>
                        </div>
                        <div className="col-md ps-0">
                          <input type="text" className="form-control input-field-ui" id=""/>
                        </div>
                        <div className="col-md-1">
                          <button className="btn text-danger"><i className="bi bi-trash"></i></button>
                        </div>
                      </div>
                </div>

                <div className="col-md-7">
                <a type="button" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className="bx bxs-plus-circle"></i> Add Task</a>
                </div>

            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title mb-0">Take HRA</label> <br/>
                    <label className="form-label">Total number of HRA throughout the challenge.</label>
                </div>

                <div className="col-md-7 mb-2">
                      <div className="row align-items-center">
                        <div className="col-md-auto">
                            <label className="form-label sub-title mb-0">1.</label>
                        </div>
                        <div className="col-md-auto ps-0 pe-2">
                          <label className="form-label mb-0">Start Date</label>
                        </div>
                        <div className="col-md ps-0">
                          <input type="date" className="form-control input-field-ui" id=""/>
                        </div>
                        <div className="col-md-auto pe-2">
                          <label className="form-label mb-0">End Date</label>
                        </div>
                        <div className="col-md ps-0">
                          <input type="date" className="form-control input-field-ui" id=""/>
                        </div>
                        <div className="col-md-1">
                          <button className="btn text-danger"><i className="bi bi-trash"></i></button>
                        </div>
                      </div>
                </div>

                <div className="col-md-7 mb-2">
                      <div className="row align-items-center">
                        <div className="col-md-auto">
                            <label className="form-label sub-title mb-0">2.</label>
                        </div>
                        <div className="col-md-auto ps-0 pe-2">
                          <label className="form-label mb-0">Start Date</label>
                        </div>
                        <div className="col-md ps-0">
                          <input type="date" className="form-control input-field-ui" id=""/>
                        </div>
                        <div className="col-md-auto pe-2">
                          <label className="form-label mb-0">End Date</label>
                        </div>
                        <div className="col-md ps-0">
                          <input type="date" className="form-control input-field-ui" id=""/>
                        </div>
                        <div className="col-md-1">
                          <button className="btn text-danger"><i className="bi bi-trash"></i></button>
                        </div>
                      </div>
                </div>
                
                <div className="col-md-7">
                <a type="button" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className="bx bxs-plus-circle"></i> Add HRA</a>
                </div>

            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Experts Tips</label>
                    <div style={editorWrapperStyle}>
                      <ReactQuill 
                          value={html} 
                          onChange={onChange} 
                          modules={modules}
                          className='form-control input-field-ui p-0'
                          placeholder='Text'
                      />
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Background Colour</label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="#000000"/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-7">
                <button type="button" className="btn program-save-btn me-2">Submit</button> <button type="button" className="btn clear-btn">Cancel</button>
                </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default CreateChallenges;
