import React from 'react';
import './ContentMemberCommunications.css';
import ContentMemberCommunicationsDetails from './ContentMemberCommunications/ContentMemberCommunicationsDetails';

function ContentMemberCommunications() {

  return (
    <>
        <section className='ContentUserManagement'>

            <ContentMemberCommunicationsDetails />

        </section>


        
    </>
  );
}

export default ContentMemberCommunications;
