import React, { useState } from 'react';
import './UploadMemberDetailsPage.css';
import Sidenavmenubar from '../componenents/Sidenavmenubar';
import Navbar from '../componenents/Navbar';
import ContentUploadMemberDetails from '../componenents/ContentUploadMemberDetails';


function UploadMemberDetailsPage() {
  const [title, setTitle] = useState('Upload Member Details');

  return (
    <>
      <ContentUploadMemberDetails/>
    </>
  );
}

export default UploadMemberDetailsPage;
