import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);

const CallCompletionRatesBar = (props) => {
  // Define your data
  // const months = ['April', 'May', 'June', 'July'];

  const { dataMonth } = props;
  const { columnName: months,dataValue: seriesData } = dataMonth;

  const yAxisJson = {
    title: {
      text: ""
    }
  };

  const tooltipJson = {
    shared: true,
    valueSuffix: ' '
  };

  // const seriesData = [{
  //   name: 'Calls Booked',
  //   data: callsBooked,
  //   color: '#2266A6'
  // }, {
  //   name: 'Calls Completed',
  //   data: callsCompleted,
  //   color: '#39BCC7'
  // }];

  const optionsCNP1 = {
    chart: {
      type: "column",
      backgroundColor: 'transparent',
        height: 260,
        options3d: {
            enabled: true,
            alpha: 0,
            beta: 15,
            depth: 50,
            viewDistance: 0
        },
        marginTop: [34]
    },
    title: "Testing",
    subtitle: null,
    xAxis: {
      categories: months,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1,
        stacking: null, // Use null for side-by-side columns
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: seriesData,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: true
    },
    legend: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsCNP1}
      />
    </div>
  );
};

export default CallCompletionRatesBar;
