import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NewRequestTable from "./NewRequestTable";
import PastRequestTable from "./PastRequestTable";

function ContentMemberDeEnrollmentDetails() {

    return (
        <>
        <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md">
                    <h1 className="main-title">Member De-Enrollment</h1>
                </div>
                <div className="col-md text-end">
                    
                </div>
            </div>

            <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/usermanagement.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-3">Enables supervisors to efficiently handle de-enrollment requests from Care Navigators, approving or rejecting them to ensure seamless operations and high-quality service delivery, while maintaining accurate member status and continuity of care.</p>
                        </div>
                    </div>
                </div>
            <div className="container-fluid">
            
                <div className="row align-items-center pb-3">
                <div className='comntent-MemberDeEnrollment mb-3'>
                <div className="row">
                    <div className="col-md-12">
                    <Tabs>
                        <TabList>
                        <Tab>New Request</Tab>
                        <Tab>Past Request</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="table-responsive ContentMemberDeEnrollmentTable">
                                <NewRequestTable />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="table-responsive ContentMemberDeEnrollmentTable">
                                <PastRequestTable />
                            </div>
                        </TabPanel>
                    </Tabs>
                    
                    </div>
                </div>
                </div>

               
                </div>
            </div>

            
        </>
    );
}

export default ContentMemberDeEnrollmentDetails;
