import React, { useState, useEffect } from 'react';
import axios from "axios";
import { fetchAssessmentCategories, fetchAssessmentServices } from "../../components/services/Service";
import DatePicker from 'react-datepicker';
import moment from 'moment';

const LabResults = (props) => {
    const c = props.c;
    const listType = props.listType;
    const data = props.data;
    const removeComponent = props.removeComponent;
    const handleFieldChange = props.handleFieldChange;
    const [categories, setCategories] = useState([]);
    const [services, setServices] = useState([]);

    const addHandleChange = (e) => {
        handleFieldChange(e, c, listType);
    }

    useEffect(() => {
        getAssessmentCategories();
    }, []);

    useEffect(() => {
        getAssessmentServices(data.categoryId);
    }, [data.categoryId])

    const getAssessmentCategories = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchAssessmentCategories(listType, ct)
            .then((response) => {
                if (response.data.data.categories)
                    setCategories(response.data.data.categories);
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getAssessmentServices = async (categoryId) => {
        if (categoryId > 0) {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await fetchAssessmentServices(categoryId, ct)
                .then((response) => {
                    if (response.data.data.services)
                        setServices(response.data.data.services);
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const addHandleDateChange = (date, name) => {
        const tempE = { name: name, value: (moment(date).isValid()) ? moment(date).format('YYYY-MM-DD') : "" };
        const e = { target: tempE };
        handleFieldChange(e, c, listType);
    }

    return (
        <>
            <tr style={{ verticalAlign: "middle" }}>
                <td style={{ width: "9%" }}>
                    <select className="form-select select-input-ui" aria-label="Default select example" name="categoryId" value={data.categoryId} onChange={addHandleChange}>
                        <option value="" data-type="">Select</option>
                        {
                            categories.map((opt, index) => {
                                return <option key={index} value={opt.categoryId}>{opt.categoryName}</option>
                            })
                        }
                    </select>
                </td>
                <td style={{ width: "9%" }}>
                    <select className="form-select select-input-ui" aria-label="Default select example" name="assessmentServiceId" value={data.assessmentServiceId} onChange={addHandleChange}>
                        <option value="" data-type="">Select</option>
                        {
                            services.map((opt, index) => {
                                return <option key={index} value={opt.serviceId} data-code={opt.serviceCode}>{opt.serviceName}</option>
                            })
                        }
                    </select>
                </td>
                <td>
                    <input type="text" className="form-control control-input-ui" id="Code" style={{ width: "auto !important" }} placeholder="Code" name="code" value={data.code} onChange={addHandleChange} />
                </td>
                <td>
                    <input type="text" className="form-control control-input-ui" id="Condition" style={{ width: "auto !important" }} placeholder="Condition" name="patientCondition" value={data.patientCondition} onChange={addHandleChange} />
                </td>
                <td>
                    {/*<input type="text" className="form-control control-input-ui" id="date" style={{ width: "auto !important" }} placeholder="Supply From" name="date" value={data.date} onChange={addHandleChange} />*/}
                    <DatePicker
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Date"
                        className='form-control control-input-ui'
                        selected={data.date}
                        name="date"
                        onChange={(date) => addHandleDateChange(date, "date")}
                        onKeyDown={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                    />
                </td>
                <td style={{ width: "16%" }}>
                    <input type="text" className="form-control control-input-ui" id="attachmentUrl" style={{ width: "auto !important" }} placeholder="Attachment (Optional)" name="attachmentUrl" value={data.attachmentUrl} onChange={addHandleChange} />
                </td>
                <td>
                    <input type="text" className="form-control control-input-ui" id="provider" style={{ width: "auto !important" }} placeholder="Provider" name="provider" value={data.provider} onChange={addHandleChange} />
                </td>
                <td>
                    <input type="text" className="form-control control-input-ui" id="Notes" style={{ width: "auto !important" }} placeholder="Remarks" name="notes" value={data.notes} onChange={addHandleChange} />
                </td>
                <td>
                    <button type="button" className="btn-close cross-btn-close" aria-label="Close" onClick={() => removeComponent(c, listType)}></button>
                </td>
            </tr>
        </>
    )
}

export default LabResults