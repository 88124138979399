import React, { useState } from 'react';
import './ChallengesPage.css';
import Sidenavmenubar from './componenents/Sidenavmenubar';
import Navbar from './componenents/Navbar';
import ContentChallenges from './componenents/ContentChallenges';


function ChallengesPage() {

  const [title, setTitle] = useState('Challenges');

  return (
    <>
        <ContentChallenges/>
    </>
  );
}

export default ChallengesPage;
