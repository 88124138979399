import React, { useState,useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MemberEngagementTable from "./MemberEngagementTable";
import CalendarDatePicker from "../CalendarDatePicker";
import moment from "moment";
import { Filterdashboard,SummeryDashboardData, SummeryDashboardDataPhase2 } from "../../met/utils/EngagementUtility";
import { decryptData } from "../../utils/Utils";

function ContentMemberEngagementTable() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate());

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };
    
    const [selectedProgram, setSelectedProgram] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const handleProgramChange = (event) => {
      setSelectedProgram(event.target.value);
      console.log('Selected Program:', event.target.value);
    };


    return (
        <>
            <div className="container-fluid px-0 pb-3 mb-5">
                <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md ">
                        <h1 className="main-title">Member Engagement Automation - Phase 2</h1>
                    </div>
                  <Filterdashboard onChange={handleProgramChange} />

                    <div className="col-md-3">
                    <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/8hxst/888st4jed0.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">Allows admins to view member information by categories (contacted, not contacted, enrolled, not interested) and initiate automated communications across various channels for enhanced engagement and efficiency.</p>
                        </div>
                    </div>
                </div>
                <div className='member-engagement mb-3'>
                    <SummeryDashboardDataPhase2 startDate={startDate} endDate={endDate}/>
                </div>

                


                <div className='member-engagement mb-3'>
                <div className="row">
                    <div className="col-md-12">
                    <div className="table-responsive">
                        <MemberEngagementTable startDate={startDate} endDate={endDate} />
                    </div>
                    </div>
                </div>
                </div>
            </div>         
        </>
    );
}

export default ContentMemberEngagementTable;
