import React, { useRef } from 'react';

const TextBoxGeneric = React.forwardRef((props, ref) => {
  return (
    <>
      <textarea
        type="text"
        placeholder={props.placeholder}
        rows="4"
        className="form-control control-input-ui-addmember"
        style={{ resize: 'none' }}
        ref={ref} // Attach the ref to the textarea
      />
    </>
  );
});

export default TextBoxGeneric;
