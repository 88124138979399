import React, { useState } from 'react';
import './MemberCommunications.css';
import Sidenavmenubar from '../../componenents/Sidenavmenubar';
import Navbar from '../../componenents/Navbar';
import ContentMemberCommunications from '../../componenents/ContentMemberCommunications';


function MemberCommunicationsPage() {

  const [title, setTitle] = useState('Member Communications');

  return (
    <>
       <ContentMemberCommunications/>
    </>
  );
}

export default MemberCommunicationsPage;
