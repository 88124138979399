import React, { useState, useEffect } from 'react';
import "./SearchExternalMember.css"
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { searchExternalMembers } from '../services/Service';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const SearchExternalMember = (props) => {

    const onUserClick = props.onUserClick;
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [users, setUsers] = useState([]);
    const [searchModal, setSearchModal] = useState(true);
    const formObject = {
        mainMembershipNumber: "",
        membershipNumber: "",
        contractNumber: "",
        memberMobile: "",
        memberName: ""
    }
    const [formData, setFormData] = useState(formObject);

    const handleChange = async (e) => {
        const { name, value } = e.target;
        if (name == "mainMembershipNumber" || name == "membershipNumber" || name == "contractNumber" || name == "memberMobile") {
            if (!/^\d*\.?\d*$/.test(value)) {
                return false;
            }
        } else if (name == "memberName") {
            if (!/^[a-zA-Z\s]*$/.test(value)) {
                return false;
            }
        }
        setFormData({
            ...formData,
            [name]: value,
        });

        setError('');
    };

    const resetFormData = async () => {
        setFormData(formObject)
    }

    const searchExternalMemberDetails = async (e) => {
        e.preventDefault()
        setUsers([])
        if (formData['membershipNumber'] == "" && formData['contractNumber'] == "" && formData['memberMobile'] == "" && formData['memberName'] == "" && formData['mainMembershipNumber'] == "") {
            setError("Please enter search criteria");
        } else {
            setMessage("");
            setError("");

            const request = axios.CancelToken.source();
            let cancelToken = {
                cancelToken: request.token
            }

            await searchExternalMembers(formData, cancelToken)
                .then((response) => {
                    if (response.data.code === 200) {
                        if (response.data.data.users.length > 0) {
                            setUsers(response.data.data.users);
                        } else {
                            setError("No record found with the given criteria");
                        }
                    } else {
                        setError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    return (
        <>
            <Modal id="externalMemberSearch" show={props.showSearchExternalMemberModal} onHide={props.closeSearchExternalMemberModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Search Member</h1>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={searchExternalMemberDetails}>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="row">
                                    <div className='col-md-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <label className="form-label">Membership No.</label>
                                                <input type="text" className='form-control control-input-ui-addmember' name="membershipNumber" value={formData['membershipNumber']} onChange={handleChange} />
                                            </div>
                                            <div className='col-3'>
                                                <label className="form-label">Contract No.</label>
                                                <input type="text" className='form-control control-input-ui-addmember' name="contractNumber" value={formData['contractNumber']} onChange={handleChange} />
                                            </div>
                                            <div className='col-3'>
                                                <label className="form-label">Mobile No.</label>
                                                <input type="text" className='form-control control-input-ui-addmember' name="memberMobile" value={formData['memberMobile']} onChange={handleChange} />                                        </div>
                                            <div className='col-3'>
                                                <label className="form-label">Member Name</label>
                                                <input type="text" className='form-control control-input-ui-addmember' name="memberName" value={formData['memberName']} onChange={handleChange} />

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <label className="form-label">Main Membership No.</label>
                                                <input type="text" className='form-control control-input-ui-addmember' name="mainMembershipNumber" value={formData['mainMembershipNumber']} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{error}</div>
                                                <div className={`${message != "" ? "messageDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{message}</div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <Button variant="secondary" type="button" className="btn btn-secondary save-btn btn mt-2" style={{ background: "#e9e9e9", border: "1px solid #03335b" }} onClick={(e) => resetFormData()} >Reset</Button>
                                                {'  '}
                                                <Button variant="primary" type="submit" className="btn btn-primary save-btn btn btn-primary mt-2" style={{ background: "#03335b", border: "1px solid #03335b" }}  >Search</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {/*List*/}
                    <div className='row '>
                        {
                            users.map((item, index) => {
                                return <>

                                    <div className='col-md-4'>
                                        <div className="userslistSearch my-2">
                                            <Link to={'/members/' + encodeURIComponent(item.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: item.userId, name: item.memberName })}>
                                                <div className='row'>

                                                    <div className="col-4 col-md-auto">

                                                        <img src={item.imageUrl} alt={item.memberName} className="profile-img"></img>

                                                    </div>
                                                    <div className="col col-md ps-0">
                                                        <Link to={'/members/' + encodeURIComponent(item.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: item.userId, name: item.memberName })}>{item.memberName}</Link><br />
                                                        <h5 className='text-small'><span className=''>Main Membership: {item.mainMembershipNumber}</span></h5>
                                                        <h5 className='text-small'><span className=''>Membership: {item.membershipNumber}</span></h5>
                                                        <h5 className='text-small'><span className=''>{item.age}</span>, <span className='ps-1'>{item.gender}</span>, <span className='ps-1'>{item.city}</span></h5>
                                                        {
                                                            item.pathways.map((pathway, pIndex) => {
                                                                return <h5 className='text-small'><span className='pe-2'>{pathway.conditionName} / {pathway.pathwayName}</span></h5>
                                                            })
                                                        }
                                                        {
                                                            item.pathways.length <= 0 &&
                                                            <h5 className='text-small'><span className='pe-2'>-</span></h5>
                                                        }
                                                    </div>

                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                </>
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SearchExternalMember