import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import MembersAcrossProgramsBar from './componenents/DashboardGraphs/MembersAcrossProgramsBar';
import MemberPathwayStatusPie from './componenents/DashboardGraphs/MemberPathwayStatusPie';
import MembersAcrossPathwaysStackedBar from './componenents/DashboardGraphs/MembersAcrossPathwaysStackedBar';
import MembersDeEnrollmentsdBar from './componenents/DashboardGraphs/MembersDeEnrollmentsdBar';
import PathwayAdherenceRateLineChart from './componenents/DashboardGraphs/PathwayAdherenceRateLineChart';
import CallStatusBar from './componenents/DashboardGraphs/CallStatusBar';
// import HealthRiskAssessmentPie from './componenents/DashboardGraphs/HealthRiskAssessmentPie';
// import ServiceCompletionRatePie from './componenents/DashboardGraphs/ServiceCompletionRatePie';


function DashboardGraphs() {

    const renderM1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members enrolled in each program.
        </Tooltip>
    );

    const renderM2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Status of members: Completed, In Progress, De-Enrolled.
        </Tooltip>
    );

    const renderM3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Number of members in various pathways over time.
        </Tooltip>
    );

    const renderM4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
          Number of members de-enrolled over time.
        </Tooltip>
    );

    const renderM5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
         Adherence rates of members to pathways.
        </Tooltip>
    );

    const renderM6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
         Number of scheduled vs. completed calls.
        </Tooltip>
    );

    const renderM7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
        Proportion of members in each risk category.
        </Tooltip>
    );

    const renderM8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
        Proportion of completed vs. pending services.
        </Tooltip>
    );

    const renderM9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
        Count of upcoming services and appointments.
        </Tooltip>
    );



  return (
    <>
      <section className="ContentMemberConnectAgent mb-5 pb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">

              <div className="dashboard-graph-columns">


                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Members Across Programs</span> 
                        <OverlayTrigger placement="top" overlay={renderM1Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <MembersAcrossProgramsBar/>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Member Pathway Status</span> 
                        <OverlayTrigger placement="top" overlay={renderM2Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <MemberPathwayStatusPie/>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Members Across Pathways</span> 
                        <OverlayTrigger placement="top" overlay={renderM3Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <MembersAcrossPathwaysStackedBar/>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Members De-Enrollments</span> 
                        <OverlayTrigger placement="top" overlay={renderM4Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <MembersDeEnrollmentsdBar/>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Pathway Adherence Rate</span> 
                        <OverlayTrigger placement="top" overlay={renderM5Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>
                    
                    <PathwayAdherenceRateLineChart/>
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Call Status</span> 
                        <OverlayTrigger placement="top" overlay={renderM6Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    <CallStatusBar/>
                </div>


                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Health Risk Assessment</span> 
                        <OverlayTrigger placement="top" overlay={renderM7Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    {/* <HealthRiskAssessmentPie/> */}
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Service Completion Rate</span> 
                        <OverlayTrigger placement="top" overlay={renderM8Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>

                    {/* <ServiceCompletionRatePie/> */}
                </div>

                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Upcoming Services and Appointments</span> 
                        <OverlayTrigger placement="top" overlay={renderM9Tooltip}>
                            <i className='bx bx-info-circle'></i>
                        </OverlayTrigger>
                    </h1>
                    <div className='dashboard-graph-box-content'>
                        58
                    </div>
                    <h3 className='dashboard-graph-subtitle'>Upcoming Services and Appointments</h3>
                </div>
               

              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DashboardGraphs;
