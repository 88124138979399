import React, { useState,useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarDatePicker from "../CalendarDatePicker";
import CSATScoreGraph from "./CSATScoreGraph";
import CSATBarGraph from "./TextBoxGeneric";
import MigratedMembersGraph from "./MigratedMembersGraph";
import EnrolledMembersGraph from "./EnrolledMembersGraph";
import ProgramMembersGraph from "./ProgramMembersGraph";
import { member_insight_summery_data, fetch_member_agent_list } from "../../../services/met/Service";
import { LoaderLoader, formatDate } from "../../utils/CommonLibrary";
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MembersReachedBar from "../DashboardGraphsMCI/MembersReachedBar";
import CallsMadedBar from "../DashboardGraphsMCI/CallsMadedBar";
import MembersEnrolledBar from "../DashboardGraphsMCI/MembersEnrolledBar";
import AgentsBar from "../DashboardGraphsMCI/AgentsBar";
import TargetAchievementBar from "../DashboardGraphsMCI/TargetAchievementBar";
import CallDispositionBar from "../DashboardGraphsMCI/CallDispositionBar";
import DataQualityPie from "../DashboardGraphsMCI/DataQualityPie";
import MemberConversionFunnelBar from "../DashboardGraphsMCI/MemberConversionFunnelBar";
import AgeGroupConversionBar from "../DashboardGraphsMCI/AgeGroupConversionBar";
import MemberCorrectDataFunnelBar from "../DashboardGraphsMCI/MemberCorrectDataFunnelBar";
import MemberIncorrectDataFunnelBar from "../DashboardGraphsMCI/MemberIncorrectDataFunnelBar";
import CallConversionDispositionBar from "../DashboardGraphsMCI/CallConversionDispositionBar";
import { decryptData } from "../../utils/Utils";


function ContentCallHistory() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const [agentData, setAgentData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const[stateAgentId,setStateAgentId] = useState('all')
    // Function to get the past week's start and end dates
  

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate());

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const [completedCall,setCompletedCall] = useState(0);
    const [enrolledCall,setEnrolledCall] = useState(0);
    const [completeRateCall,setCompleteRateCall] = useState(0);
    const [avgCallTime,setAvgCallTime] = useState(0);
    const [firstCallResolution,setFirstCallResolution] = useState(0);
    const [careNavigatorBookedTotal,setCareNavigatorBookedTotal] = useState(0);
    const [attemptCallTotal,setAttemptCallTotal] = useState(0);
    const [totalAgentCount,setTotalAgentCount] = useState(0);
    const [agentLoginCount,setAgentLoginCount] = useState(0);
    const [agentLoginAbsentCount,setAgentLoginAbsentCount] = useState(0);
    const [noOfCallPerAgent,setNoOfCallPerAgent] = useState(0);
    const [memberUntouched,setMemberUntouched] = useState(0);
    const [firstCallConversionRate,setFirstCallConversionRate] = useState(0);
    const [memberSatisfaction,setMemberSatisfaction] = useState(0);
    const [agentUtilizationRate,setAgentUtilizationRate] = useState(0);
    const [totalIncorrectData,setTotalIncorrectData] = useState(0);
    const [totalIncorrectDataPercentage,setTotalIncorrectDataPercentage] = useState(0);
    const [totalMemberUntoched,setTotalMemberUntoched] = useState(0);
    const [statsValue, setStatsValue ] = useState({'membersMigrated':'','touchedCount':'','untouchedCount':'','validCount':'','invalidCount':'','onboardedCount':'',
        'extraEntryCount':'','notInterstedCount':'','backPoolCount':'','onboardLaterCount':'','totalOnboardCount':''
    })

    
    useEffect(() => {
        fetchAgentUserlist();
    }, [stateAgentId]);

    useEffect(() => {
        agentInsightStats();
    }, [stateAgentId,startDate,endDate]);

    const fetchAgentUserlist = async () =>{
        setIsLoading(true); //to show loader
    try {
            let resp = await fetch_member_agent_list();
           // if(resp.data.code === 200){

                setAgentData(resp.data.data);
            //}
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
}

const agentInsightStats = async () =>{
    try {
            let sdate= formatDate(startDate);
            let edate= formatDate(endDate);
            let resp = await member_insight_summery_data(sdate,edate,stateAgentId);
            if(resp.data.code === 200){
                setAvgCallTime(resp.data.data.recordAvgDuration);
                setAttemptCallTotal(resp.data.data.attemptPerMember);
                setMemberSatisfaction(resp.data.data.memberSatisfaction);
                setFirstCallConversionRate(resp.data.data.firstCallConversionRate);
                setAgentUtilizationRate(resp.data.data.agentUtilizationRate);
                setTotalIncorrectData(resp.data.data.totalIncorrectData);
                setTotalIncorrectDataPercentage(resp.data.data.totalIncorrectData);
                setTotalMemberUntoched(resp.data.data.totalMemberUntoched);
                setStatsValue(prevState => ({ ...prevState, membersMigrated: resp.data.data.successRecords, touchedCount: resp.data.data.touchCount, untouchedCount: resp.data.data.untouchCount,
                    validCount: resp.data.data.valid, invalidCount: resp.data.data.invalid, onboardedCount: resp.data.data.onboardedValue, notInterstedCount: resp.data.data.rejected, onboardLaterCount: resp.data.data.laterOnboard,
                    totalOnboardCount: resp.data.data.onboardedTotal,backPoolCount: resp.data.data.backToPool,extraEntryCount: resp.data.data.extraManualOnboarded
                }));
            }
            // let resp = await agent_insight_stats(sdate,edate,stateAgentId);
            //  //if(resp.data.code === 200){
            //     console.log("agent_stats",resp.data.data.totalEnrollCount);
            //     setCompletedCall(resp.data.data.totalCompletedCall);
            //     setMemberUntouched(resp.data.data.totalMemberUntoched);
            //     setEnrolledCall(resp.data.data.totalEnrollCount);
            //     setCompleteRateCall(resp.data.data.totalCompletedRate);
            //     setAvgCallTime(resp.data.data.avgCallTime);
            //     setAttemptCallTotal(resp.data.data.attemptCallTotal);
            //     setCareNavigatorBookedTotal(resp.data.data.careNavigatorBookedTotal);
            //     setTotalAgentCount(resp.data.data.totalAgentCount);
            //     setAgentLoginCount(resp.data.data.agentLoginCount);
            //     setAgentLoginAbsentCount(resp.data.data.agentLoginAbsentCount);
            //     setNoOfCallPerAgent(resp.data.data.noOfCallPerAgent);
                
                
                
                
            //}
        } catch (error) {
            console.error('Error:', error);
        }
}

const [formData, setFormData] = useState({ agentId: '' });

const handleChange = (e) => {
    const { name, value } = e.target;
    setStateAgentId(value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

    const renderMCI1Tooltip = (props) => (
    <Tooltip className="custom-tooltip" {...props}>
        Total number of members migrated by RCM + Enroll Later.
    </Tooltip>
    );

    const renderMCI2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of calls that successfully connected with a member.
        </Tooltip>
    );

    const renderMCI3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members who have enrolled in the program (Enrolled + Enroll later).
        </Tooltip>
    );

    const renderMCI4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members who were not interested in enrolling.
        </Tooltip>
    );

    const renderMCI5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of records marked as Incorrect Data by the agent.
        </Tooltip>
    );

    const renderMCI6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members who were successfully contacted.
        </Tooltip>
    );

    const renderMCI7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of calls made to members.
        </Tooltip>
    );

    const renderMCI8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members who have enrolled in the program.
        </Tooltip>
    );

    const renderMCI9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of agents making the calls.
        </Tooltip>
    );

    const renderMCI10Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Percentage of the target achieved by the agents.
        </Tooltip>
    );

    const renderMCI11Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Outcome of the calls made
        </Tooltip>
    );

    const renderMCI12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Average time spent on each call by agents over time.
        </Tooltip>
    );

    const renderMCI13Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Average number of call attempts per member.
        </Tooltip>
    );

    const renderMCI14Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Percentage of members enrolled after the first call attempt.
        </Tooltip>
    );

    const renderMCI15Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Measure of member satisfaction based on feedback.
        </Tooltip>
    );

    const renderMCI16Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Percentage of time agents spend on calls versus available time.
        </Tooltip>
    );

    const renderMCI17Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Measure of the accuracy and completeness of member data.
        </Tooltip>
    );

    const renderMCI18Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Stages of member conversion from contact to enrollment.
        </Tooltip>
    );

    const renderMCI19Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Conversion rate of members segmented by age groups.
        </Tooltip>
    );

    const renderMCI20Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Total number of member untouched.
        </Tooltip>
    );

    const renderMCI21Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Reasons for correct data.
        </Tooltip>
    );

    const renderMCI22Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Reasons for incorrect data.
        </Tooltip>
    );

    const renderMCI23Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Call conversion
        </Tooltip>
    );

    const renderMCI24Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members who enroll later.
        </Tooltip>
    );

    //call back agent graph
    const [totalAgentsCount,setTotalAgentsCount] = useState(0);
    const handleAgentCallback = (childData) => {
        setTotalAgentsCount(childData);
      };

    //call back members enroll graph
    const [totalEnrollCount,setTotalEnrollCount] = useState(0);
    const handleEnrollCallback = (childData) => {
            setTotalEnrollCount(childData);
    };
    
    //call back for member reached graph
    const [totalReachedCount,setTotalReachedCount] = useState(0);
    const handleReachedCallback = (childData) => {
        setTotalReachedCount(childData);
    };

    //call back for call made graph
    const [totalCallMadeCount,setTotalCallMadeCount] = useState(0);
    const handleCallMadeCallback = (childData) => {
        setTotalCallMadeCount(childData);
    };

    //call back for call conversion graph
    
    const [totalCallConversionData,setTotalCallConversionData] = useState({ count_enroll: 0, count_reject: 0,count_later: 0 });
    const handleCallConversionCallback = (childData) => {
        setTotalCallConversionData(childData);
    };
    //member conversion funnel call back
    const [totalAgentMemberConversionData,setTotalAgentMemberConversionData] = useState(0);
    const handleAgentMemberCallback = (childData) => {
        setTotalAgentMemberConversionData(childData);
    };
      
    //Target Achieved call back
    const [totalTargetAchieved,setTotalTargetAchieved] = useState(0);
    const handleTargetAchievedCallback = (childData) => {
        setTotalTargetAchieved(childData);
    };

    return (
        <>
         <LoaderLoader isLoading={isLoading} />
         <section className='ContentMemberEngagement'>
            <div className="container-fluid px-0 mb-5 pb-4 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md text-start">
                        <h1 className="main-title">MemberConnect Insights</h1>
                    </div>
                    <div className="col-md-3">
                    <select className="form-select input-field-ui" id="agentId" name="agentId" value={formData['agentId']}
                                    onChange={handleChange} aria-label="Default select example">
                                            <option value="all">All Agents</option>
                                            {agentData.map((option,i) => (
                                                <option key={i} value={option.agentId}>{option.fullName}</option>
                                            ))}
                            </select>
                    </div>
                    <div className="col-md-3">
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                    
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/h3glw/rofjblbhs8.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">Provides admins with the tools to manage agent metrics, including call statistics, attendance, performance, utilization, and detailed call data, ensuring comprehensive oversight and optimization.</p>
                        </div>
                    </div>
                </div>
                
                {/* <div className="member-engagement p-3 mb-3">
                    <div className="row align-items-center mb-2">
                        <div className="col">
                            <h1 className='dashboard-graph-title'>OutReach Performance</h1>
                        </div>
                    </div>
                    <div className="row text-center px-2">
                        <div className="col-2 px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="card-title1">{totalAgentMemberConversionData}</h3>
                                            <h5 className="card-text1">Members Migrated</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top" overlay={renderMCI1Tooltip}>
                                                <i className='bx bx-info-circle'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="card-title1">{totalMemberUntoched}</h3>
                                        <h5 className="card-text1">Member Untouched</h5>
                                    </div>
                                    <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCI20Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col border-end">
                                            <div className="row">
                                                <div className="col">
                                                    <h2 className="card-title1">{totalReachedCount}</h2>
                                                    <h5 className="card-text1">Successful Connect</h5>
                                                </div>
                                                <div className="col-auto">
                                                    <OverlayTrigger placement="top" overlay={renderMCI2Tooltip}>
                                                        <i className='bx bx-info-circle'></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col border-end">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title2 mt-1">{totalEnrollCount}  {totalReachedCount > 0 ? (
                                                        <span><br/>({((totalEnrollCount / totalReachedCount) * 100).toFixed(2)}%)</span>
                                                        ) : (
                                                        <span><br/>(0%)</span>
                                                        )}
                                                    </h1>
                                                    <h5 className="card-text1">Members Enrolled</h5>
                                                </div>
                                                <div className="col-auto">
                                                    <OverlayTrigger placement="top" overlay={renderMCI3Tooltip}>
                                                        <i className='bx bx-info-circle'></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col border-end">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title2 mt-1">{totalCallConversionData.count_later} 
                                                        
                                                    {totalReachedCount > 0 ? (
                                                            <span><br/>({((totalCallConversionData.count_later / totalReachedCount) * 100).toFixed(2)}%)</span>
                                                        ) : (
                                                        <span><br/>(0%)</span>
                                                        )}
                                                                    </h1>                                        
                                                        
                                                    <h5 className="card-text1">Enroll Later</h5>
                                                </div>
                                                <div className="col-auto">
                                                    <OverlayTrigger placement="top" overlay={renderMCI24Tooltip}>
                                                        <i className='bx bx-info-circle'></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col border-end">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title2 mt-1">{totalCallConversionData.count_reject}
                                                        {totalReachedCount > 0 ? (
                                                            <span><br/>({((totalCallConversionData.count_reject / totalReachedCount) * 100).toFixed(2)}%)</span>
                                                        ) : (
                                                        <span><br/>(0%)</span>
                                                        )}
                                                    </h1>
                                                    <h5 className="card-text1">Not Interested</h5>
                                                </div>
                                                <div className="col-auto">
                                                    <OverlayTrigger placement="top" overlay={renderMCI4Tooltip}>
                                                        <i className='bx bx-info-circle'></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className="col border-end">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title2 mt-1">
                                                    {totalIncorrectData}

                                                    {totalReachedCount > 0 ? (
                                                        <>
                                                        <br/>
                                                        <span>({((totalIncorrectData / totalReachedCount) * 100).toFixed(2)}%)</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                    <br/><span>(0%)</span>
                                                    </>
                                                    )} </h1>
                                                        
                                                        
                                                    <h5 className="card-text1">Invalid Data</h5>
                                                </div>
                                                <div className="col-auto ps-0">
                                                    <OverlayTrigger placement="top" overlay={renderMCI5Tooltip}>
                                                        <i className='bx bx-info-circle'></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}


                <div className="member-engagement p-3 mb-3">
                    <div className="row align-items-center mb-2">
                        <div className="col">
                            <h1 className='dashboard-graph-title' style={{justifyContent:"flex-start"}}>Reaching out members
                            <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Overview of the status breakdown for member outreach.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                            </h1>
                        </div>
                    </div>
                    <div className="row text-center px-2">
                        <div className="col" >
                            <div className="row">
                                <div className="col-2 px-1" >
                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{statsValue.membersMigrated}</h1>
                                                    <h5 className="card-text1">Members Migrated
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Members successfully migrated by the RCM team.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-2  px-1" >
                                    <div className={`card bg-grey mb-1`} style={{ borderRadius: '14px', border: '2px solid #1279be' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{statsValue.touchedCount}</h1>
                                                    <h5 className="card-text1">Touched
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Members who have been contacted.</Tooltip>}
                                                    >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                        <div className="card-body p-2">
                                          
                                            <div className="row">
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h1 className="card-title1">{statsValue.untouchedCount}</h1>
                                                            <h5 className="card-text1">Untouched
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip className="custom-tooltip">Members who have not yet been contacted.</Tooltip>}
                                                            >
                                                            <i className='bx bx-info-circle ms-1'></i>
                                                            </OverlayTrigger>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="col-2  px-1" >
                                    <div className={`card bg-grey mb-1`} style={{ borderRadius: '14px', border: '2px solid #1279be' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{statsValue.validCount}</h1>
                                                    <h5 className="card-text1">Valid
                                                    <OverlayTrigger 
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Members successfully reached by agents.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h1 className="card-title1">{statsValue.invalidCount}</h1>
                                                            <h5 className="card-text1">Incorrect
                                                            <OverlayTrigger 
                                                                placement="top"
                                                                overlay={<Tooltip className="custom-tooltip">Members marked as invalid data by agents.</Tooltip>}
                                                            >
                                                                <i className='bx bx-info-circle ms-1'></i>
                                                            </OverlayTrigger>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col" >
                                    <div className="row">
                                    <div className="col-12 px-1 mb-1" >
                                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%",border: '2px solid #1279be' }}>
                                        <div className="card-body p-2">
                                        <div className="row">
                                                        <div className="col border-end">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.onboardedCount}</h1>
                                                                    <h5 className="card-text1">Onboarded
                                                                    <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members who interested to join program.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.extraEntryCount}</h1>
                                                                <h5 className="card-text1">Extra Manual Onboarded
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members manually uploaded into the system by agents.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                        <div className="col-12 px-1 mb-2" >
                                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                                <div className="card-body p-2">
                                                <div className="row">
                                                    <div className="col border-end">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.notInterstedCount}</h1>
                                                                <h5 className="card-text1">Not Interested
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members who are not interested in the program.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col border-end">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.backPoolCount}</h1>
                                                                <h5 className="card-text1">Back to pool
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members who can be contacted again for onboarding.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.onboardLaterCount}</h1>
                                                                <h5 className="card-text1">Onboard later
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members interested in the program but will onboard later.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-1" >
                                <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid #1279be' }}>
                                    <div className="card-body p-2">
                                        <div className="row">
                                            <div className="col">
                                                <h1 className="card-title1">{statsValue.totalOnboardCount}</h1>
                                                <h5 className="card-text1">Total Onboarded
                                                <OverlayTrigger 
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Combined total of members onboarded by both RCM and agents.</Tooltip>}
                                                    >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>

                    <div className="row align-items-center mt-4 mb-2">
                        <div className="col"><h1 className='main-title'>Agent Performance</h1></div>
                    </div>



                    <div className="row">

                       <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Calls Made
                                    <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    <span className="dashboard-graph-number">{totalCallMadeCount}</span>
                                </h1>

                                <CallsMadedBar startDate={startDate} endDate={endDate} agentId={stateAgentId} parentCallback={handleCallMadeCallback} />
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Members Reached
                                    <OverlayTrigger placement="top" overlay={renderMCI6Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    <span className="dashboard-graph-number">{totalReachedCount}</span>
                                </h1>

                                <MembersReachedBar startDate={startDate} endDate={endDate} agentId={stateAgentId} parentCallback={handleReachedCallback}/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Onboarded Members
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Members who interested to join the program</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    <span className="dashboard-graph-number">{totalEnrollCount}</span>
                                </h1>

                                <MembersEnrolledBar startDate={startDate} endDate={endDate} agentId={stateAgentId} parentCallback={handleEnrollCallback}/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Agents
                                    <OverlayTrigger placement="top" overlay={renderMCI9Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    <span className="dashboard-graph-number">{totalAgentsCount}</span>
                                </h1>

                                <AgentsBar startDate={startDate} endDate={endDate} agentId={stateAgentId} parentCallback={handleAgentCallback}/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Target Achievement 
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Target Status achieved by agents</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalTargetAchieved}%</span> */}
                                </h1>

                                <TargetAchievementBar startDate={startDate} endDate={endDate} agentId={stateAgentId} parentCallback={handleTargetAchievedCallback}/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Call Conversion 
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Bifurcation of call conversions</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    {/* <span className="dashboard-graph-number">89%</span> */}
                                </h1>

                                <CallConversionDispositionBar startDate={startDate} endDate={endDate} agentId={stateAgentId} parentCallback={handleCallConversionCallback}/>
                            </div>
                        </div>
                
                    </div>

                    <div className="member-engagement mb-3">

                    <div className="row text-center px-2">

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{avgCallTime} <span>min</span></h1>
                                        <h5 className="card-text1">Average Call Duration
                                        <OverlayTrigger placement="top" overlay={renderMCI12Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCI12Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{attemptCallTotal}</h1>
                                        <h5 className="card-text1">Attempt per member
                                        <OverlayTrigger placement="top" overlay={renderMCI13Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCI13Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        {/* <h1 className="card-title1">{firstCallConversionRate}</h1> */}
                                        <h1 className="card-title1">0</h1>
                                        <h5 className="card-text1">First Call Conversion Rate
                                        <OverlayTrigger placement="top" overlay={renderMCI14Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCI14Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{memberSatisfaction}</h1>
                                        <h5 className="card-text1">Member Satisfaction
                                        <OverlayTrigger placement="top" overlay={renderMCI15Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCI15Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{agentUtilizationRate}</h1>
                                        <h5 className="card-text1">Agent Utilization Rate
                                        <OverlayTrigger placement="top" overlay={renderMCI16Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                    {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderMCI16Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    </div>
                
                <div className="row">

                    <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Data Quality
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Measurement of data quality</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    {/* <span className="dashboard-graph-number">1500</span> */}
                                </h1>

                                <DataQualityPie startDate={startDate} endDate={endDate} agentId={stateAgentId} />
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Correct Data
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Correct marked data disposition</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    {/* <span className="dashboard-graph-number">1500</span> */}
                                </h1>

                                <MemberCorrectDataFunnelBar startDate={startDate} endDate={endDate} agentId={stateAgentId} />
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Incorrect Data
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Incorrect marked data disposition</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    {/* <span className="dashboard-graph-number">1500</span> */}
                                </h1>

                                <MemberIncorrectDataFunnelBar startDate={startDate} endDate={endDate} agentId={stateAgentId} />
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Member Conversion funnel
                                    <OverlayTrigger placement="top" overlay={renderMCI18Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    {/* <span className="dashboard-graph-number">1500</span> */}
                                </h1>

                                <MemberConversionFunnelBar startDate={startDate} endDate={endDate} agentId={stateAgentId} memberReached = {totalReachedCount} memberEnrolled={totalEnrollCount} parentCallback={handleAgentMemberCallback}/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Age Group Conversion
                                    <OverlayTrigger placement="top" overlay={renderMCI19Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    {/* <span className="dashboard-graph-number">1500</span> */}
                                </h1>

                                <AgeGroupConversionBar startDate={startDate} endDate={endDate} agentId={stateAgentId} />
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Call Status 
                                    <OverlayTrigger placement="top" overlay={renderMCI11Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    {/* <span className="dashboard-graph-number">89%</span> */}
                                </h1>

                                <CallDispositionBar startDate={startDate} endDate={endDate} agentId={stateAgentId}/>
                            </div>
                        </div>

                </div>

                
                

            </div>

            </section>
        </>
    );
}

export default ContentCallHistory;
