import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { add_new_call_disposition, fetch_setting_disposition_agent_list } from '../../../services/met/Service';

import { CustomLoader } from '../../utils/CommonLibrary';
import TableSettingDisposition from './TableSettingDisposition';
import { validateFormCallDispositionAdd } from '../../utils/validationUtils';



function SettingDispositionAgent() {

    const [selectedData, setSelectedData] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const handleClose = () =>{  setShowModal(false);}
    const [finalData, setFinalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [key, setKey] = useState(1);

    const [formData, setFormData] = useState({
        dataRating: '',
        reason: '',
    })

    const [errors, setErrors] = useState({
        dataRating: '',
        reason: '',
    })

    useEffect(() => {
       // fetchData();
        fetchAllData();
    }, []);

   

    const fetchAllData = async () => {
        try{
            let resp = await fetch_setting_disposition_agent_list();
            if (resp.data.code === 200) {
                console.log("resp.data",resp.data.data)
                setFinalData(resp.data.data);
                console.log("finalData",finalData);
            }else{
                setFinalData(resp.data.data);
            }
        } catch (error) {
            console.error(error);
        }finally {
        //setIsLoading(false); // Will be called regardless of success or failure
        }
    }

    const handleChange = (event) => {
        setFormData((prevData) => ({ ...prevData, ['dataRating']: event.target.value}));
    };


    const addNewDisposition = async (e) => {
        e.preventDefault();
        setErrors('')

        const validationErrors = validateFormCallDispositionAdd(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        }else{
            console.log("formData",formData);
            add_new_call_disposition(formData)
            .then((response) => {   
                if(response.data.code == 200){
                    setShowModal(false);
                    fetchAllData();
                    
                }
            });
        }
    }

    const onValueChange = (e) =>{    
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const setShowModalOpen = (e) => {
        setFormData('');
        setShowModal(true);
        
    }

  return (
    <>
        <section className='ContentPrograms'>
            <div className="container-fluid px-0 mb-3">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md"> 
                        <h4 className="mb-0 content-main-title">Agent Disposition Settings</h4>
                    </div>
                    <div className="col-md text-end">
                        <button onClick={setShowModalOpen} className="btn btn-primary add-new-program-btn">
                            <i className="bi bi-card-checklist me-2"></i> Add New Disposition
                        </button>
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/5jw18/wh4l7e7hk4.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The agent disposition settings enable you to efficiently manage call disposition reasons, ensuring accurate data tracking and correction.</p>
                        </div>
                    </div>
                </div>
            
            <div className='content-programs mb-3'>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        {finalData.length > 0 && <TableSettingDisposition initialData={finalData} key={finalData.length} /> } 
                    </div>
                </div>
            </div>
            </div>       
            
            </div>
            {/* { loading && <CustomLoader /> } */}
        </section>

{showModal && 
         <div className="modal fade show" id="addNewServiceModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="main-title">Add New Call Disposition</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div class="modal-body pt-1 mb-2">
                        <form className='' onSubmit={addNewDisposition}> 
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor='Condition' class="form-label">Data Rating </label>
                                    <span style={{color:'red'}}> *</span>
                                        <select class="form-select input-field-ui" aria-label="Default select example" value={formData['dataRating']} onChange={handleChange}>
                                            <option value="Condition">Select Data Rating</option>
                                            <option value="correct">Correct</option>
                                            <option value="incorrect">Incorrect</option>
                                        </select>
                                        {(errors['dataRating'] ) && <div className="text-danger">{errors['dataRating']}</div>}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='Condition' class="form-label">Reason </label>
                                        <span style={{color:'red'}}> *</span>
                                        <textarea
                                        className="form-control input-field-ui"
                                        placeholder="Reason"
                                        rows="3"
                                        name="reason"
                                        onChange={(e) => onValueChange(e)} value={formData['reason']}
                                        />
                                        {errors['reason'] && <div className="text-danger">{errors['reason']}</div>}
                                    </div>
                                    
                                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        >
                                        Add Disposition
                                        </button>
                                        {/* <button
                                        type="button"
                                        className="btn btn-secondary clear-btn"
                                        onClick={handleClose}
                                        >
                                        Cancel
                                        </button> */}
                                    </div>
                                </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
            
                </div> }

                


        

        
    </>
  );
}

export default SettingDispositionAgent;
