import React,{useState,useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import exporting from 'highcharts/modules/exporting';
import { agent_data_quality_graph } from '../../../services/met/Service';
import { formatDate } from '../../utils/CommonLibrary';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const DataQualityPie = ({startDate,endDate,agentId}) => {
  // Pie chart data for service completion rate
  const [loadingCount, setLoadingCount] = useState(0);
  const [incorrectData, setIncorrectData] = useState(0);
  const [correctData, setCorrectData] = useState(0);

  useEffect(() => {
    agentDataQualityGraph();    
}, [agentId,startDate,endDate]);

  const agentDataQualityGraph = async () => {
    try {
        let sdate = formatDate(startDate);
        let edate = formatDate(endDate);
        let response = await agent_data_quality_graph(sdate,edate,agentId);
        if(response.data.code === 200){
              setCorrectData(response.data.data);
              setIncorrectData(response.data.data);
              setLoadingCount(response.data.data.length)
        }
    } catch (error) {
        console.log(error);
    } 
}
  const pieData = [
    {
      name: 'Correct ',
      y: correctData['count_1'], // Example percentage, replace with actual data
      color: '#00B9FB' 
    },
    {
      name: 'Incorrect',
      y: incorrectData['count_2'], 
      color: '#1E2386' 
    },
    // {
    //   name: 'Poor',
    //   y: 5, 
    //   color: '#1E2386' 
    // }
  ];

  const pieOptionsMCI2 = {
    chart: {
      type: 'pie',
      height: 200,
      spacing: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 70,
        beta: 0
      },
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif",
        marginBottom: '10px'
      }
    },
    tooltip: {
      pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 30,
        size: 190,
        dataLabels: {
          enabled: true, // Enable data labels
          format: '{point.percentage:.1f}%',
          distance: 5, // Adjust label position
          alignTo: 'toPlotEdges' // Center the label inside the pie slice
        },
        showInLegend: true
      }
    },
    series: [{
      name: 'Service Status',
      data: pieData
    }],
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: true
    }
  };

  return (
    <>
    {correctData['count_1'] > 0 || incorrectData['count_2'] > 0 ? (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={pieOptionsMCI2}
      />
    </div>
     ) : (

      <div className="emty-pie">
          <h5 className="emty-title-graph">There are no data to display</h5>
      </div>
     )} 
     </>
  );
};

export default DataQualityPie;
