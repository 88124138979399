import React, { useState } from 'react';
import './MemberEngagementPage.css';
import ContentMemberEngagement from '../../componenents/ContentMemberEngagement';


function MemberEngagementPage() {
  const [title, setTitle] = useState('Member Engagement Automation - Phase 2');

  return (
    <>
        <ContentMemberEngagement/>
    </>
  );
}


export default MemberEngagementPage;
