import React, { useState } from 'react'; 
import { comment_on_observation } from '../../../services/observations/Service';


const CommentForm = ({observationId, commentBy, fetchObservationThread, fetchObservationHistory}) => {

    const [commentForm, setCommentForm] = useState({ 
        comment: "", 
        files: ""
    });

    const [commentFormErrors, setCommentFormErrors]= useState({ 
        comment: "", 
        files: ""
    });

    const [fileInputs, setFileInputs] = useState([{ id: 0 }]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    function handleCommentChange(e) {

        if (e.target.name === 'files[]') {
            const inputId = parseInt(e.target.getAttribute('data-input-id'));
            const updatedFiles = Array.from(commentForm.files || []);
            updatedFiles[inputId] = e.target.files[0];
            const filteredFiles = updatedFiles.filter(file => file !== null);
            setCommentForm({ ...commentForm, files: filteredFiles });
            setCommentFormErrors({ ...commentFormErrors, files: '' });
            return;
        }

        setCommentForm({ ...commentForm, [e.target.name]: e.target.value });
        setCommentFormErrors({ ...commentFormErrors, [e.target.name]: '' });
    }

    function validateForm(){ 
        let errors = {}; 

        if (commentForm.files) {
            if (commentForm.files.size > 10000000) {
              errors.files = 'File size is too large. Max size is 10MB';
            }
      
            const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png','mp4'];
            Array.from(commentForm.files).forEach(file => {
              const fileExtension = file.name.split('.').pop().toLowerCase();        
              if (!allowedExtensions.includes(fileExtension)) {
                errors.files = 'Invalid file type. Only PDF, JPEG, JPG, PNG, TIFF, DOC, DOCX, XLS, XLSX, CSV, TXT, and DCM files are allowed';
              }
            });
      
        }


        if(!commentForm.comment.trim()){
            errors.comment = 'Comment is required';
        }
        
        return errors; 
    }

    const submitComment = async () => { 
        let errors = validateForm(); 
        if(Object.keys(errors).length > 0){ 
            setCommentFormErrors(errors); 
            return; 
        }

        setIsSubmitting(true);

        let formData = new FormData();

        for (const file of commentForm.files) {
            formData.append('files[]', file);         
        }
        
        let commentCopy = {...commentForm};
        delete commentCopy.files;

        let commentDataObj  = {...commentCopy, observationId: observationId, commentBy: commentBy};
        formData.append('commentData', JSON.stringify(commentDataObj));    

        let requestData = commentForm.files.length > 0 ? formData : {commentData: JSON.stringify(commentDataObj)};

        // setIsrequestLoading(true);
        try{
             let response = await comment_on_observation(requestData); 

             if(response.data.code === 200){
                fetchObservationThread();
                // clear the state data 
                setCommentForm({ comment: '', files: '' });
                setCommentFormErrors({ comment: '', files: '' });
                setFileInputs([{ id: 0 }]);
                const fileInputElements = document.querySelectorAll('input[type="file"]');
                fileInputElements.forEach(input => {
                    input.value = '';
                });

                fetchObservationHistory(); 

             }else { 

             }
        }catch(error){
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }

    }

    const addFileInput = () => {
        setFileInputs([...fileInputs, { id: fileInputs.length }]);
    };

    const removeFileInput = (inputId) => {
        setFileInputs(fileInputs.filter(input => input.id !== inputId));
        
        const updatedFiles = Array.from(commentForm.files || []);
        updatedFiles[inputId] = null;
        const filteredFiles = updatedFiles.filter(file => file !== null);
        setCommentForm({ ...commentForm, files: filteredFiles });
    };

  return (
    <>
    <div className='bg-light p-3 input-field-ui'>
    <div className="row align-items-center">
            <div className="col-md-12 mb-3">
                {/* <label className="form-label">Description <span className="text-danger">*</span></label> */}
                <textarea
                    name="comment"
                    placeholder="Comment"
                    rows="3"
                    className="form-control control-input-ui"
                    value={commentForm.comment}
                    onChange={e => handleCommentChange(e)}
                />
                {commentFormErrors.comment && <p className="text-danger">{commentFormErrors.comment}</p>}
            </div>


            <div className="col-5">
                <label className="form-label">Upload Screenshots (Max 5MB per image):</label>
                {fileInputs.map((input) => (
                    <div className="d-flex mb-2" key={input.id}>
                        <input
                            type="file"
                            name="files[]"
                            className="form-control input-field-ui"
                            accept=".png, .jpg, .jpeg, .gif, .doc, .docx, .pdf, .csv, .xls, .xlsx"
                            data-input-id={input.id}
                            onChange={e => handleCommentChange(e)}
                        />
                        {fileInputs.length > 1 && (
                            <button 
                                type="button" 
                                className="btn btn-Cancel ms-2" 
                                onClick={() => removeFileInput(input.id)}
                            >
                                <i className="bi bi-trash"></i>
                            </button>
                        )}
                    </div>
                ))}
                <button 
                    type="button" 
                    className="btn btn-link p-0" 
                    onClick={addFileInput}
                >
                    Add New File
                </button>
                {commentFormErrors.files && <p className="text-danger">{commentFormErrors.files}</p>}
            </div>


            <div className="col-12 mt-3">
                <button 
                    type="button" 
                    id="xlsxSubmit" 
                    className="btn btn-success uplaod-file-btn"
                    onClick={e => submitComment(e)}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? (
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Submitting...
                        </>
                    ) : 'Submit'}
                </button>
            </div>
        </div>
    </div>
        

    </>
  )
}

export default CommentForm
