import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaWhatsapp, FaArrowLeft, FaSync, FaInfoCircle, FaCopy, FaPlusCircle, FaUser, FaTag, FaReply, FaClock } from 'react-icons/fa';

const CampaignDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPreview, setShowPreview] = useState(false);
    const [previewDevice, setPreviewDevice] = useState('android');
    const [mounted, setMounted] = useState(false)
    const campaignData = location.state?.campaignData || {
      name: "no user story 08",
      status: "Completed",
      statistics: {
        attempted: 67,
        sent: 65,
        delivered: 61,
        read: 44,
        replied: 1,
        failed: 2
      },
      notificationType: "OneTime",
      audience: {
        users: "Users",
        tags: "Tags is pa 08"
      },
      message: {
        templateName: "asking_notes_images",
        body: "नमस्कार। मैं परवेज़ आलम, GOQii से बोल रहा हूं।\n\nआपकी GOQii कोच आपसे कॉल पर संपर्क करने की कोशिश कर रही थी। हम आपकी सेहत की प्रगति की जांच के लिए आपका दवाखाना में भी गए थे, लेकिन आपसे संपर्क नहीं हो पाया। कृपया अपनी सभी ब्लड रिपोर्ट्स और अपना दवाखाना की नोटबुक की शुरुआत से सभी तस्वीरें हमें इस नंबर पर 9819363557 पर व्हाट्सएप (Whatsapp) पर भेजें ताकि हम आपकी सेहत की प्रोग्रेस को ट्रैक कर सकें। 🙏\n\n#BETHEFORCE"
      },
      schedule: {
        startedOn: "08/08/2024 04:51:27 PM",
        endedOn: "08/08/2024 04:51:30 PM"
      }
    };
  
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setMounted(true);
      }, []);
  
    const refreshData = () => {
      console.log("Refreshing data...");
    };
  
    const copyToClipboard = () => {
      navigator.clipboard.writeText(campaignData.name);
      // You might want to show a temporary notification here
    };
  
    const MessagePreview = ({ device, message }) => {
        const mockupContent = (
          <div className="tw-p-4 tw-bg-white tw-rounded-xl tw-overflow-hidden tw-w-[272px] tw-h-[572px]">
            <div className="tw-mb-2 tw-text-sm tw-font-bold">Today</div>
            <div className="tw-bg-blue-100 tw-p-2 tw-rounded-lg tw-mb-2">
              <p className="tw-whitespace-pre-wrap tw-text-xs">{message}</p>
              <p className="tw-text-right tw-text-[10px] tw-text-gray-500 tw-mt-1">11:52 AM</p>
            </div>
          </div>
        );
      
        if (device === 'ios') {
          return (
            <div className="tw-relative tw-mx-auto tw-border-gray-800 tw-bg-gray-800 tw-border-[14px] tw-rounded-[2.5rem] tw-h-[600px] tw-w-[300px] tw-shadow-xl">
              <div className="tw-w-[148px] tw-h-[18px] tw-bg-gray-800 tw-top-0 tw-rounded-b-[1rem] tw-left-1/2 tw--translate-x-1/2 tw-absolute"></div>
              <div className="tw-h-[46px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--start-[17px] tw-top-[124px] tw-rounded-s-lg"></div>
              <div className="tw-h-[46px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--start-[17px] tw-top-[178px] tw-rounded-s-lg"></div>
              <div className="tw-h-[64px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--end-[17px] tw-top-[142px] tw-rounded-e-lg"></div>
              <div className="tw-rounded-[2rem] tw-overflow-hidden tw-w-[272px] tw-h-[572px] tw-bg-white">
                {mockupContent}
              </div>
            </div>
          );
        } else {
          return (
            <div className="tw-relative tw-mx-auto tw-border-gray-800 tw-bg-gray-800 tw-border-[14px] tw-rounded-xl tw-h-[600px] tw-w-[300px] tw-shadow-xl">
              <div className="tw-w-[148px] tw-h-[18px] tw-bg-gray-800 tw-top-0 tw-rounded-b-[1rem] tw-left-1/2 tw--translate-x-1/2 tw-absolute"></div>
              <div className="tw-h-[32px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--start-[17px] tw-top-[72px] tw-rounded-s-lg"></div>
              <div className="tw-h-[46px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--start-[17px] tw-top-[124px] tw-rounded-s-lg"></div>
              <div className="tw-h-[46px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--start-[17px] tw-top-[178px] tw-rounded-s-lg"></div>
              <div className="tw-h-[64px] tw-w-[3px] tw-bg-gray-800 tw-absolute tw--end-[17px] tw-top-[142px] tw-rounded-e-lg"></div>
              <div className="tw-rounded-xl tw-overflow-hidden tw-w-[272px] tw-h-[572px] tw-bg-white">
                {mockupContent}
              </div>
            </div>
          );
        }
      };

  const StatBox = ({ title, value, hasViewList = true }) => (
    <div className="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4 tw-text-center tw-flex tw-flex-col tw-justify-between tw-h-full tw-relative tw-transition-all tw-duration-300 hover:tw-shadow-lg">
      <div>
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
          <span className="tw-text-xl tw-text-center tw-font-bold tw-w-full tw-text-gray-700">{value}</span>
          <div className="tw-group tw-absolute tw-top-2 tw-right-2">
            <FaInfoCircle className="tw-text-gray-400 tw-cursor-help" />
            <div className="tw-hidden group-hover:tw-block tw-absolute tw-z-50 tw-bg-sky-800 tw-text-white tw-p-2 tw-rounded tw-text-sm tw--mt-1 tw-right-0 tw-w-48">
              Tooltip for {title}
            </div>
          </div>
        </div>
        <div className="tw-text-sm tw-text-gray-600">{title}</div>
      </div>
      {hasViewList && (
        <a href="#" className="tw-text-gray-500 tw-text-sm tw-mt-2 hover:tw-text-gray-700 tw-transition-colors tw-duration-300">View user list</a>
      )}
    </div>
  );

  const Modal = ({ show, onClose, children }) => {
    if (!show) return null;
    return (
      <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-z-50 tw-transition-opacity tw-duration-300" style={{ opacity: show ? 1 : 0 }}>
        <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-max-w-2xl tw-w-full tw-max-h-[80vh] tw-overflow-y-auto tw-shadow-2xl tw-transform tw-transition-all tw-duration-300 tw-scale-95" style={{ transform: show ? 'scale(1)' : 'scale(0.95)' }}>
          {children}
          <button 
            onClick={onClose}
            className="tw-mt-4 tw-bg-sky-600 tw-text-white tw-px-4 tw-py-2 tw-rounded tw-transition-colors tw-duration-300 hover:tw-bg-sky-800"
          >
            Okay, Got it!
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={`tw-container tw-mx-auto tw-p-4 tw-transition-opacity tw-duration-500 ${mounted ? 'tw-opacity-100' : 'tw-opacity-0'}`}>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-6">
        <div className="tw-flex tw-items-center tw-space-x-4">
          <FaArrowLeft 
            className="tw-text-gray-600 tw-cursor-pointer tw-transition-transform tw-duration-300 hover:tw-scale-110" 
            onClick={() => navigate('/notification-dashboard')}
          />
          <h1 className="tw-text-2xl tw-font-bold tw-text-gray-800">{campaignData.name}</h1>
          <FaWhatsapp className="tw-text-green-500" />
          <span className="tw-bg-sky-100 tw-text-gray-800 tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-semibold">
            {campaignData.status}
          </span>
          <div className="tw-group tw-relative">
            <FaCopy 
              className="tw-text-gray-400 tw-cursor-pointer tw-transition-transform tw-duration-300 hover:tw-scale-110" 
              onClick={copyToClipboard} 
            />
            <div className="tw-hidden group-hover:tw-block tw-absolute tw-z-50 tw-bg-sky-800 tw-text-white tw-p-2 tw-rounded tw-text-sm tw-mt-1 tw-left-1/2 tw-transform tw--translate-x-1/2">
              Copy campaign
            </div>
          </div>
        </div>
      </div>

      <div className="tw-bg-sky-50 tw-p-4 tw-rounded-lg tw-flex tw-items-center tw-space-x-2 tw-mb-6 tw-shadow-md">
        <div className="tw-group tw-relative">
          <FaInfoCircle className="tw-text-gray-500" />
          <div className="tw-hidden group-hover:tw-block tw-absolute tw-z-50 tw-bg-sky-800 tw-text-white tw-p-2 tw-rounded tw-text-sm tw-mt-1 tw-left-1/2 tw-transform tw--translate-x-1/2">
            Refresh data info
          </div>
        </div>
        <span className="tw-flex-grow tw-text-gray-700">Click 'Refresh Data' to see the most current stats on this page</span>
        <button 
          onClick={refreshData}
          className="tw-bg-sky-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg tw-flex tw-items-center tw-space-x-2 tw-transition-colors tw-duration-300 hover:tw-bg-sky-800"
        >
          <FaSync className="tw-animate-spin" /> <span>Refresh Data</span>
        </button>
      </div>

      <div className="tw-mb-8">
        <div className="tw-flex tw-justify-between tw-mb-4">
          <h2 className="tw-text-2xl tw-font-semibold tw-text-gray-800">Statistics</h2>
          <div className="tw-space-x-4">
            <button 
              className="tw-border tw-border-sky-600 tw-text-gray-500 tw-px-4 tw-py-2 tw-rounded tw-transition-colors tw-duration-300 hover:tw-bg-sky-800 hover:tw-text-white"
              onClick={() => setShowModal(true)}
            >
              Know your data
            </button>
            <button className="tw-border tw-border-sky-600 tw-text-gray-500 tw-px-4 tw-py-2 tw-rounded tw-transition-colors tw-duration-300 hover:tw-bg-sky-800 hover:tw-text-white">
              Report Downloaded
            </button>
          </div>
        </div>
        <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-6 tw-gap-4">
          <StatBox title="Attempted" value={`${campaignData.statistics.attempted} out of ${campaignData.statistics.attempted}`} />
          <StatBox title="Sent" value={campaignData.statistics.sent} />
          <StatBox title="Delivered" value={campaignData.statistics.delivered} />
          <StatBox title="Read" value={campaignData.statistics.read} />
          <StatBox title="Replied" value={campaignData.statistics.replied} />
          <StatBox title="Failed" value={campaignData.statistics.failed} hasViewList={false} />
        </div>
      </div>

      <div className="tw-mb-8">
        <h2 className="tw-text-2xl tw-font-semibold tw-mb-4 tw-text-gray-800">Button Tracking</h2>
        <div className="tw-overflow-x-auto tw-shadow-md tw-rounded-lg">
          <table className="tw-w-full tw-border-collapse tw-border tw-border-sky-200">
            <thead>
              <tr className="tw-bg-sky-50">
                <th className="tw-border tw-border-sky-200 tw-p-3 tw-text-left tw-text-gray-700">Button Name</th>
                <th className="tw-border tw-border-sky-200 tw-p-3 tw-text-left tw-text-gray-700">Button Type</th>
                <th className="tw-border tw-border-sky-200 tw-p-3 tw-text-left tw-text-gray-700">Number of Clicks</th>
                <th className="tw-border tw-border-sky-200 tw-p-3 tw-text-left tw-text-gray-700">Click Percentage</th>
                <th className="tw-border tw-border-sky-200 tw-p-3 tw-text-left tw-text-gray-700">Users</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="5" className="tw-border tw-border-sky-200 tw-p-4 tw-text-center tw-text-gray-500">
                  <FaPlusCircle className="tw-text-4xl tw-text-gray-300 tw-mx-auto tw-mb-2" />
                  No result found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tw-mb-16">
        <h2 className="tw-text-2xl tw-font-bold tw-mb-4 tw-text-gray-800">Detail</h2>
        
        <div className="tw-bg-sky-50 tw-rounded-lg tw-overflow-hidden tw-shadow-md">
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-divide-y md:tw-divide-y-0 md:tw-divide-x tw-divide-sky-200">
            <div className="tw-p-4 tw-bg-white">
              <h3 className="tw-text-gray-700 tw-font-semibold tw-mb-2">Notification type</h3>
              <p className="tw-text-gray-600">{campaignData.notificationType}</p>
            </div>
            <div className="tw-p-4">
              <h3 className="tw-text-gray-700 tw-font-semibold tw-mb-2">Audience</h3>
              <p className="tw-flex tw-items-center tw-text-gray-600"><FaUser className="tw-mr-2 tw-text-gray-600" />{campaignData.audience.users}</p>
              <p className="tw-flex tw-items-center tw-mt-1 tw-text-gray-600"><FaTag className="tw-mr-2 tw-text-gray-600" />{campaignData.audience.tags}</p>
            </div>
            <div className="tw-p-4 tw-bg-white md:tw-col-span-2">
              <h3 className="tw-text-gray-700 tw-font-semibold tw-mb-2">Message</h3>
              <p className="tw-mb-2"><span className="tw-font-medium tw-text-gray-600">Template Name:</span> {campaignData.message.templateName}</p>
              <div className="tw-bg-sky-100 tw-p-4 tw-rounded tw-mb-4">
                <p className="tw-whitespace-pre-wrap tw-text-gray-700">{campaignData.message.body}</p>
              </div>
              <h4 className="tw-text-gray-700 tw-font-semibold tw-mb-2">Footer</h4>
              <p className="tw-mb-4 tw-text-gray-600">#BETHEFORCE</p>
              <button 
                onClick={() => setShowPreview(true)}
                className="tw-bg-sky-600 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-sky-800 tw-transition-colors tw-duration-300"
              >
                See Template Preview
              </button>
            </div>
            <div className="tw-p-4">
              <h3 className="tw-text-gray-700 tw-font-semibold tw-mb-2">Reply Flow</h3>
              <p className="tw-flex tw-items-center tw-text-gray-600"><FaReply className="tw-mr-2 tw-text-gray-600" />No chat flows are set for this campaign</p>
            </div>
            <div className="tw-p-4  tw-bg-white">
              <h3 className="tw-text-gray-700 tw-font-semibold tw-mb-2">Schedule</h3>
              <p className="tw-flex tw-items-center tw-mb-1 tw-text-gray-600"><FaClock className="tw-mr-2 tw-text-gray-600" />Started on: {campaignData.schedule.startedOn}</p>
              <p className="tw-flex tw-items-center tw-text-gray-600"><FaClock className="tw-mr-2 tw-text-gray-600" />Ended on: {campaignData.schedule.endedOn}</p>
            </div>
          </div>
        </div>
      </div>

      {showPreview && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-p-4 tw-z-50 tw-transition-opacity tw-duration-300" style={{ opacity: showPreview ? 1 : 0 }}>
          <div className="tw-bg-white tw-rounded-lg tw-p-4 tw-w-full tw-max-w-md tw-max-h-[80vh] tw-overflow-y-auto tw-shadow-2xl tw-transform tw-transition-all tw-duration-300 tw-scale-95" style={{ transform: showPreview ? 'scale(1)' : 'scale(0.95)' }}>
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
              <h3 className="tw-text-lg tw-font-bold tw-text-gray-800">Preview</h3>
              <div>
                <button 
                  onClick={() => setPreviewDevice('android')}
                  className={`tw-mr-2 tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-transition-colors tw-duration-300 ${previewDevice === 'android' ? 'tw-bg-sky-500 tw-text-white' : 'tw-bg-gray-200 tw-text-gray-700 hover:tw-bg-sky-100'}`}
                >
                  Android
                </button>
                <button 
                  onClick={() => setPreviewDevice('ios')}
                  className={`tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-transition-colors tw-duration-300 ${previewDevice === 'ios' ? 'tw-bg-sky-500 tw-text-white' : 'tw-bg-gray-200 tw-text-gray-700 hover:tw-bg-sky-100'}`}
                >
                  iOS
                </button>
              </div>
            </div>
            <div className="tw-flex tw-justify-center tw-items-center">
              <MessagePreview device={previewDevice} message={campaignData.message.body} />
            </div>
            <button 
              onClick={() => setShowPreview(false)}
              className="tw-mt-4 tw-bg-sky-500 tw-text-white tw-px-3 tw-py-1 tw-rounded hover:tw-bg-sky-600 tw-text-sm tw-transition-colors tw-duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <h2 className="tw-text-xl tw-font-bold tw-mb-4 tw-text-gray-800">Know Your Data</h2>
        <ul className="tw-list-disc tw-pl-5 tw-space-y-2 tw-text-gray-700">
          <li>Sent, Delivered, Read, Replied, and Failed statuses will update as we receive information from Meta.</li>
          <li>Delivered, Read, and Replied statuses may update indefinitely due to external factors like network strength and recipient actions.</li>
          <li>Sent and Failed counts should equal the Attempted count, but slight data discrepancies may cause variations.</li>
          <li><strong>Attempted</strong> denotes the count of notifications Interakt tried to send to WhatsApp.</li>
          <li><strong>Sent</strong> denotes the count of notifications successfully sent to the recipient's phone, but it doesn't guarantee receipt, as the phone could be off or in a poor network zone (equivalent to a single tick on WhatsApp).</li>
          <li><strong>Failed</strong> denotes the count of notifications that couldn't be sent to the recipient's phone.</li>
          <li><strong>Delivered</strong> denotes the count of notifications successfully received by the recipient (equivalent to a double tick on WhatsApp).</li>
          <li><strong>Read</strong> denotes the count of notifications read by the recipient (equivalent to a blue tick on WhatsApp).</li>
          <li><strong>Replied</strong> denotes the count of notifications which received a reply within 72 hours.</li>
        </ul>
      </Modal>
    </div>
  );
};

export default CampaignDetails;