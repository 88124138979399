import { useNavigate } from "react-router-dom";
import  { useEffect } from 'react';


const Logout = () => {

    const nav = useNavigate();
        // localStorage.removeItem("user");
        localStorage.clear();
        

        useEffect(() => {
            return nav('/login');
        }, []);
};


export default Logout;