import React from 'react';
import './ContentPrograms.css';
import ContentProgramsNoActivePrograms from './ContentPrograms/ContentProgramsNoActivePrograms';
import ContentProgramsDetails from './ContentPrograms/ContentProgramsDetails';
import ContentDraftProgramsList from './ContentPrograms/ContentDraftProgramsList';

function ContentDraftPrograms() {

  return (
    <>
        <section className='ContentPrograms'>
         
            {/* <ContentProgramsNoActivePrograms /> */}

            {/* <ContentProgramsDetails /> */}

            <ContentDraftProgramsList />

        </section>


        
    </>
  );
}

export default ContentDraftPrograms;
