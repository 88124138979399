import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showToast = (message, type, position) => {
    if (type === 'error') {
        toast.error(message, { position });
    } else if (type === 'success') {
        toast.success(message, { position });
    }
};

const NotificationToast = () => <ToastContainer />;

export { NotificationToast, showToast };
