import React from 'react';
import './RewardPoints.css';


function RewardPoints() {

  return (
    <>
      <section className='RewardPoints'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Reward Points</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='reward-points mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7uyah/31ko7ud140.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Earn reward points based on the number of kilometers traveled with purchases over a 30-day period. For every 5 kilometers traveled, customers receive 10 reward points. These points can be redeemed for discounts, special offers, or exclusive rewards within the loyalty program.</p>
              </div>
            </div>
          </div>

          <div className='reward-points p-3'>
            <div className="row">
                <div className="col-md mb-3">
                    <label className="form-label sub-title">Select Activity</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Activity">Select Activity</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </select>
                </div>
                <div className="col-md mb-3">
                    <label className="form-label sub-title">Select Duration</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                    <option value="Duration">Select Duration</option>
                        <option value="hours">Hours</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="yearly">Yearly</option>
                        <option value="half-yearly">Half Yearly</option>
                        <option value="quarterly">Quarterly</option>
                    </select>
                </div>
                <div className="col-md mb-3">
                    <label className="form-label sub-title">Target Number</label>
                    <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Target Number"/>
                </div>
                <div className="col-md mb-3">
                    <label className="form-label sub-title">Select Unit</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Unit">Select Unit</option>
                        <option value="kms">Kms</option>
                        <option value="miles">Miles</option>
                        <option value="litres">Litres</option>
                        <option value="minutes">Minutes</option>
                        <option value="hours">Hours</option>
                        <option value="lakhs">Lakhs</option>
                        <option value="crores">Crores</option>
                    </select>
                </div>
                <div className="col-md mb-3">
                    <label className="form-label sub-title">Assign Points</label>
                    <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Assign Points"/>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-6">
                <button type="button" id="startProcess" className="btn btn-success uplaod-file-btn">Save Reward Points</button>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                <hr className='mb-4' />
                <div className="table-responsive">
                <table className="table table-striped mb-0">
                    <thead className='table-dark '>
                        <tr>
                        <th scope="col">Activity</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Target to Achieve</th>
                        <th scope="col">Points</th>
                        <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>
                            Activity 1
                            </td>
                            <td>
                            hours
                            </td>
                            <td>
                            3444 kms
                            </td>
                            <td>
                            200
                            </td>
                            <td>
                                <button className="btn btn-Edit">Edit</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                        <tr>
                            <td>
                            Activity 2
                            </td>
                            <td>
                            hours
                            </td>
                            <td>
                            3444 kms
                            </td>
                            <td>
                            200
                            </td>
                            <td>
                                <button className="btn btn-Edit">Edit</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <select className="form-select input-field-ui" aria-label="Default select example">
                                    <option value="Activity">Select Activity</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                </select>
                            </td>
                            <td>
                                <select className="form-select input-field-ui" aria-label="Default select example">
                                    <option value="Duration">Select Duration</option>
                                    <option value="hours">Hours</option>
                                    <option value="day">Day</option>
                                    <option value="week">Week</option>
                                    <option value="month">Month</option>
                                    <option value="yearly">Yearly</option>
                                    <option value="half-yearly">Half Yearly</option>
                                    <option value="quarterly">Quarterly</option>
                                </select>
                            </td>
                            <td className='d-flex align-items-center'>
                            <input type="text" className="form-control input-field-ui" placeholder="Enter Target Number"/>
                                <select className="form-select input-field-ui" aria-label="Default select example">
                                    <option value="Unit">Select Unit</option>
                                    <option value="kms">Kms</option>
                                    <option value="miles">Miles</option>
                                    <option value="litres">Litres</option>
                                    <option value="minutes">Minutes</option>
                                    <option value="hours">Hours</option>
                                    <option value="lakhs">Lakhs</option>
                                    <option value="crores">Crores</option>
                                </select>
                            </td>
                            <td>
                                <input type="text" className="form-control input-field-ui" placeholder="Enter Assign Points"/>
                            </td>
                            <td>
                                <button className="btn btn-Edit">Save</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>



                    </tbody>
                    </table>
                    </div>
                    
                </div>
               
            </div>

            
          </div>
        </div>
      </section>
    </>
  );
}

export default RewardPoints;
