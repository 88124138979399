import React, { useEffect, useState } from "react";
import { frequency_configuration_template_creation } from "../../../services/met/Service";
import { LoaderLoader } from "../../utils/CommonLibrary";
const maxChars = 200;

function ContentSMSNew({ dataTemplates, dataLengthCount }) {
//console.log("templatesData conf",dataTemplates.configData[0].followData)
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [textareaValue, setTextareaValue] = useState("");
  //dataTemplates.data[0].template
  const [scheduleSend, setScheduleSend] = useState("immediate");
  const [followUpMessages, setFollowUpMessages] = useState([
    { id: 1,followupId:"", message: "", frequency: "", fschedule: "" },
  ]);
 
  const[passId,setPassId] =useState("")

  const fetchDataPassValue=()=>{
    if(dataLengthCount > 0){
      setTextareaValue(dataTemplates.data[0].template);
      setScheduleSend(dataTemplates.data[0].duration)
      setFollowUpMessages(dataTemplates.configData[0].followData);
      console.log("dataTemplates.data[0].Id",dataTemplates.data[0].id);
      setPassId(dataTemplates.data[0].id);
    }
}
  useEffect(() => {
    fetchDataPassValue();
    
  }, [dataTemplates,dataLengthCount]);

  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [templateMessage, setTemplateMessage] = useState("");
  const [isScheduleSendButtonVisible, setIsScheduleSendButtonVisible] =
    useState(true);

  const handleScheduleSendChange = (value) => {
    setScheduleSend("immediate");
    setIsScheduleSendButtonVisible(false);
  };

  //   const handleTextChange = (event) => {
  //     setTextareaValue(event.target.value);
  //   };

  const handleTextChange = (event) => {
    if (event.target.value.length >= maxChars + 1) {
      setErrors({ textareaValue: `Maximum ${maxChars} characters allowed.` });
    } else {
      setErrors({ textareaValue: `` });
      setTextareaValue(event.target.value);
    }
  };

  const handleAddFollowUpMessage = () => {
    //const lastMessage = followUpMessages[followUpMessages.length - 1];
    // if (lastMessage.message.length < 10 || !lastMessage.frequency || !lastMessage.fschedule) {
    //   setErrors({ ...errors, [lastMessage.id]: "Please complete the current follow-up message before adding a new one." });
    //   return;
    // }
    // setErrors({});
    setFollowUpMessages([
      ...followUpMessages,
      {
        id: followUpMessages.length + 1,
        followupId: 0,
        message: "",
        frequency: "",
        fschedule: "",
      },
    ]);
  };

  //   const handleFollowUpChange = (id, field, value) => {
  //     setFollowUpMessages(
  //       followUpMessages.map((message) =>
  //         message.id === id ? { ...message, [field]: value } : message
  //       )
  //     );
  //     if (field === 'message' && value.length >= maxChars) {
  //       const newErrors = { ...errors };
  //       delete newErrors[id];
  //       setErrors(newErrors);
  //     }
  //   };

  const handleFollowUpChange = (id, field, value, index = null) => {
    if (value.length >= maxChars + 1) {
      const newErrors = { ...errors };
      newErrors[`followUp${index}`] = `Maximum ${maxChars} characters allowed.`;

      setErrors(newErrors);
      return;
    } else {
      setErrors({ ...errors, [`followUp${index}`]: "" });
      setFollowUpMessages(
        followUpMessages.map((message,ind) =>
        ind === index ? { ...message, [field]: value } : message
        )
      );
      if (field === "message" && value.length >= maxChars) {
        const newErrors = { ...errors };
        delete newErrors[id];
      }
    }
  };

  const validate = () => {
    let newErrors = {};
    if (textareaValue.length === 0) {
      newErrors["textareaValue"] = `Message is required.`;
    }
    // if (!selectedFrequency) {
    //   newErrors["selectedFrequency"] = "Frequency is required.";
    // }
    // if (!scheduleSend) {
    //   newErrors["scheduleSend"] = "Schedule is required.";
    // }
    followUpMessages.forEach((followUp, index) => {
      if (followUp.message.length === 0) {
        newErrors[`followUp${index}`] = `Message is required.`;
      }
    //   if (!followUp.frequency) {
    //     newErrors[`frequency${index}`] = "Frequency is required.";
    //   }
      if (!followUp.fschedule) {
        newErrors[`fschedule${index}`] = "Schedule is required.";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (validate()) {
      try {
        setIsLoading(true);
        const formData = {
          id: passId,
          message: textareaValue,
        //   frequency: selectedFrequency,
          frequency: 0,
          scheduleSend: scheduleSend,
          followUpMessages: followUpMessages,
          labelValue: 1,
          labelDepth: 0,
          labelApoinment: 1,
          type: "sms",
        };
        console.log("formData",formData);
    
        let result = await frequency_configuration_template_creation(formData);
        if (result.data.code === 200) {
          setTemplateMessage(result.data.data.message || []);
          // setTextareaValue("");
          // setSelectedFrequency("");
          // setScheduleSend("");
          // setFollowUpMessages([
          //   { id: 1, message: "", frequency: "", fschedule: "" },
          // ]);
          setErrors({});
          setFormError("");
          //message disappear 
          setTimeout(() => {
            setTemplateMessage("");
          }, 4000);
        } else {
          setTemplateMessage([]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error submitting form data:", error);
      }
    } else {
      setFormError(
        `Please fill in all required fields and ensure all follow-up messages have at least ${maxChars} characters.`
      );
    }
  };
  const smsClose = ()=>{
    setTemplateMessage([]);
    setErrors({});
    setFormError("");
  }

  return (
    <>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-subtitle">SMS message</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={smsClose}
            ></button>
          </div>
          {templateMessage ? (
            <p style={{ color: "green", textAlign: "center" }}>
              {templateMessage}
            </p>
          ) : (
            ""
          )}
          <LoaderLoader isLoading={isLoading} />
          <div className="modal-body">
            <div className="row">
              {/* <form onSubmit={handleSave}>  */}
              <div className="col-md-12 mb-3 px-2">
                <div className="modal-bg">
                  <div className="row ">
                  <label
                        htmlFor="inputMessage2"
                        className="col-form-label pt-0"
                      >
                        Service Confirmation Message
                      </label>
                      <hr className="mt-2" />
                    {/* <div className="col-6 d-flex">
                      <i className="bi bi-bell me-2"></i>
                      <select
                        className={`form-select input-field-ui-select ${
                          errors["selectedFrequency"] ? "is-invalid1" : ""
                        }`}
                        id="SelectProgram"
                        aria-label="Default select example"
                        value={selectedFrequency}
                        onChange={(e) => setSelectedFrequency(e.target.value)}
                      >
                        <option value="" disabled>
                          Select Frequency
                        </option>
                        <option value="1">1 Time</option>
                        <option value="2">2 Times</option>
                        <option value="3">3 Times</option>
                        <option value="4">4 Times</option>
                        <option value="5">5 Times</option>
                      </select>
                      
                    </div> */}

                    <div className="col-12 d-flex align-items-center">
                      <i className="bi bi-clock me-2"></i>
                      <select
                        className={`form-select input-field-ui ${
                          errors["selectedFrequency"] ? "is-invalid1" : ""
                        }`}
                        id="SelectProgram"
                        aria-label="Default select example"
                        value={scheduleSend}
                        onChange={(e) =>
                          handleScheduleSendChange(e.target.value)
                        }
                      >
                        {/* <option value="">Select Schedule Time</option> */}
                        <option value="immediate" selected>immediate</option>
                        {/* <option value="after 15 mins">after 15 mins</option>
                        <option value="after 30 mins">after 30 mins</option>
                        <option value="after 1 hrs">after 1 hrs</option> */}
                      </select>
                      
                    </div>


                    {/* <div className="col-6">
                    {errors["selectedFrequency"] && (
                        <div className="text-danger">
                          {errors["selectedFrequency"]}
                        </div>
                      )}
                    </div> */}

                    <div className="col-12">
                    {errors["scheduleSend"] && (
                        <div className="text-danger">
                          {errors["scheduleSend"]}
                        </div>
                      )}
                    </div>

                    {/* <hr className="mt-2" /> */}
                    <div className="row mt-3"></div>
                    <div className="col-12">
                     
                      {/* <textarea className="form-control input-field-ui mb-1" value={template.template} id="inputMessage2" rows="3"></textarea> */}
                      <textarea
                        className="form-control input-field-ui"
                        id="exampleFormControlTextarea1"
                        placeholder="Please write the text message you want the member to receive when the Care Navigator consultation is booked."
                        rows="3"
                        value={textareaValue}
                        onChange={handleTextChange}
                      ></textarea>
                      {errors["textareaValue"] && (
                        <div className="text-danger">
                          {errors["textareaValue"]}
                        </div>
                      )}
                      <h5 className="fw-lighter" style={{ fontSize: "16px" }}>
                        {maxChars - textareaValue.length} characters remaining
                      </h5>
                    </div>
                  </div>
                </div>
              </div>


              {followUpMessages.map((followUp, index) => (
                      <>
              <div className="col-md-12 mb-3 px-2">
                <div className="modal-bg">
                  <div className="row ">
                  <label
                                htmlFor="inputMessage2"
                                className="col-form-label pt-0">
                                Follow up message - {index+1}
                              </label>
                              <hr className="mt-2" />
                        {/* <div className="col-6 d-flex" key={followUp.id}>
                          <i className="bi bi-bell me-2"></i>
                          <select
                            className={`form-select input-field-ui-select ${
                              errors[`frequency${index}`] ? "is-invalid1" : ""
                            }`}
                            id="SelectProgram"
                            aria-label="Default select example"
                            value={followUp.frequency}
                            onChange={(e) =>
                              handleFollowUpChange(
                                followUp.id,
                                "frequency",
                                e.target.value
                              )
                            }
                          >
                            <option value="" disabled>
                              Select Frequency
                            </option>
                            <option value="1">1 Time</option>
                            <option value="2">2 Times</option>
                            <option value="3">3 Times</option>
                            <option value="4">4 Times</option>
                            <option value="5">5 Times</option>
                          </select>
                          
                        </div> */}
                        <div className="col-12 d-flex align-items-center">
                          <i className="bi bi-clock me-2"></i>
                          <select
                            className={`form-select input-field-ui ${
                              errors[`frequency${index}`] ? "is-invalid1" : ""
                            }`}
                            id="SelectProgram"
                            aria-label="Default select example"
                            value={followUp.fschedule}
                            onChange={(e) =>
                              handleFollowUpChange(
                                followUp.id,
                                "fschedule",
                                e.target.value,
                                index
                              )
                            }
                          >
                            <option value="">Select Schedule Time</option>
                            <option value="after 24 hrs">
                            After 24 Hours
                            </option>
                            <option value="after 48 hrs">
                            After 48 Hours
                            </option>
                          </select>
                         
                        </div>


                        {/* <div className="col-6">
                        {errors[`frequency${index}`] && (
                            <div className="text-danger">
                              {errors[`frequency${index}`]}
                            </div>
                          )}
                    </div> */}

                    <div className="col-12">
                    {errors[`fschedule${index}`] && (
                            <div className="text-danger">
                              {errors[`fschedule${index}`]}
                            </div>
                          )}
                    </div>
                        {/* <hr className="mt-2" /> */}

                        
                        <div className="col-12 mt-2">
                          <label
                            htmlFor="inputMessage2"
                            className="col-form-label pt-0"
                          >
                            {/* Service Confirmation Message - {followUp.id} */}
                          </label>
                          <textarea
                            className={`form-control input-field-ui ${
                              errors[`followUp${index}`] ? "is-invalid1" : ""
                            }`}
                            id={`followUpTextarea${followUp.id}`}
                            placeholder="Please write the reminder text message you want the member to receive for the Care Navigator consultation."
                            value={followUp.message}
                            onChange={(e) =>
                              handleFollowUpChange(
                                followUp.id,
                                "message",
                                e.target.value,
                                index
                              )
                            }
                            rows="3"
                          ></textarea>
                          {errors[`followUp${index}`] && (
                            <div className="text-danger">
                              {errors[`followUp${index}`]}
                            </div>
                          )}
                          <h5
                            className="fw-lighter"
                            style={{ fontSize: "16px" }}
                          >
                            {maxChars - followUp.message.length} characters
                            remaining
                          </h5>
                        </div>
                      
                  </div>
                </div>
              </div>

              </>
                    ))}

              <div className="col-md-12 my-2">
                <div className="col-md-12 text-center">
                  <p onClick={handleAddFollowUpMessage}
                  type="button"
                  className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  >
                    <i className="bi bi-plus me-2"></i> Add More Follow Up Message
                  </p>
                </div>
              </div>
              <div className="col-md-12 my-2 text-center">
                <button
                  type="button"
                  className="btn btn-primary program-save-btn me-2"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary clear-btn"
                  data-bs-dismiss="modal"
                  onClick={smsClose}
                >
                  Cancel
                </button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentSMSNew;
