import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { fetch_agent_enroll_later_approve_list, fetch_all_members_reenroll, update_members_reenroll } from '../../../services/met/Service';
import DatePicker from 'react-datepicker'; 
import { formatDate } from '../utils/CommonLibrary';
import { format, set } from 'date-fns';



function NewRequestReenroll({data, fetchAgentMemberEnrollList}) {
    // State variables
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]); 
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [requestData, setRequestData] = useState({ 
        id: "", 
        memberId: "", 
        type: ""
    }); 
    const [agentEnrollLaterId, setAgentEnrollLaterId] = useState("");
    const [selectedCareNavigator, setSelectedCareNavigator] = useState("");
    const [modalType, setModalType] = useState('pending'); 
    const [remarks, setRemarks] = useState(""); 
    const [requestError, setRequestError] = useState(""); 

    const requestApprovalModalCloseBtn = useRef(); 
    const requestRejectModalCloseBtn = useRef();

    useEffect(() => { 
        setFilteredData(data); 
    }, [data]); 

    // filtering logic 
    useEffect(() => { 
         let filtered = data.filter((item) => { 
            const matchesSearch = item.memberName.toLowerCase().includes(searchQuery.toLowerCase());
            const matchMembershipNo = item.membershipNo.toLowerCase().includes(searchQuery.toLowerCase());
            
            return matchesSearch ||  matchMembershipNo;
         });    

         setFilteredData(filtered);
    }, [searchQuery]); 
   
    const openApproveSlotPopup = (id, memberId, requestType) => {
        setRequestData({ id: id, memberId: memberId, type: requestType });
        setModalType("approved");  
    }

    const openRejectSlotPopup = (id,memberId, requestType) => {
        setRequestData({ id: id, memberId: memberId, type: requestType });
        setModalType('reject');  
    }
 
    const closeModal = () => {
        setAgentEnrollLaterId('');
        setModalType(null);
    };

    const columns = [
        {
            name: 'Request Date',
            selector: row => row.reEnrollRequestedDate,
            sortable: true,
        },
        {
            name: 'Requested By',
            selector: row => row.reEnrollRequestBy,
            sortable: true,
        },
        {
            name: 'Request Notes',
            selector: row => 
            <span>
                {row.reEnrollRequestNote.length   < 100 ? row.reEnrollRequestNote: row.reEnrollRequestNote.slice(0,100)}
                { row.reEnrollRequestNote?.length > 100 && <a>  Read More</a>}
            </span>,                
            sortable: true,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership No',
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Marked Status',
            selector: row => row.previousEnrollmentStatus,
            sortable: true,
        }, 
        {
            name: 'Marked Date',
            selector: row => row.previousAgentActionTime,
            sortable: true,
        },
        {
            name: 'Marked By',
            selector: row => row.previousAgentName,
            sortable: true,
        },        
        {
            name: 'Action',
            cell: row => (
                <div>
                    <a href="#" className="btn-approve" data-bs-toggle="modal" data-bs-target="#slotBookModal"  onClick={() => openApproveSlotPopup(row.logId, row.membershipNo, "approved")}>Accept</a> / 
                    <a href="#" className="btn-reject" data-bs-toggle="modal" data-bs-target="#slotDeleteModal" onClick={() => openRejectSlotPopup(row.logId, row.membershipNo, "rejected")}>Reject</a>
                </div>      
            ),
        },
    ];
    

    const acceptReEnrollRequest = async () => {      
        const {id, memberId, type} = requestData;   

        if(!remarks) {
            setRequestError("Please provide remarks");
            return;
        }

        try{ 
            let response = await update_members_reenroll({id, memberId, type, remarks});
            if(response.data.code === 200) {
                requestApprovalModalCloseBtn.current?.click();
                fetchAgentMemberEnrollList();
            }else {  
                setRequestError(response.data.message);
            }
        }catch(error) { 
            setRequestError("Something went wrong, please try again");
        }
    };


    const rejectReEnrollRequest = async () => {      
        const {id, memberId, type} = requestData; 

        try{ 
            let response = await update_members_reenroll({id, memberId, type});
            if(response.data.code === 200) {
                requestRejectModalCloseBtn.current?.click(); 
                fetchAgentMemberEnrollList();
            }else {     
                setRequestError(response.data.data.message);
            }
        }catch(error) { 
            setRequestError("Something went wrong, please try again");
        }
    };

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    // const newFilteredData = data.filter(user => {
    //     const matchesSearchQuery =
    //         user.memberName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //         user.agentName.toLowerCase().includes(searchQuery.toLowerCase());
    //     const matchesCondition = selectedCondition === 'All' || user.conditionName === selectedCondition;
    //     const matchesProgram = selectedProgram === 'All' || user.programName === selectedProgram;
    //     return matchesSearchQuery && matchesProgram && matchesCondition;
    // });


    const handleExport = () => {
        const modifiedData = filteredData.map(item => ({
            'Request Date': item.reEnrollRequestedDate,
            'Requested By': item.reEnrollRequestBy,
            'Request Notes': item.reEnrollRequestNote,
            'Member ID': item.membershipNo,
            'Main Membership No': item.mainMembershipNo,
            'Member Name': item.memberName,
            'Marked Status': item.previousEnrollmentStatus,
            'Marked Date': item.previousAgentActionTime,
            'Marked By': item.previousAgentName,
        }));

        modifiedData.forEach(v => {
            delete v.approvedBy;
            delete v.approvedDate;
            delete v.logId;
            delete v.mobile;
            delete v.requestStatus;
            delete v.userId;
        });

        exportTableData("current-reenroll-requests", modifiedData); 
    };

    const handleClear = () => { 
        setSearchQuery(""); 
    }

    return (
        <>
            <LoaderLoader isLoading={isLoading} />
            <div className='container-fluid'>
                <div className='row mb-3 d-flex'>
                    <div className='col-md-6 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Member Name or Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-end'>
                        <div className='col-md-auto px-1'>
                            <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                        </div>
                        <div className='col-md-auto px-1'>
                            <button onClick={handleExport} className="btn btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                        </div>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
            <div className="modal fade" id="slotBookModal" tabindex="-1" aria-labelledby="slotBookModalLabel" data-bs-backdrop="static" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h1 className="modal-title fs-5 text-center" id="slotBookModalLabel" >Approve Request</h1>
                            <button ref={requestApprovalModalCloseBtn} type="button" className="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close"
                                onClick={e =>{
                                    setRemarks(""); 
                                    setRequestError("");
                                }}
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className=''>
                                <div>
                                    <p style={{color: 'red'}}>{requestError.length > 0 && requestError}</p>
                                </div>
                                <div className="col mb-2 pe-1">
                                    <label className="col-form-label">Remarks <span style={{color: 'red'}}>*</span></label>
                                    <textarea className='form-control control-input-ui'
                                        placeholder='Remark'  
                                        value={remarks} 
                                        onChange={e =>{
                                            setRemarks(e.target.value); 
                                            setRequestError(""); 
                                        }}
                                    >
                                    </textarea>
                                </div>
                                <div className="col-auto mb-2 mt-2 ps-0 text-center">
                                    <label className="col-form-label">&nbsp;</label>
                                    <button id="searchBtn" className="btn btn-primary program-save-btn mt-3" onClick={acceptReEnrollRequest} style={{ width: "140px" }}>Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="slotDeleteModal" tabindex="-1" aria-labelledby="slotDeleteModalLabel" data-bs-backdrop="static" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h1 className="modal-title fs-5 text-center" id="slotBookModalLabel" >Reject Request</h1>
                            <button ref={requestRejectModalCloseBtn} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={e =>{
                                setRemarks("");
                                setRequestError("");
                            }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className=''>
                                <div className="col mb-2 pe-1">
                                    <label className="col-form-label">Remarks</label>
                                    <textarea className='form-control control-input-ui'
                                    placeholder='Remark'  
                                    value={remarks} 
                                    onChange={e => {
                                        setRemarks(e.target.value); 
                                        setRequestError("");
                                    }}
                                    >
                                    </textarea>
                                </div>
                                <div className="col-auto mb-2 mt-2 ps-0 text-center">
                                    <label className="col-form-label">&nbsp;</label>
                                    <button id="searchBtn" className="btn btn-primary program-save-btn mt-3" onClick={rejectReEnrollRequest} style={{ width: "140px" }}>Reject</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="notesDisplay" tabindex="-1" aria-labelledby="notesDisplay" data-bs-backdrop="static" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h1 className="modal-title fs-5 text-center" id="slotBookModalLabel" >Reject Request</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className=''>
                                <div className="col mb-2 pe-1">
                                    <label className="col-form-label">Remarks</label>
                                    <textarea className='form-control control-input-ui'
                                    placeholder='Remark'  
                                    value={remarks} 
                                    onChange={e => {
                                        setRemarks(e.target.value); 
                                        setRequestError("");
                                    }}
                                    >
                                    </textarea>
                                </div>
                                <div className="col-auto mb-2 mt-2 ps-0 text-center">
                                    <label className="col-form-label">&nbsp;</label>
                                    <button id="searchBtn" className="btn btn-primary program-save-btn mt-3" onClick={rejectReEnrollRequest} style={{ width: "140px" }}>Reject</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default NewRequestReenroll;
