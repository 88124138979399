import React, { useState } from 'react';
import './ConfigurationPage.css';
import Sidenavmenubar from '../../componenents/Sidenavmenubar';
import Navbar from '../../componenents/Navbar';
import ContentConfiguration from '../../componenents/ContentConfiguration';


function ConfigurationPage() {

  const [title, setTitle] = useState('Configuration');

  return (
    <>
       <ContentConfiguration/>
        
    </>
  );
}

export default ConfigurationPage;
