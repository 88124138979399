import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import highcharts3d from 'highcharts/highcharts-3d';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const MembesAccrossPathwayMonthsBar = (props) => {
  // Destructure props for readability
  const { dataMonth } = props;
  const { columnName: careNavigators, dataValue: membersManaged, color: colorCode, displayName : axisName } = dataMonth;

  // yAxis configuration
  const yAxisJson = {
    title: {
      text: "   "
    }
  };

  // Tooltip configuration
  const tooltipJson = {
    shared: true,
    valueSuffix: ' Members'
  };

  // Series data
  const columnData = [{
    name: axisName,
    data: membersManaged,
    color: colorCode
  }];

  // Highcharts configuration
  const optionsCN1 = {
    chart: {
      type: "column",
      backgroundColor: 'transparent',
      height: 260,
      options3d: {
          enabled: true,
          alpha: 0,
          beta: 15,
          depth: 50,
          viewDistance: 0
      },
      marginTop: [34]
  },
    title: "    ",
    subtitle: null,
    xAxis: {
      categories: careNavigators,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 20, // Set border radius for columns
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: columnData,
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: true
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsCN1}
      />
    </div>
  );
};

export default MembesAccrossPathwayMonthsBar;
