import React from 'react';
import ObservationsBar from './componenents/DashboardGraphsSupportTeam/ObservationsBar';
import ObservationsPie from './componenents/DashboardGraphsSupportTeam/ObservationsPie';
import OpenObservationsBar from './componenents/DashboardGraphsSupportTeam/OpenObservationsBar';

function SupportTeamDashboard() {


  return (
    <>
      <section className='ReportsPrograms'>
            <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md">
                        <h1 className="main-title">Support Team Dashboard
                        </h1>
                    </div>
                </div>

                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/wul4w/gh7ss4yhho.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-3">Provides admins with an overview of program and condition-specific data, including key performance indicators (KPIs) on member engagement and service utilization.</p>
                        </div>
                    </div>
                </div>


                <div className="row">

                       <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Number of Observations
                                    {/* <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger> */}
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalCallMadeCount}</span> */}
                                </h1>

                                <ObservationsBar/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Observations by Status
                                    {/* <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger> */}
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalCallMadeCount}</span> */}
                                </h1>

                                <ObservationsPie/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Observations by Status
                                    {/* <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger> */}
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalCallMadeCount}</span> */}
                                </h1>

                                <OpenObservationsBar/>
                            </div>
                        </div>

                        <div className="col-md-12 mb-3">
                            <div className="dashboard-graph-box mb-3">
                               <div className='table-responsive'>
                               <table class="table table-striped mb-0">
                               <thead>
                                    <tr>
                                        <th>Observation ID</th>
                                        <th>Module</th>
                                        <th>Description</th>
                                        <th>Submission Date</th>
                                        <th>Approval Date</th>
                                        <th>Resolved Date</th>
                                        <th>Reverification Status</th>
                                        <th>Final Closure Date</th>
                                        <th>Status</th>
                                        <th>Update Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>001</td>
                                        <td>Module 1</td>
                                        <td>Issue with login</td>
                                        <td>2024-08-01</td>
                                        <td>2024-08-03</td>
                                        <td>2024-08-05</td>
                                        <td>Pending</td>
                                        <td>--</td>
                                        <td>Completed</td>
                                        <td>
                                            <select>
                                                <option value="notStarted">Not Started</option>
                                                <option value="inProgress">In Progress</option>
                                                <option value="completed" selected>Completed</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                               </div>

                            </div>
                        </div>


                </div>
        </div>
      </section>
    </>
  );
}

export default SupportTeamDashboard;
