import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);

const MembersAcrossPathwaysStackedBar = () => {
  // Example data structure (replace with your actual data)
  const pathways = ['Pathway A', 'Pathway B', 'Pathway C'];
  const timePeriods = ['Jan', 'Feb', 'Mar', 'Apr', 'May']; // Example time periods
  const memberData = [
    [100, 120, 80, 90, 110], // Pathway A data over time periods
    [80, 90, 100, 110, 120], // Pathway B data over time periods
    [70, 85, 95, 105, 115]   // Pathway C data over time periods
  ];

  // Define colors for each pathway
  const colors = ['#e7766c', '#f0c148', '#74bd6d', '#5479bc'];

  // Prepare series data for Highcharts with colors
  const seriesData = pathways.map((pathwayIndex, index) => ({
    name: pathwayIndex,
    data: memberData[index],
    color: colors[index] // Assign color based on index
  }));

  const options3 = {
    chart: {
      type: 'bar',
      height: 220,
      spacing: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 15,
        depth: 50,
        viewDistance: 25 // Adjusted viewDistance for better 3D effect
      }
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    xAxis: {
      categories: timePeriods,
      gridLineWidth: 0
    },
    yAxis: {
      title: {
        text: null
      }
    },
    tooltip: {
      shared: true,
      valueSuffix: ' members'
    },
    plotOptions: {
      bar: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },
    series: seriesData,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: true
    },
    
    colors: colors // Set global colors for the chart (optional)
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options3}
      />
    </div>
  );
};

export default MembersAcrossPathwaysStackedBar;
