import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { updateVisibilityCare,updateCapacityCare } from '../../../services/medengage/Service';
import { NotificationToast, showToast } from './NotificationToast';
import { Commingsoon, CustomLoader } from '../../utils/CommonLibrary';
import { Modal } from "react-bootstrap";


const CapacityManagementTable = ({ data: initialData,reload }) => {


    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('Diabetes Mellitus');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [data, setData] = useState(initialData);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('success');
    const [showModal, setShowModal] = useState(false);
    const [capacityValue, setCapacityValue] = useState('');
    const [notes, setNotes] = useState('');
    const [updateId, setUpdateId] = useState(0);
    const [userName, setUserName] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleClose = () =>setShowModal(false);

    const handleToggle = async (id) => {
        try {
            setLoading(true);
            let newVisibilityNew;
            const updatedData = data.map(row => {
                if (row.id === id) {
                    const newVisibility = row.visibility === 'on' ? 'off' : 'on';
                    newVisibilityNew = newVisibility;
                    return { ...row, visibility: newVisibility };
                }
                return row;
            });
    
            setData(updatedData);

            
            updateVisibilityCare(id,newVisibilityNew)
            .then((response) => {   
                setLoading(false);
                setMessage(response.data.data.msgData.showMsg);
                setType(response.data.data.msgData.msgType);
                showToast(response.data.data.msgData.showMsg, response.data.data.msgData.msgType,'top-right');
            });
        } catch (error) {
            console.error('Error toggling visibility', error);
        }
    };

    const openModalPopup = async (capacity,id,careName) => {
        try {
            setShowModal(true);
            setCapacityValue(capacity);
            setUpdateId(id);
            setUserName(careName)
        } catch (error) {
            console.error('Error=', error);
        }
    };

    const updateCapacity = async () => {
        try {
            updateCapacityCare(capacityValue,updateId,notes)
            .then((response) => {   
                const { showMsg, msgType } = response.data.data.msgData;
                setMessage(showMsg);
                setType(msgType);
                showToast(showMsg, msgType, 'top-right');

                // Delay the reload call to ensure the toast message is displayed
                setTimeout(() => {
                    reload();
                }, 1000); // Adjust the delay time as needed

                setShowModal(false);
                setNotes('');
                setCapacityValue('');
                setUpdateId(0);
                
            });

        } catch (error) {
            console.error('Error=', error);
        }
    };

    const handleCapacityChange = (e) => {
        let filedName = e.target.name;

        if(filedName == 'firstName'){
            setCapacityValue(e.target.value);
        }else{
            setNotes(e.target.value);
        }
        
    };


    const columns = [
        {
            name: 'Name',
            selector: row => row.userName,
            sortable: true,
        },
      
        {
            name: 'Visibility',
            selector: row => row.visibility,
            sortable: true,
            cell: row => (
                <div className="toggle-container-CareNavigator">
                    <input
                        type="checkbox"
                        checked={row.visibility === 'on'}
                        onChange={() => handleToggle(row.id)}
                    />
                    <div className={`slider-CareNavigator round-CareNavigator ${row.visibility === 'on' ? 'checked' : ''}`}></div>
                </div>
            ),
        },
        {
            name: 'Total Members',
            selector: row => row.totalMembers,
            sortable: true, 
        },
        {
            name: 'Capacity',
            selector: row => row.capacity,
            sortable: true,
            cell: row => (
                <div>
                    <span>{row.capacity}</span>
                    <i className="bi bi-pen ms-2" style={{ cursor: 'pointer' }} onClick={(e) => openModalPopup(row.capacity,row.id,row.userName)}></i>
                </div>
            ),
        },
        {
            name: 'Condition Name',
            selector: row => row.carePrograms,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => row.capacity,
            sortable: true,
            cell: row => (
                <div>
                {row.showTransfer &&(
                     <Link to={`/member-transfer/${row.id}`} className='btn btn-primary back-btn mb-0'>
                        Transfer
                    </Link>
                 )}
                </div>
            ),
        },
    ];

    

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('Diabetes Mellitus');
        setSelectedStatus('All');
    };

    const filteredData = data.filter(user => {
        const matchesSearchQuery =
            user.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesProgram = selectedProgram === 'Diabetes Mellitus' || selectedProgram === 'All';
        const matchesStatus = selectedStatus === 'All' || user.status === selectedStatus;

        return matchesSearchQuery && matchesProgram && matchesStatus;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search By Name"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedProgram}
                            onChange={e => setSelectedProgram(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Condition--</option>
                            <option value="Diabetes Mellitus">Diabetes Mellitus</option>
                        </select>
                    </div>
                    {/* <div className='col-md-3 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                    </div> */}
                    
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
                
                {loading && <CustomLoader /> }
                <NotificationToast />
                <Commingsoon />
                </div> 
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />

            <Modal dialogClassName="modal-md modal-dialog-centered" show={showModal} onHide={handleClose} id="updateCapacity">
                <Modal.Header closeButton>
                    <Modal.Title 
                        style={{
                        width: "90%",
                        fontSize:"18px",
                        }}
                    >
                        Update Capacity for {userName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 mb-3 ">
                            <label htmlFor="emailId" className="form-label">Capacity:</label>
                            <div className="d-flex">
                                <input type="text" className="form-control input-field-ui" id="Name" name="firstName" value={capacityValue} onChange={(e) => handleCapacityChange(e)}/>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3 ">
                            <label htmlFor="emailId" className="form-label">Notes:</label>
                            <div className="d-flex">
                                <textarea className="form-control input-field-ui" id="notes"  placeholder="Write Notes" name="notes" onChange={(e) => handleCapacityChange(e)} value={notes} rows="4"></textarea>
                            </div>
                        </div>
                        <div  className="col-md-12">
                            <button type="submit" className="btn btn-primary btn-primary program-save-btn" onClick={updateCapacity}>Update</button> 
                        </div>
                    </div>
                </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CapacityManagementTable;
