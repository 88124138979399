import React from 'react';
import './ContentUserManagementDetails.css';
import ContentUserManagementDetails from '../ContentUserManagement/ContentUserManagementDetails';

function ContentUserManagement() {

  return (
    <>
        <section className='ContentUserManagement'>
            <ContentUserManagementDetails />
        </section>
    </>
  );
}

export default ContentUserManagement;
