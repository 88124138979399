import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const MemberPathwayAssignedStatusPie = () => {
  // Pie chart data for service completion rate
  const pieData = [
    {
      name: 'Completed',
      y: 20, // Example percentage, replace with actual data
      color: '#3FB8C7' 
    },
    {
      name: 'In-Progress',
      y: 150, 
      color: '#227C9E' 
    },
    {
      name: 'De-Enrolled',
      y: 30, 
      color: '#065376' 
    }
  ];

  const pieOptionsCN2 = {
    chart: {
      type: 'pie',
      height: 180,
      spacing: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 70,
        beta: 0
      },
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif",
        marginBottom: '10px'
      }
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>'
      // pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 30,
        size: 190,
        dataLabels: {
          enabled: true, // Enable data labels
          format: '{point.percentage:.1f}%',
          distance: 5, // Adjust label position
          alignTo: 'toPlotEdges' // Center the label inside the pie slice
        },
        showInLegend: true
      }
    },
    series: [{
      name: 'Service Status',
      data: pieData
    }],
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={pieOptionsCN2}
      />
    </div>
  );
};

export default MemberPathwayAssignedStatusPie;
