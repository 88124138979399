import React, { useState } from 'react';
import ContentMemberTransferPage from './componenents/MemberTransferPage/ContentMemberTransferPage';


function MemberTransferPage() {

  const [title, setTitle] = useState('Member Transfer');

  return (
    <>
        <ContentMemberTransferPage/>
    </>
  );
}

export default MemberTransferPage;
