import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import exporting from 'highcharts/modules/exporting';

// Initialize necessary Highcharts modules
highchartsMore(Highcharts);
solidGauge(Highcharts);
exporting(Highcharts);

const AppointmentAdherenceRateGauge = () => {
  // Example data based on the formula provided
  const numberOfCompletedAppointments = 70; // Replace with actual data
  const totalScheduledAppointments = 100; // Replace with actual data

  // Calculate adherence rate
  const adherenceRate = (numberOfCompletedAppointments / totalScheduledAppointments) * 100;

  // Gauge chart options
  const gaugeOptions = {
    chart: {
      type: 'solidgauge',
      height: 220,
      spacing: [0, 0, -120, 0],
    
    },
    title: {
      text: null,
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif",
        marginBottom: '10px'
      }
    },
    tooltip: {
      enabled: false
    },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      stops: [
        [0.1, '#FF0000'], // Red
        [0.5, '#DDDF0D'], // Yellow
        [0.9, '#55BF3B']  // Green
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70,
        text: null
      },
      labels: {
        y: 16
      }
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
          format: '<div style="text-align:center"><span style="font-size:25px">{y}%</span></div>'
        }
      }
    },
    series: [{
      name: 'Adherence Rate',
      data: [adherenceRate],
      tooltip: {
        valueSuffix: '%'
      }
    }],
    credits: {
      enabled: false
    },
    exporting: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={gaugeOptions}
      />
    </div>
  );
};

export default AppointmentAdherenceRateGauge;
