import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);

const MembesAccrossPathwayMonthsBar = (props) => {

  const careNavigators= props.dataMonth.columnName;
  const membersManaged= props.dataMonth.dataValue;
  // const colorCode = props.dataMonth.color;

  const yAxisJson = {
    title: {
      text: " "
    }
  };

  const tooltipJson = {
    shared: true,
    valueSuffix: ' Members'
  };

  const columnData = [{
    name: 'Members',
    data: membersManaged,
    color: '#1279be'
  }];

  const optionsCN2 = {
    chart: {
        type: "column",
        height: 260,
        backgroundColor: 'transparent',
        options3d: {
            enabled: true,
            alpha: 0,
            beta: 15,
            depth: 50,
            viewDistance: 0
        },
        marginTop: [34]
    },
    title: "    ",
    subtitle: null,
    xAxis: {
      categories: careNavigators,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 20, // Set border radius for columns
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: columnData,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: true
    },
    legend: {
      enabled: false
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsCN2}
      />
    </div>
  );
};

export default MembesAccrossPathwayMonthsBar;
