import React, { useState, useRef, useEffect } from 'react';
import { useUserTabs } from '../components/common/UserTabsContext';
import axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TopBar from '../components/topbar/TopBar';
import UsersList from '../components/userslist/UsersList';
import Pie from '../components/graphs/Pie';
import Column from '../components/graphs/Column';
import WorkingPlansAndBreaks from '../components/workingplansandbreaks/WorkingPlansAndBreaks';
import TodaysAppointmentProgressBar from './TodaysAppointmentProgressBar';
import ContentNewReports from './ContentNewReports';
import ContentOtherTasks from './ContentOtherTasks';
import AppointmentSchedule from './AppointmentSchedule';
import { Link } from 'react-router-dom'; import { fetchOtherTasksList } from "../components/services/Service";
import './AppointmentsReport.css';

function ContentOtherTask({ onUserClick }) {
    const [taskListResult, setTaskListResult] = useState([]);
    useEffect(() => {
        getTaskList();
    }, [])

    const getTaskList = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchOtherTasksList(ct)
            .then((response) => {
                console.log("tasks", response.data)
                if (response.data.code === 200) {
                    setTaskListResult(response.data.data.tasks);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    {/* <div className="no-data-box">
                        <h5>No tasks assigned yet.</h5>
                        <h6 className='card-title1 '>Your tasks will be displayed here.</h6>
                    </div> */}
                    <div className='col-md-12 px-0'>
                        <h2>Other Tasks</h2>
                        <h3>Here are some tasks for you to complete today.</h3>
                    </div>
                    <div className='col-md-12 px-0 mt-2' style={{ maxHeight: "392px", overflowX: "hidden", overflowY: "scroll" }}>
                        {
                            taskListResult.map((item, index) => {
                                return <>
                                    <div key={index} className='row align-items-center mb-2'>
                                        <div className='col-auto'>
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/dhdjh/898otsm9c0.png" className="icon-appointments-report" alt="" />
                                        </div>
                                        <div className='col ps-0'>
                                            <Link to={'/members/' + encodeURIComponent(item.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: item.userId, name: item.memberName })}><h4>{item.desc}</h4></Link>
                                            {/*<p>{item.subDesc}</p>*/}
                                        </div>
                                    </div>
                                </>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContentOtherTask;
