import React, { useState,useEffect } from 'react';
import './ProgramsPage.css';
// import UpcomingSession from '../componenents/UpcomingSession';
// import Sidenavmenubar from '../../componenents/Sidenavmenubar';
// import Navbar from '../../componenents/Navbar';
import ContentPrograms from '../../componenents/ContentPrograms';



function ProgramsPage() {

  const [title, setTitle] = useState('Programs');
 
return (
    <>
        <ContentPrograms/>
    </>
  );
}

export default ProgramsPage;
