import React, { useState } from 'react';
import './ChallengeReport.css';
import { Link } from 'react-router-dom';

function ChallengeReport() {


  return (
    <>
      <section className='ChallengeReport'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Challenge Report</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='challenge-report mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/kjws6/w17anars5h.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo. Vivamus et interdum felis, ut vulputate tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo.</p>
              </div>
            </div>
          </div>

          <div className='challenge-report p-4'>
            
          <div className="row mb-4">
              <div className="col-md-7">
                <div className="row">
                    <div className="col-md">
                        <label className="form-label sub-title">Start Date</label>
                        <input type="date" className="form-control input-field-ui" id="" placeholder="Start Date"/>
                    </div>
                    <div className="col-md">
                        <label className="form-label sub-title">End Date</label>
                        <input type="date" className="form-control input-field-ui" id="" placeholder="End Date"/>
                    </div>
                </div>
              </div>
            </div>

            <div className="row">
                <div className="col-md-7">
                    <div className="row">
                        <div className="col-md mb-4">
                        <label className="form-label sub-title">Select Report</label>
                            <select className="form-select input-field-ui " id="" name="" aria-label="Default select example">
                            <option value="">Select Report</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            </select>
                        </div>
                        <div className="col-md mb-4">
                            <label className="form-label sub-title">Reporting Email</label>
                            <input type="email" className="form-control input-field-ui" id="" placeholder="Enter your Email Id"/>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-md-7">
                <button type="button" className="btn program-save-btn me-2">Generate</button> <button type="button" className="btn clear-btn">Cancel</button>
                </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default ChallengeReport;
