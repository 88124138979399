import React,{useState} from 'react';
import './ConfigurationSettingsAgentCalls.css';
import ConfigurationSettingsCareNavigatorCallsDetails from '../components/ConfigurationSettingsCareNavigatorCallsDetails';

function ConfigurationSettingsCareNavigatorCalls() {
    const [title, setTitle] = useState('Member Connect');

  return (
    <>
         <ConfigurationSettingsCareNavigatorCallsDetails/>   
    </>
  );
}

export default ConfigurationSettingsCareNavigatorCalls;
