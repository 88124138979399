import { color } from "highcharts";
import React, { useState, useEffect } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";

function TreeDropdown(props) {
  let data = props.data ? props.data : [];

  // Function to check if all children are selected
  const areAllChildrenSelected = (node) => {
    if (!node.children || node.children.length === 0) return false;
    return node.children.every((child) => child.checked);
  };

  // Function to extract labels of selected nodes, showing only parent if all children are selected
  const getSelectedLabels = (nodes) => {
    let labels = [];
    nodes.forEach((node) => {
      if (node.checked) {
        if (areAllChildrenSelected(node)) {
          labels.push(node.label);
        } else if (!node.children || node.children.length === 0 || !areAllChildrenSelected(node)) {
          labels.push(node.label);
        }
      }
      if (node.children && !areAllChildrenSelected(node)) {
        labels = labels.concat(getSelectedLabels(node.children));
      }
    });
    return labels;
  };

  // Initialize selected nodes from props data
  const initialSelectedLabels = getSelectedLabels(data);

  const [selectedNodes, setSelectedNodes] = useState(initialSelectedLabels);

  useEffect(() => {
    // Update the selected nodes when the data prop changes
    const updatedSelectedLabels = getSelectedLabels(data);
    setSelectedNodes(updatedSelectedLabels);
  }, [data]);

  const onChange = (currentNode, selectedNodes) => {
    let currentNodeLabel = '';

    if (currentNode.path >= 0) {
      currentNodeLabel = 'parent';
    } else {
      currentNodeLabel = 'child';
    }

    props.onChange(currentNodeLabel, currentNode);
    const updatedSelectedLabels = getSelectedLabels(data);
    setSelectedNodes(updatedSelectedLabels);
  };

  const onAction = (node, action) => {
    // Handle action if needed
  };

  const onNodeToggle = (currentNode) => {
    // Handle node toggle if needed
  };

  const placeholderText = selectedNodes.length > 0 ? selectedNodes.join(', ') : 'Select Program';

  return (
    <>
      <DropdownTreeSelect
        data={data}
        onChange={onChange}
        onAction={onAction}
        onNodeToggle={onNodeToggle}
        texts={{ placeholder: placeholderText }}
        className="mdl-demo treeDropdownList"
        keepTreeOnSearch={true}
        keepChildrenOnSearch={true}
        showPartiallySelected={true}
      />
    </>
  );
}

export default TreeDropdown;
