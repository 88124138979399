import React, { useState } from 'react';
import './MemberDeEnrollmentPage.css';
import ContentMemberDeEnrollment from './componenents/ContentMemberDeEnrollment';


function MemberDeEnrollmentPage() {

  return (
    <>
        <ContentMemberDeEnrollment/>
    </>
  );
}

export default MemberDeEnrollmentPage;
