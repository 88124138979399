import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ChooseAudience from './CreateCampaignComponents/ChooseAudience';
import CreateMessage from './CreateCampaignComponents/CreateMessage';
import SetNextSteps from './CreateCampaignComponents/SetNextSteps';
import ScheduleMessage from './CreateCampaignComponents/ScheduleMessage';
import SetMessageLive from './CreateCampaignComponents/SetMessageLive';

const CreateCampaign = () => {
  const [campaignName, setCampaignName] = useState('');
  const [activeStage, setActiveStage] = useState(0);
  const [mounted, setMounted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setMounted(true);
  }, []);

  const stages = [
    { title: 'Choose your audience', content: <ChooseAudience /> },
    { title: 'Create your message', content: <CreateMessage /> },
    // { title: 'Set next steps after customer replies to the message (Recommended) 💎', content: <SetNextSteps /> },
    { title: 'Schedule your message', content: <ScheduleMessage /> },
    { title: 'Set your message live', content: <SetMessageLive /> },
  ];

  const handleStageClick = (index) => {
    setActiveStage(index === activeStage ? -1 : index);
  };

  const handleDone = () => {
    if (activeStage < stages.length - 1) {
      setActiveStage(activeStage + 1);
    }
  };

  return (
    <div className={` tw-mb-8 tw-mx-auto tw-p-4 tw-max-w-[90%] tw-bg-sky-50 tw-transition-opacity tw-duration-500 ${mounted ? 'tw-opacity-100' : 'tw-opacity-0'}`}>
      <style jsx>{`
        @keyframes slideDown {
          from { transform: translateY(-20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        .animate-slide-down {
          animation: slideDown 0.5s ease-out forwards;
        }
        .animate-fade-in {
          animation: fadeIn 0.5s ease-out forwards;
        }
      `}</style>
      <div className="tw-flex tw-items-center tw-mb-6 animate-slide-down">
        <FaChevronLeft 
          onClick={() => navigate('/notification-dashboard')} 
          className="tw-mr-4 tw-cursor-pointer tw-text-gray-600 hover:tw-text-gray-800 tw-transition-colors tw-duration-300" 
        />
        <h1 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-text-gray-800">Create Campaign</h1>
      </div>
      <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-mb-8 animate-slide-down" style={{animationDelay: '0.2s'}}>
        <input
          type="text"
          placeholder="Enter campaign name"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          className="tw-flex-grow tw-mb-2 sm:tw-mb-0 sm:tw-mr-4 tw-p-3 tw-border tw-border-sky-300 tw-rounded-lg tw-bg-white tw-shadow-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-sky-500 tw-transition-all tw-duration-300"
        />
        <span className="tw-bg-sky-200 tw-text-gray-800 tw-px-4 tw-py-2 tw-rounded-full tw-font-semibold tw-cursor-pointer">Draft</span>
      </div>
      {stages.map((stage, index) => (
        <div 
          key={index}
          className="tw-mb-6 animate-fade-in"
          style={{animationDelay: `${0.1 * (index + 1)}s`}}
        >
          <div
            className={`tw-flex tw-items-center tw-justify-between tw-p-4 tw-rounded-t-lg tw-cursor-pointer tw-transition-all tw-duration-300 tw-relative ${
              activeStage === index ? 'tw-bg-sky-200 tw-text-gray-800' : 'tw-bg-white tw-border tw-border-sky-300 hover:tw-bg-sky-100'
            }`}
            onClick={() => handleStageClick(index)}
          >
            <div className="tw-flex tw-items-center">
              <span className="tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-bg-sky-600 tw-text-gray-800 tw-rounded-full tw-mr-4 tw-font-bold">
                {index + 1}
              </span>
              <span className="tw-text-lg">{stage.title}</span>
            </div>
            <div className="tw-flex tw-items-center">
              {activeStage === index && (
                <button
                  className="tw-bg-white tw-text-gray-600 tw-px-6 tw-py-2 tw-rounded-full tw-mr-4 tw-font-semibold tw-shadow-md hover:tw-bg-sky-100 tw-transition-all tw-duration-300"
                  onClick={handleDone}
                >
                  Done
                </button>
              )}
              <FaChevronDown className={`tw-transition-transform tw-duration-300 ${activeStage === index ? 'tw-rotate-180' : ''}`} />
            </div>
          </div>
          {activeStage === index && (
            <div className="tw-border tw-border-t-0 tw-border-sky-300 tw-rounded-b-lg tw-p-4 tw-bg-white tw-shadow-md animate-fade-in tw-relative">
              {stage.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CreateCampaign;