import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CustomLoader, exportTableData, formatDate } from '../../../utils/CommonLibrary';
import { fetchMembersProgress, getCareNavigators } from '../../../../services/medengage/Service';


function MemberProgressTable({ startDate, endDate }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCare, setSelectedCare] = useState('All');
    const [memberData, setMemberData] = useState([]);
    const [careList, setCareList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        fetchMemberProgressData();
    }, [startDate, endDate]);

    useEffect(() => {
        fetchCareNavigatorData();
    }, []);

    const fetchMemberProgressData = async () => {
        try {
            fetchMembersProgress(formatDate(startDate), formatDate(endDate))
                .then((response) => {
                    setMemberData(response.data.data.users);
                    setIsLoading(false);
                });
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    };

    const fetchCareNavigatorData = async () => {
        try {
            getCareNavigators()
                .then((response) => {
                    setCareList(response.data.data.users);
                });
        } catch (error) {
            console.error(error);
        }
        finally {
            // setIsLoading(false);
        }
    };

    const renderMETP1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Unique identifier for the member.
        </Tooltip>
    );

    const renderMETP2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the member.
        </Tooltip>
    );

    const renderMETP3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of Care Navigator.
        </Tooltip>
    );

    const renderMETP70Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of Supervisor
        </Tooltip>
    );

    const renderMETP4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Enrollment Date.
        </Tooltip>
    );

    const renderMETP5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Program Name.
        </Tooltip>
    );

    const renderMETP6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Condition Name.
        </Tooltip>
    );

    const renderMETP7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Pathway Start Date.
        </Tooltip>
    );

    const renderMETP8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Initial Pathway Name.
        </Tooltip>
    );

    const renderMETP9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Current Pathway Name.
        </Tooltip>
    );

    const renderMETP10Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Pathway Completion Date.
        </Tooltip>
    );

    const renderMETP11Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Current Pathway Month.
        </Tooltip>
    );

    const renderMETP12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Appointments Count with Care Navigator.
        </Tooltip>
    );

    const renderMETP13Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Task Count.
        </Tooltip>
    );

    const renderMETP14Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Service Count.
        </Tooltip>
    );

    const renderMETP15Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Lab Referrals Count.
        </Tooltip>
    );

    const renderMETP19Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Completed Lab Referrals Count.
        </Tooltip>
    );

    const renderMETP16Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Home Care Referrals Count.
        </Tooltip>
    );

    const renderMETP20Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Completed Home Care Referrals Count.
        </Tooltip>
    );

    const renderMETP17Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Medications Referrals Count.
        </Tooltip>
    );

    const renderMETP21Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Completed Medications Referrals Count.
        </Tooltip>
    );

    const renderMETP18Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's TeleHealth Referrals Count.
        </Tooltip>
    );

    const renderMETP22Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Completed TeleHealth Referrals Count.
        </Tooltip>
    );

    const renderMETP23Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Main Membership No.
        </Tooltip>
    );
    const renderMETP24Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member Type
        </Tooltip>
    );

    const renderMETP25Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Age
        </Tooltip>
    );

    const renderMETP26Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            City
        </Tooltip>
    );
    const renderMETP27Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Policy End Date
        </Tooltip>
    );
    const renderMETP28Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members Pathway Status
        </Tooltip>
    );
    const renderMETP29Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            HBA1C level Initial
        </Tooltip>
    );
    const renderMETP30Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            HBA1C level Current
        </Tooltip>
    );
    const renderMETP31Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            HRA
        </Tooltip>
    );
    const renderMETP32Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            BMI
        </Tooltip>
    );
    const renderMETP33Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member Email
        </Tooltip>
    );
    const renderMETP34Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member Mobile No
        </Tooltip>
    );
    const renderMETP35Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Gender
        </Tooltip>
    );
    const renderMETP36Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Date of Birth
        </Tooltip>
    );
    const renderMETP37Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member Network
        </Tooltip>
    );
    const renderMETP38Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Contract No
        </Tooltip>
    );
    const renderMETP39Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Contract Name
        </Tooltip>
    );
    const renderMETP40Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Policy Start Date
        </Tooltip>
    );
    const renderMETP41Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Policy Status
        </Tooltip>
    );
    const renderMETP42Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Policy Total Members
        </Tooltip>
    );
    const renderMETP43Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Policy Member Type
        </Tooltip>
    );
    const renderMETP44Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Head of Family
        </Tooltip>
    );
    const renderMETP45Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Height (Cms)
        </Tooltip>
    );
    const renderMETP46Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Weight (Lbs)
        </Tooltip>
    );
    const renderMETP47Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Health Risk Category
        </Tooltip>
    );
    const renderMETP48Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Health Risk Segment
        </Tooltip>
    );
    const renderMETP49Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Personal Data
        </Tooltip>
    );
    const renderMETP50Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Surgical History
        </Tooltip>
    );
    const renderMETP51Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Medical History
        </Tooltip>
    );
    const renderMETP52Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Common Chronic Profile
        </Tooltip>
    );
    const renderMETP53Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Associated Chronic/Advanced Conditions
        </Tooltip>
    );
    const renderMETP54Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Top Providers Visits
        </Tooltip>
    );
    const renderMETP55Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Top Specialties Visits
        </Tooltip>
    );
    const renderMETP56Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Top Doctors Visits
        </Tooltip>
    );
    const renderMETP57Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Top Conditions By ICD
        </Tooltip>
    );
    const renderMETP58Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            OP Cost Per Claims
        </Tooltip>
    );
    const renderMETP59Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            IP Cost Per Claims
        </Tooltip>
    );
    const renderMETP60Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total YTD
        </Tooltip>
    );
    const renderMETP61Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Accommodation
        </Tooltip>
    );
    const renderMETP62Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Consultation
        </Tooltip>
    );
    const renderMETP63Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Medication
        </Tooltip>
    );
    const renderMETP64Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Laboratories
        </Tooltip>
    );
    const renderMETP65Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Advanced Radiology
        </Tooltip>
    );
    const renderMETP66Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Surgical Procedures
        </Tooltip>
    );
    const renderMETP67Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Advanced Services
        </Tooltip>
    );
    const renderMETP68Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Dental
        </Tooltip>
    );
    const renderMETP69Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Other Services
        </Tooltip>
    );


    const columns = [
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Member ID</span>
                </OverlayTrigger>
            ),
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP2Tooltip}>
                    <span>Member Name</span>
                </OverlayTrigger>
            ),
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP23Tooltip}>
                    <span>Main Membership No.</span>
                </OverlayTrigger>
            ),
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP24Tooltip}>
                    <span>Member Type</span>
                </OverlayTrigger>
            ),
            selector: row => row.policyMemberType,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP25Tooltip}>
                    <span>Age</span>
                </OverlayTrigger>
            ),
            selector: row => row.age,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP26Tooltip}>
                    <span>City</span>
                </OverlayTrigger>
            ),
            selector: row => row.city,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP40Tooltip}>
                    <span>Policy Start Date</span>
                </OverlayTrigger>
            ),
            selector: row => row.policyStartDate,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP27Tooltip}>
                    <span>Policy End Date</span>
                </OverlayTrigger>
            ),
            selector: row => row.policyEndDate,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP41Tooltip}>
                    <span>Policy Status</span>
                </OverlayTrigger>
            ),
            selector: row => row.policyStatus,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Care Navigator</span>
                </OverlayTrigger>
            ),
            selector: row => row.careName,
            sortable: true,
            width: "100px",
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP70Tooltip}>
                    <span>Supervisor</span>
                </OverlayTrigger>
            ),
            selector: row => row.supervisorName,
            sortable: true,
            width: "100px",
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP4Tooltip}>
                    <span>Onboarded Date</span>
                </OverlayTrigger>
            ),
            selector: row => row.enrollmentTime,
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP5Tooltip}>
                    <span>Program</span>
                </OverlayTrigger>
            ),
            selector: row => row.programName,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                    <span>Condition</span>
                </OverlayTrigger>
            ),
            selector: row => row.conditionName,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP7Tooltip}>
                    <span>Pathway Start Date</span>
                </OverlayTrigger>
            ),
            selector: row => row.pathwayStartDate,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP8Tooltip}>
                    <span>Initial Pathway</span>
                </OverlayTrigger>
            ),
            selector: row => row.initialPathway,
            sortable: true,
            width: "100px",
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP9Tooltip}>
                    <span>Current Pathway</span>
                </OverlayTrigger>
            ),
            selector: row => row.pathwayName,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP10Tooltip}>
                    <span>Pathway Completion Date</span>
                </OverlayTrigger>
            ),
            selector: row => row.pathwayEndDate,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP28Tooltip}>
                    <span>Members Pathway Status</span>
                </OverlayTrigger>
            ),
            selector: row => row.pathwayStatus,
            sortable: true,
            width: "100px",
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP42Tooltip}>
                    <span>Policy Total Members</span>
                </OverlayTrigger>
            ),
            selector: row => row.policyMembersTotal,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP11Tooltip}>
                    <span>Pathway Month</span>
                </OverlayTrigger>
            ),
            selector: row => row.pathwayMonth,
            sortable: true,
            width: "100px",
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP29Tooltip}>
                    <span>HBA1C level Initial</span>
                </OverlayTrigger>
            ),
            selector: row => row.initialHBA1C,
            sortable: true,
            width: "100px",
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP30Tooltip}>
                    <span>HBA1C level Current</span>
                </OverlayTrigger>
            ),
            selector: row => row.currentHBA1C,
            sortable: true,
            width: "100px",
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP31Tooltip}>
                    <span>HRA</span>
                </OverlayTrigger>
            ),
            selector: row => row.hra,
            sortable: true,
            width: "100px",
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP32Tooltip}>
                    <span>BMI</span>
                </OverlayTrigger>
            ),
            selector: row => row.bmi,
            sortable: true,
            width: "100px",
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP12Tooltip}>
                    <span>CareNavigator Appointments Count</span>
                </OverlayTrigger>
            ),
            selector: row => row.totalAppointment,
            sortable: true,
        },
        // {
        //     name: (
        //         <OverlayTrigger placement="top" overlay={renderMETP13Tooltip}>
        //             <span>Task Count</span>
        //         </OverlayTrigger>
        //     ),
        //     selector: row => row.taskCount,
        //     sortable: true,
        // },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP14Tooltip}>
                    <span>Services Count</span>
                </OverlayTrigger>
            ),
            selector: row => row.serviceCount,
            sortable: true,
            width: "100px",
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP15Tooltip}>
                    <span>Lab Refferals</span>
                </OverlayTrigger>
            ),
            selector: row => row.labRefferals,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP19Tooltip}>
                    <span>Lab Refferals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.labRefferalsCompleted,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP16Tooltip}>
                    <span>Home Care Referrals</span>
                </OverlayTrigger>
            ),
            selector: row => row.homeCareReferrals,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP20Tooltip}>
                    <span>Home Care Referrals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.homeCareReferralsCompleted,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP17Tooltip}>
                    <span>Medication Referrals</span>
                </OverlayTrigger>
            ),
            selector: row => row.medicationReferrals,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP21Tooltip}>
                    <span>Medication Referrals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.medicationReferralsCompleted,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP18Tooltip}>
                    <span>Telehealth Referrals</span>
                </OverlayTrigger>
            ),
            selector: row => row.telehealthReferrals,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP22Tooltip}>
                    <span>Telehealth Referrals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.telehealthReferralsCompleted,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP33Tooltip}>
                    <span>Member Email</span>
                </OverlayTrigger>
            ),
            selector: row => row.email,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP34Tooltip}>
                    <span>Member Mobile No</span>
                </OverlayTrigger>
            ),
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP35Tooltip}>
                    <span>Gender</span>
                </OverlayTrigger>
            ),
            selector: row => row.gender,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP36Tooltip}>
                    <span>DOB</span>
                </OverlayTrigger>
            ),
            selector: row => row.dob,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP37Tooltip}>
                    <span>Member Network</span>
                </OverlayTrigger>
            ),
            selector: row => row.memberNetwork,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP38Tooltip}>
                    <span>Contract No</span>
                </OverlayTrigger>
            ),
            selector: row => row.contractNo,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP39Tooltip}>
                    <span>Contract Name</span>
                </OverlayTrigger>
            ),
            selector: row => row.contractName,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP44Tooltip}>
                    <span>HOF</span>
                </OverlayTrigger>
            ),
            selector: row => row.isHof,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP45Tooltip}>
                    <span>Height (Cms)</span>
                </OverlayTrigger>
            ),
            selector: row => row.height,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP46Tooltip}>
                    <span>Weight (Lbs)</span>
                </OverlayTrigger>
            ),
            selector: row => row.weight,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP47Tooltip}>
                    <span>Health Risk Category</span>
                </OverlayTrigger>
            ),
            selector: row => row.healthRiskCategory,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP48Tooltip}>
                    <span>Health Risk Segment</span>
                </OverlayTrigger>
            ),
            selector: row => row.healthRiskSegment,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP49Tooltip}>
                    <span>Personal Data</span>
                </OverlayTrigger>
            ),
            selector: row => row.personalData,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP50Tooltip}>
                    <span>Surgical History</span>
                </OverlayTrigger>
            ),
            selector: row => row.surgicalHistory,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP51Tooltip}>
                    <span>Medical History</span>
                </OverlayTrigger>
            ),
            selector: row => row.medicalHistory,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP52Tooltip}>
                    <span>Common Chronic Profile</span>
                </OverlayTrigger>
            ),
            selector: row => row.commonChronicProfile,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP53Tooltip}>
                    <span>Associated Chronic/Advanced Conditions</span>
                </OverlayTrigger>
            ),
            selector: row => row.associatedChronicAdvancedConditions,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP54Tooltip}>
                    <span>Top Providers Visits</span>
                </OverlayTrigger>
            ),
            selector: row => row.topProvidersVisits,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP55Tooltip}>
                    <span>Top Specialties Visits</span>
                </OverlayTrigger>
            ),
            selector: row => row.topSpecialtiesVisits,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP56Tooltip}>
                    <span>Top Doctors Visits</span>
                </OverlayTrigger>
            ),
            selector: row => row.topDoctorsVisits,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP57Tooltip}>
                    <span>Top Conditions By ICD</span>
                </OverlayTrigger>
            ),
            selector: row => row.topConditionsByICD,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP58Tooltip}>
                    <span>OP Cost Per Claims</span>
                </OverlayTrigger>
            ),
            selector: row => row.opCostPerClaims,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP59Tooltip}>
                    <span>IP Cost Per Claims</span>
                </OverlayTrigger>
            ),
            selector: row => row.ipCostPerClaims,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP60Tooltip}>
                    <span>Total YTD</span>
                </OverlayTrigger>
            ),
            selector: row => row.totalYTD,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP61Tooltip}>
                    <span>Accommodation</span>
                </OverlayTrigger>
            ),
            selector: row => row.accommodation,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP62Tooltip}>
                    <span>Consultation</span>
                </OverlayTrigger>
            ),
            selector: row => row.consultation,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP63Tooltip}>
                    <span>Medication</span>
                </OverlayTrigger>
            ),
            selector: row => row.medication,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP64Tooltip}>
                    <span>Laboratories</span>
                </OverlayTrigger>
            ),
            selector: row => row.laboratories,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP65Tooltip}>
                    <span>Advanced Radiology</span>
                </OverlayTrigger>
            ),
            selector: row => row.advancedRadiology,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP66Tooltip}>
                    <span>Surgical Procedures</span>
                </OverlayTrigger>
            ),
            selector: row => row.surgicalProcedures,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP67Tooltip}>
                    <span>Advanced Services</span>
                </OverlayTrigger>
            ),
            selector: row => row.advancedServices,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP68Tooltip}>
                    <span>Dental</span>
                </OverlayTrigger>
            ),
            selector: row => row.dental,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP69Tooltip}>
                    <span>Other Services</span>
                </OverlayTrigger>
            ),
            selector: row => row.otherServices,
            sortable: true,
        },
    ];
    const data = memberData;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedCare('All');
    };

    const filteredData = data.filter(user => {
        const matchesSearchQuery =
            (typeof user.memberName === 'string' && user.memberName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (typeof user.membershipNo === 'string' && user.membershipNo.toString().includes(searchQuery));

        const matchCare = selectedCare === 'All' || user.careName === selectedCare;

        return matchesSearchQuery && matchCare;
    });

    const handleExport = () => {

        const modifiedData = filteredData.map(item => {
            return {
                'Member ID': item.membershipNo,
                'Member Name': item.memberName,
                'Main Membership No.': item.mainMembershipNo,
                'Member Type': item.policyMemberType,
                'Age': item.age,
                'City': item.city,
                'Policy Start Date': item.policyStartDate,
                'Policy End Date': item.policyEndDate,
                'Policy Status': item.policyStatus,
                'Care Navigator': item.careName,
                'Supervisor': item.supervisorName,
                'Onboarded Date': item.enrollmentTime,
                'Program': item.programName,
                'Condition': item.conditionName,
                'Pathway Start Date': item.pathwayStartDate,
                'Initial Pathway': item.initialPathway,
                'Current Pathway': item.pathwayName,
                'Pathway Compltion Date': item.pathwayEndDate,
                'Members Pathway Status': item.pathwayStatus,
                'Policy Total Members': item.policyMembersTotal,
                'Pathway Month': item.pathwayMonth,
                'HbA1c level Initial': item.initialHBA1C,
                'HbA1c level Current': item.currentHBA1C,
                'HRA': item.hra,
                'BMI': item.bmi,
                'CareNavigator Appointments Count': item.totalAppointment,
                'Task Count': item.taskCount,
                'Services Count': item.serviceCount,
                'Lab Refferals': item.labRefferals,
                'Home Care Referrals': item.homeCareReferrals,
                'Medication Referrals': item.medicationReferrals,
                'Telehealth Referrals': item.telehealthReferrals,
                'Member Email': item.email,
                'Member Mobile No': item.mobile,
                'Gender': item.gender,
                'Date of Birth': item.dob,
                'Member Network': item.memberNetwork,
                'Contract No': item.contractNo,
                'HOF': item.isHof,
                'Height (Cms)': item.height,
                'Weight (Lbs)': item.weight,
                'Health Risk Category': item.healthRiskCategory,
                'Health Risk Segment': item.healthRiskSegment,
                'Personal Data': item.personalData,
                'Surgical History': item.surgicalHistory,
                'Medical History': item.medicalHistory,
                'Common Chronic Profile': item.commonChronicProfile,
                'Associated Chronic/Advanced Conditions': item.associatedChronicAdvancedConditions,
                'Top Providers Visits': item.topProvidersVisits,
                'Top Specialties Visits': item.topSpecialtiesVisits,
                'Top Doctors Visits': item.topDoctorsVisits,
                'Top Conditions By ICD': item.topConditionsByICD,
                'OP Cost Per Claims': item.opCostPerClaims,
                'IP Cost Per Claims': item.ipCostPerClaims,
                'Total YTD': item.totalYTD,
                'Accommodation': item.accommodation,
                'Consultation': item.consultation,
                'Medication': item.medication,
                'Laboratories': item.laboratories,
                'Advanced Radiology': item.advancedRadiology,
                'Surgical Procedures': item.surgicalProcedures,
                'Advanced Services': item.advancedServices,
                'Dental': item.dental,
                'Other Services': item.otherServices
            };
        });

        exportTableData("MemberProgressData", modifiedData);
    };

    return (
        <>

            <div className='container-fluid'>

                <div className='row mb-3'>

                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Member Name or Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/wjm48/fh6lw97sch.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedCare}
                            onChange={e => setSelectedCare(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Care Navigator--</option>
                            {careList.length > 0 && careList.map(option => (
                                <option key={option.id} value={option.userName}>
                                    {option.userName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleExport} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
            {isLoading && <CustomLoader />}
        </>
    );


}

export default MemberProgressTable;
