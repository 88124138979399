import React, { useState, useEffect } from 'react';
import {Device} from 'twilio-client'; 
import { fetch_soft_number, 
    fetchsoftphonetoken,
    make_soft_phone_call, 
    cn_call_disconnect, 
    cn_call_hold, 
    cn_call_unhold, 
    cn_call_user_status, 
    soft_number_logger, 
    cn_call_mute
} from '../services/Service';
import firebase from '../../../../firebase/firebase'; 
import { getDatabase, ref, onValue} from "firebase/database";
import { useCnID } from '../../../../App';

const CoachCallAudio = ({userId, changeCallBlockDisplayStatus, memberName, triggerDisposition, mobileNo}) => {
    
    // state for timer 
    const [timer, setTimer] = useState({
        seconds: "00",
        minutes: "00",
        hours: "00",
        isTimerAlreadyStarted: false,
        intervalId: null,
    });     

    const [currDevice, setCurrDevice] = useState(null); 

    const [callStatus, setCallStatus] = useState("idle"); 
    const [displayCallStatus, setDisplayCallStatus] = useState("Calling"); 
    const [isCallOnHold, setIsCallOnHold] = useState(false); 

    const [conferenceNumber, setConferenceNumber] = useState(""); 
    const [copyConferenceNumber, setCopyConferenceNumber] = useState(""); 
    const [shouldShowDisposition, setShouldShowDisposition] = useState(false); 

    const [pollIntervalRef, setPollIntervalRef] = useState(null); 
    
    const [editableMobileNo, setEditableMobileNo] = useState(mobileNo); 
    const [editableMobileNoError, setEditableMobileNoError] = useState("")
    const [callControllEnabled, setCallControllEnabled] = useState(false); 
    const [isCallOnMute, setIsCallOnMute] = useState(false);
    const id = useCnID(); 
    
      // getting clal statuses 
  useEffect(() => {  
    const database = getDatabase(firebase);
    // just read values from this database 
    const dbRef = ref(database);
    let realtimeRef; 
    let unSubscribe; 
    
    if(copyConferenceNumber){ 
       realtimeRef = ref(database, `cnapp/${id}/${copyConferenceNumber}`);
       unSubscribe = onValue(realtimeRef, (snapshot) => {
        const data = snapshot.val();
        
        if (data) {
          if(data.status === "Connected"){ 
            setDisplayCallStatus(data.status); 
            setCallStatus("in-progress"); 
            setCallControllEnabled(true);
          }

          if(data.status === "Ringing") setDisplayCallStatus(data.status); 
          if(data.status === "Calling") setDisplayCallStatus(data.status); 
          
          if(data.status === "Completed"){ 
            setCallStatus("idle");
            setDisplayCallStatus("Calling"); 
            unSubscribe(); 
          }
        } 
      });
    }

  }, [copyConferenceNumber]);

    // timer on call start 
    useEffect(() => {
        // Start timer only once when callStatus becomes "in-progress"
        if (!timer.isTimerAlreadyStarted && callStatus === 'in-progress') {
        toggleTimer();
        setTimer(prevTimer => ({
            ...prevTimer,
            isTimerAlreadyStarted: true,
        }));
        } else if ((callStatus === 'completed' || callStatus === 'idle') && timer.isTimerAlreadyStarted) {
        
            // Stop timer when callStatus becomes "completed" or "idle"
        toggleTimer(true);
        setTimer({
            ...timer, 
            isTimerAlreadyStarted: false,
            intervalId: null,
        });
        }
    }, [callStatus, toggleTimer, timer.isTimerAlreadyStarted]);

   // function to initiate call 
   const makePhoneCall = async (e) => {   

        // validate provided mobile no 
        if(!editableMobileNo || !/^[0-9]+$/.test(editableMobileNo)){  
            setEditableMobileNoError("Please Enter Valid Mobile No"); 
            return; 
        }



       let stream; 
       try{ 
           stream = await navigator?.mediaDevices?.getUserMedia({ audio: true });
           stream.getTracks().forEach(track => track.stop());
       }catch(e){ 
            if (e.name === 'NotAllowedError') {
                alert('Please allow Microphone Permissions'); 
            } else { 
                alert('No microphone Found in your device'); 
            }

           await soft_number_logger({userId, log: {name: e.name, message: e.message}, type: "getUserMedia_coach_call_ausio"});
           return; 
       }

       setShouldShowDisposition(true); 

        setCallStatus('calling'); 
        // setting timer to zero before staring new call
        setTimer({
            seconds: "00",
            minutes: "00", 
            hours: "00", 
            isTimerAlreadyStarted: false,
            intervalId: null,
        });   


        // fetch soft phone number 
        let response; 
        let pollInterval; 
        try{ 
            response = await fetch_soft_number({userId}); 
            
            if(!response && response.data && response.data.code !== 200){ 
                // handle the error 
                return; 
            }
            
            let confNumber = response.data.data.confNumber;

            setConferenceNumber(response.data.data.confNumber); 
            setCopyConferenceNumber(response.data.data.confNumber); 

            let token = await fetchsoftphonetoken({confNumber});

            if(!token && !token.data && token.data.code !== 200){ 
                // handle the error 
                return; 
            }

            
            let {token: softPhoneToken} = token.data; 
            
            // creating device  
            const device = new Device(softPhoneToken, { 
                debug: true,
                audioConstraints: {
                  optional: [
                    { echoCancellation: true },
                    { noiseSuppression: true },
                    { autoGainControl: true },
                  ],
                },
            }); 

            setCurrDevice(device); 
            
            device.on("error", (error) => { 
                
            });

           device.on('ready', () => { 
            device.connect({ From: confNumber });

            device.on("connect", async () => { 
                let makeSoftCall = await make_soft_phone_call({userId, confNumber, mobileNo: editableMobileNo}); 
                // pollInterval = setInterval(async () => { 
                //     setCallControllEnabled(true); 
                //     const callStatusResponse = await cn_call_user_status({confNumber}); 
                    
                //     if(callStatusResponse && callStatusResponse.data )
                //     { 
                //         const status = callStatusResponse.data.callStatusInProgess;
                //         // Enabling call report forms once user will pick up the call
                //         if (status === "in-progress" && !timer.isTimerAlreadyStarted) {
                //             setCallStatus("in-progress");
                //         }
    
                //         if(status === "ringing" && !(callStatus === "disconnecting")) setDisplayCallStatus("Ringing..."); 
    
                //         // Handling Call disconnection               
                
                //         if (
                //             status === "completed" ||
                //             status === "canceled" ||
                //             status === "failed" ||
                //             status === "busy" ||
                //             status === "no-answer"
                //         ) {
                //             clearInterval(pollInterval);
                //             setCallStatus("idle");
                //             setDisplayCallStatus('Calling...'); 
                //         }
                //     }
                // }, 2000);   
    
                setPollIntervalRef(pollInterval); 
 
            });  

           })
            
       
            device.on("disconnect", () => { 

            });

        }catch(error){ 
            // fallback ui
        }
    }    


    // Disconnect call
    async function disconnectCall() {
        clearInterval(pollIntervalRef); 
        setDisplayCallStatus('disconnecting...');
        setCallStatus('disconnecting'); 
        
        try{    
            if(currDevice) currDevice.disconnectAll();     
            triggerDisposition(conferenceNumber); 
            changeCallBlockDisplayStatus(false);             
            const response = await cn_call_disconnect({confNumber: conferenceNumber});
        }catch(error){ 
            // fallback ui
        }
    }

   // function for timer 
   function toggleTimer(stop = false) {
    let { seconds, minutes, hours, isTimerAlreadyStarted, intervalId } = timer;
  
    seconds = parseInt(seconds);
    minutes = parseInt(minutes);
    hours = parseInt(hours);
  
    if (!stop && !isTimerAlreadyStarted) {

      intervalId = setInterval(() => {
        seconds++;
        
        if (seconds === 60) {
          seconds = 0;
          minutes++;
        }
  
        if (minutes === 60) {
          minutes = 0;
          hours++;
        }
  
        const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        const formattedHours = hours < 10 ? "0" + hours : hours;
  
        setTimer({
          seconds: formattedSeconds,
          minutes: formattedMinutes,
          hours: formattedHours,
          isTimerAlreadyStarted: true,
          intervalId: intervalId,
        });
      }, 1000);
  
    } else {
      clearInterval(intervalId);
      setTimer({
        ...timer, 
        isTimerAlreadyStarted: false,
        intervalId: null,
      });
    }
  } 

  async function holdCall(){ 
        try{ 

            let response = await cn_call_hold({confNumber: conferenceNumber}); 
            
            setIsCallOnHold(true); 
        }catch(e){ 

        }
  }

  async function unHoldCall(e){   
     try{
        let response = await cn_call_unhold({confNumber: conferenceNumber}); 
        setIsCallOnHold(false); 
     }catch(e){ 

     }
  }

  // handle mobile change
  function onMobileChange(e){ 
    setEditableMobileNoError(""); 
    setEditableMobileNo(e.target.value);     
  }


  // mute call 
  async function muteCall(){ 
    setIsCallOnMute(true); 
    try{ 
        await cn_call_mute({mute: "Y"}); 
    }catch(e){ }
  }

  async function unMuteCall(){ 
    setIsCallOnMute(false); 
    try{ 
        await cn_call_mute({mute: "N"}); 
    }catch(e){}
  }
  

  return (
      <>
          <span className=' mx-2 me-5'>
              <h2 class="coach-card-title">{memberName}</h2>
              <input type="text" placeholder='Enter Mobile Number' className='form-control control-input-ui-call mt-2' 
                value={editableMobileNo}
                onChange={e => onMobileChange(e)}
              />
              {editableMobileNoError.length > 0 && <p>{editableMobileNoError}</p>}
              {
                callStatus !== "idle" && 
                <>
                    <h3 class="coach-card-subtitle">Call Status: {displayCallStatus}</h3>
                    <h3 class="coach-card-subtitle">Call Duration: {timer.hours} : {timer.minutes} : {timer.seconds}</h3>
                </>
              }
          </span>

        {
            callStatus === "idle" &&
            <>
            <button 
                className="btn btn-dark coach-calling-btn mx-1"
                alt="soft phone call"
                onClick={e => makePhoneCall(e)}
            >   
                <i class="bx bxs-phone"></i>
            </button>


            <button 
                className="btn btn btn-outline-light btn-sm rounded-pill ms-3"
                alt="soft phone call"
                onClick={ e => changeCallBlockDisplayStatus(false)}
            >
                <i class="bi bi-x-lg"></i>
            </button>

            </>
        }
            
        { 
            ( callStatus !== "idle"  &&  callControllEnabled ) &&
            <>

            { 
                !isCallOnMute? 
                <button 
                className="btn btn-dark coach-other-btn mx-2" 
                alt="soft phone call"
                onClick={e => muteCall()}
                >
                    <i class="bx"><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/73j3v/f1y8t4zowa.png" className='icon-call' alt="" /></i>
                </button> : 
                <button className="btn btn-dark coach-other-btn mx-2" 
                alt="soft phone call"
                onClick={e => unMuteCall()}
                >
                    <i class="bx">
                    <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/73j3v/f1y8t4zowa.png" className='icon-call' alt="" /> </i> Unmute
                </button>
            }

            

            
            { 
                !isCallOnHold?
                <button 
                className="btn btn-dark coach-other-btn mx-1"
                 alt="soft phone call"
                 onClick ={ e => holdCall(e)}
                 >
                    <i class="bx" >
                    <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/74b48/7e54fobs41.png" className='icon-call' alt="" /></i>
                </button>
                : 
                <button 
                    className="btn btn-dark coach-other-btn mx-1"
                    alt="soft phone call"
                    onClick={e =>unHoldCall(e)}
                 >
                   <i class="bx">
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/74b48/7e54fobs41.png" className='icon-call' alt="" />
                    </i> UnHold                
                </button>
            }

            <button 
                className="btn btn-dark coach-call-close-btn mx-1" 
                alt="soft phone call"
                onClick={e => disconnectCall(e)}          
            >
                <i class="bx bxs-phone"></i>
            </button>
            </>
         }

          
      </>
  );
}

export default CoachCallAudio;
