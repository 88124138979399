import React from 'react';
import './ProgramPerformancePage.css';
import ContentCareNavigatorPerformance from './componenents/ContentCareNavigatorPerformance';


function CareNavigatorPerformancePage() {

  return (
    <>
        <ContentCareNavigatorPerformance/>
    </>
  );
}

export default CareNavigatorPerformancePage;
