import React from 'react';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';

const MemberCallAttemptList = ({ showModal, handleClose, showMemberList, customStyles }) => {
    const columns = [
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Agent Name',
            selector: row => row.agentName,
            sortable: true,
        },
        {
            name: 'Contacted Date',
            selector: row => row.agentContactDate,
            sortable: true,
            
        },
        {
            name: 'Member Response',
            selector: row => row.enrollStatus,
            sortable: true,
        },
        {
            name: 'Call Notes',
            selector: row => row.notes,
            sortable: true,
        },

    ];


    return (
        <Modal dialogClassName="modal-xl modal-dialog-centered" show={showModal} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title style={{ width: "90%", fontSize: "18px", textAlign: "left"}}>Call Attempt Member List</Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body>
                <DataTable
                    columns={columns}
                    data={showMemberList}
                    pagination
                    paginationPerPage={10}
                    striped
                    responsive
                    noHeader
                    noFooter
                    customStyles={customStyles}
                />
            </Modal.Body>
        </Modal>
    );
};

export default MemberCallAttemptList;
