import React from 'react';
import './ContentUserManagementDetails.css';
// import ContentEditUser from './ContentUserManagement/ContentEditUser';
// import ContentUserManagementDetails from './ContentUserManagement/ContentUserManagementDetails';
import ContentEditUser from './ContentUserManagement/ContentEditUser';


function ContentEditUserManagement() {

  return (
    <>
        <section className='ContentUserManagement'>

            <ContentEditUser />

            {/* <ContentUserManagement /> */}

        </section>


        
    </>
  );
}

export default ContentEditUserManagement;