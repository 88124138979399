import React, { useState, useEffect } from 'react';
import { FaTimes, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NewCampaignModal = ({ isOpen, onClose }) => {
  const [activeOption, setActiveOption] = useState('start');
  const [mounted, setMounted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      setMounted(true);
    } else {
      setMounted(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleStartFromScratch = () => {
    onClose();
    navigate('/create-campaign');
  };

  const WhatsAppPreview = () => (
    <div className="tw-bg-[#E5DDD5] tw-h-full tw-flex tw-flex-col tw-justify-end tw-p-4 tw-rounded-lg tw-shadow-md">
      <div className="tw-bg-white tw-rounded-lg tw-p-3 tw-max-w-[80%] tw-shadow-md">
        <p className="tw-font-semibold tw-mb-1">Browse our catalog right here!</p>
        <p>Hey John 👋</p>
        <p>You can browse through our catalog on WhatsApp itself! 🛍️</p>
        <p className="tw-mb-2">Click below & we will take you through our collections! 📚</p>
        <div className="tw-flex tw-flex-col tw-space-y-2">
          <button className="tw-bg-[#E9EDEF] tw-text-[#1FA855] tw-px-3 tw-py-1.5 tw-rounded tw-text-sm tw-font-medium tw-text-left tw-transition-colors tw-duration-300 hover:tw-bg-[#D1E7DD]">
            VIEW PRODUCTS
          </button>
          <button className="tw-bg-[#E9EDEF] tw-text-[#1FA855] tw-px-3 tw-py-1.5 tw-rounded tw-text-sm tw-font-medium tw-text-left tw-transition-colors tw-duration-300 hover:tw-bg-[#D1E7DD]">
            Stop
          </button>
        </div>
        <p className="tw-text-right tw-text-xs tw-text-gray-500 tw-mt-1">4:51 PM</p>
      </div>
    </div>
  );

  const renderContent = () => {
    switch (activeOption) {
      case 'start':
        return (
          <div className="tw-h-full tw-flex tw-flex-col tw-justify-between">
            <div>
              <h2 className="tw-text-xl tw-font-bold tw-mb-4 tw-text-gray-800">Start from Scratch</h2>
              <p className="tw-text-gray-700 tw-mb-4">Create a new campaign from scratch.</p>
            </div>
            <button onClick={handleStartFromScratch} className="tw-bg-sky-600 tw-text-white tw-px-4 tw-py-2 tw-rounded tw-w-full tw-transition-colors tw-duration-300 hover:tw-bg-sky-700">
              Start Creating
            </button>
          </div>
        );
      case 'browse':
        return (
          <div className="tw-h-full tw-flex tw-flex-col tw-justify-between">
            <div>
              <h2 className="tw-text-xl tw-font-bold tw-mb-4 tw-text-gray-800">Browse Catalog on WhatsApp</h2>
              <h3 className="tw-text-base tw-font-semibold tw-mb-2 tw-text-gray-700">Who will receive this message</h3>
              <p className="tw-text-sm tw-text-gray-600 tw-mb-4">
                All opted-in customers are selected as the default audience. When the customer clicks on
                the "View Products" button, the catalog browsing flow will start. If the customer clicks
                on "Stop", their WhatsApp Opted status will change to False.
              </p>
            </div>
            <p className="tw-text-xs tw-text-gray-500">
              The audience & message template have been pre-set in this sample campaign. You simply need to set the campaign live.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-z-50 tw-transition-opacity tw-duration-300 ${mounted ? 'tw-opacity-100' : 'tw-opacity-0'}`}>
      <style jsx>{`
        @keyframes slideIn {
          from { transform: translateY(-20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }
        .animate-slide-in {
          animation: slideIn 0.3s ease-out forwards;
        }
      `}</style>
      <div className="tw-bg-white tw-rounded-lg tw-w-full tw-max-w-4xl tw-h-[80vh] tw-flex tw-flex-col tw-shadow-2xl animate-slide-in">
        <div className="tw-bg-sky-600 tw-text-white tw-p-4 tw-flex tw-justify-between tw-items-center">
          <h2 className="tw-text-xl tw-font-bold">Create New Campaign</h2>
          <FaTimes className="tw-cursor-pointer tw-transition-transform tw-duration-300 hover:tw-rotate-90" onClick={onClose} />
        </div>
        <div className="tw-flex tw-flex-1 tw-overflow-hidden">
          {/* Sidebar */}
          <div className="tw-w-full md:tw-w-1/3 tw-bg-sky-50 tw-p-4 tw-overflow-y-auto">
            <button
              className={`tw-w-full tw-text-left tw-p-2 tw-rounded tw-mb-2 tw-transition-colors tw-duration-300 ${
                activeOption === 'start' ? 'tw-bg-sky-600 tw-text-white' : 'tw-bg-white tw-text-gray-700 hover:tw-bg-sky-100'
              }`}
              onClick={() => setActiveOption('start')}
            >
              Start from Scratch
            </button>
            <button
              className="tw-w-full tw-text-left tw-p-2 tw-rounded tw-mb-2 tw-bg-gray-200 tw-text-gray-500 tw-cursor-not-allowed"
              disabled
            >
              Choose from Sample Campaigns
            </button>
            <button
              className={`tw-w-full tw-text-left tw-p-2 tw-rounded tw-transition-colors tw-duration-300 ${
                activeOption === 'browse' ? 'tw-bg-sky-600 tw-text-white' : 'tw-bg-white tw-text-gray-700 hover:tw-bg-sky-100'
              }`}
              onClick={() => setActiveOption('browse')}
            >
              Browse Catalog on WhatsApp
            </button>
          </div>

          {/* Main Content */}
          <div className="tw-w-full md:tw-w-2/3 tw-flex tw-flex-col tw-overflow-hidden">
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-flex-1 tw-overflow-hidden">
              <div className="tw-w-full md:tw-w-1/2 tw-p-4 tw-overflow-y-auto">
                {renderContent()}
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-p-4 tw-overflow-y-auto">
                {activeOption === 'browse' && <WhatsAppPreview />}
              </div>
            </div>
            <div className="tw-bg-sky-50 tw-p-4 tw-flex tw-justify-end">
              <button 
                className={`tw-px-4 tw-py-2 tw-rounded tw-transition-colors tw-duration-300 ${
                  activeOption === 'browse'
                    ? 'tw-bg-sky-600 tw-text-white hover:tw-bg-sky-700' 
                    : 'tw-bg-gray-300 tw-text-gray-700 hover:tw-bg-gray-400'
                }`}
              >
                Use Sample
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCampaignModal;