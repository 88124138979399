import React, { useState,useEffect } from 'react';
import './ProgramsPage.css';
// import UpcomingSession from '../componenents/UpcomingSession';
// import Sidenavmenubar from '../../componenents/Sidenavmenubar';
// import Navbar from '../../componenents/Navbar';
import ContentDraftPrograms from '../../componenents/ContentDraftPrograms';



function ProgramsDraftPage() {

  const [title, setTitle] = useState('Programs');
 
return (
    <>
        <ContentDraftPrograms/>
    </>
  );
}

export default ProgramsDraftPage;
