import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import './HRABasic.css';
import { saveHRA, fetchLastHRA } from "../../components/services/Service";
import { compileString } from 'sass';

const HRABasic = ({ getMemberBasicInfo }) => {
    const qsParams = useParams();
    const userId = qsParams.memberId;

    const initialFormData = {
        answer_1: { value: '', question: 'Input weight in kgs', category: '', score: 0 },
        answer_2: { value: '', question: 'Input height in cms', category: '', score: 0 },
        answer_3: { value: '', question: 'What is your goal within the next year?', category: 'Goal', score: 0 },
        answer_3_1: { value: '', question: 'Have you used any weight loss product (pills or injection) or have done any weight loss surgery before?', category: '', score: 0 },
        answer_3_1_specified: { value: '', score: 0 },
        answer_4: { value: [], question: 'Do you have any health conditions?', category: '', score: 0 },
        answer_4_specified: { value: '', score: 0 },
        answer_5: { value: '', question: 'How many glasses of water you drink per day?', category: '', score: 0 },
        answer_6: { value: '', question: 'How often you do exercise?', category: '', score: 0 },
        answer_7: { value: '', question: 'How many meals you eat per day including snacks?', category: '', score: 0 },
        answer_8: { value: [], question: 'Which of the following food groups are included in your daily routine (more than 4 times a week)? You can select more than one', category: '', score: 0 },
        answer_8_specified: { value: '', score: 0 },
        bmi: { weight: '', height: '', value: '', bmiObservation: '' }
    };

    const [formData, setFormData] = useState(initialFormData);

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [bmi, setBMI] = useState("");
    const [bmiObservation, setBMIObservation] = useState("");

    const [hraScore, setHRAScore] = useState("");
    const [hraObservation, setHRAObservation] = useState("");
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");

    useEffect(() => {
        getLastHRA();
    }, [userId]);

    const getLastHRA = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchLastHRA(userId, "basic", ct)
            .then((response) => {
                if (response.data.code === 200) {
                    const hraJson = response.data.data.hraJson;
                    const updatedFormData = { ...formData };
                    Object.keys(hraJson).map((key, index) => {
                        if (Array.isArray(formData[key].value)) {
                            updatedFormData[key].value = hraJson[key].value
                        } else {
                            updatedFormData[key].value = hraJson[key].value;
                        }

                        if (key === "bmi") {
                            updatedFormData['bmi']['weight'] = hraJson['bmi'].weight;
                            updatedFormData['bmi']['height'] = hraJson['bmi'].height;
                            updatedFormData['bmi']['bmiObservation'] = hraJson['bmi'].bmiObservation;
                        }
                    })
                    setFormData(updatedFormData);
                    let bmiW = (hraJson.bmi) ? hraJson.bmi.weight : "";
                    let bmiH = (hraJson.bmi) ? hraJson.bmi.height : "";
                    setWeight(bmiW);
                    setHeight(bmiH);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    useEffect(() => {
        if (weight !== "" && height !== "") {
            //const heightInInches = height / 2.54;
            //let BMI = ((weight * 703) / (heightInInches * heightInInches));
            const heightInMeters = height / 100;
            let BMI = (weight / (heightInMeters * heightInMeters));
            BMI = parseFloat(BMI).toFixed(1)
            let observation = (BMI < 18.5) ? "Underweight" : (BMI >= 18.5 && BMI <= 24.9) ? "Healthy" : (BMI >= 25 && BMI <= 29.9) ? "Overweight" : "Obese";

            setBMI(BMI);
            setBMIObservation(observation);
        } else {
            setBMI("");
            setBMIObservation("");
        }
    }, [weight, height])

    const validateForm = () => {
        setError("");
        let answerFound = false;
        Object.entries(formData).forEach(([key, field]) => {
            const { value, question, category, score } = field;
            if (Array.isArray(value)) {
                if (value.length > 0) answerFound = true;
            } else {
                if (value !== "") answerFound = true;
            }
        });

        /*let err = "";
        if (formData.answer_1.value == "" || formData.answer_1.value == "0") {
            err = "Please enter weight.";
        } else if (formData.answer_2.value == "" || formData.answer_2.value == "0") {
            err = "Please enter height";
        } else if (formData.answer_3.value == "") {
            err = "Please answer all the questions";
        } else if (formData.answer_3.value == "Loose Weight" && formData.answer_3_1.value == "") {
            err = "Please answer all the questions";
        } else if (formData.answer_3_1.value == "Yes" && formData.answer_3_1_specified.value == "") {
            err = "Please specify weight loss product (pills or injection) ";
        } else if (formData.answer_4.value.length <= 0) {
            err = "Please answer all the questions";
        } else if (formData.answer_4.value.includes("Others") && formData.answer_4_specified.value == "") {
            err = "Please specify the other health condition";
        } else if (formData.answer_5.value == "") {
            err = "Please answer all the questions";
        } else if (formData.answer_6.value == "") {
            err = "Please answer all the questions";
        } else if (formData.answer_7.value == "") {
            err = "Please answer all the questions";
        } else if (formData.answer_8.value.length <= 0) {
            err = "Please answer all the questions";
        } else if (formData.answer_8.value.includes("Others") && formData.answer_8_specified.value == "") {
            err = "Please specify the food groups";
        }
        if (err !== "") {
            setError(err);
            return false;
        } else {
            return true;
        }*/
        return answerFound;
    }
    const handleChange = (e) => {
        setError("");
        const { name, value, type, checked } = e.target;

        if (name == "answer_1" || name == "answer_2") {
            if (!/^\d*\.?\d*$/.test(value)) {
                return false;
            }
        }

        if (type === 'checkbox') {
            let updatedValue;
            if (name === 'answer_4') {
                const currentValues = Array.isArray(formData[name].value) ? formData[name].value : [];
                if (value === 'None' && checked) {
                    updatedValue = ['None'];
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        [name]: { ...prevFormData[name], value: updatedValue },
                        answer_4_specified: { ...prevFormData.answer_4_specified, value: '' }
                    }));
                } else if (value !== 'None' && checked) {
                    updatedValue = currentValues.filter(item => item !== 'None');
                    updatedValue.push(value);
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        [name]: { ...prevFormData[name], value: updatedValue }
                    }));
                } else {
                    updatedValue = currentValues.filter(item => item !== value);
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        [name]: { ...prevFormData[name], value: updatedValue }
                    }));
                }
            } else {
                const currentValues = Array.isArray(formData[name].value) ? formData[name].value : [];

                updatedValue = checked
                    ? [...currentValues.filter(item => item !== 'None'), value]
                    : currentValues.filter(item => item !== value);
                setFormData({
                    ...formData,
                    [name]: { ...formData[name], value: updatedValue }
                });
            }
        } else {
            setFormData({ ...formData, [name]: { ...formData[name], value: value } });
            if (name === "answer_1") {
                setWeight(value);
            }
            if (name === "answer_2") {
                setHeight(value);
            }
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage("");
        setError("");
        if (validateForm() === true) {
            const request = axios.CancelToken.source();
            let cancelToken = {
                cancelToken: request.token
            }

            let form = event.target;
            let formDataArr = {};

            let hs = 0;
            let hrao = "";

            Array.from(form.elements).forEach(element => {
                if (element.name) {
                    //console.log(element, element.checked);
                    const indexName = element.name;
                    const score = parseInt(element.getAttribute('attr-score')) || 0;
                    const question = (element.name !== "answer_3_1_specified" && element.name !== "answer_4_specified" && element.name !== "answer_8_specified" && element.name !== "bmi") ? initialFormData[element.name].question : "";
                    const category = (element.name !== "answer_3_1_specified" && element.name !== "answer_4_specified" && element.name !== "answer_8_specified" && element.name !== "bmi") ? initialFormData[element.name].category : "";
                    if (!formDataArr[element.name]) {
                        formDataArr[element.name] = {
                            //value: element.type === 'checkbox' ? [] : element.value,
                            value: element.type === 'checkbox' ? [] : "",
                            score: 0
                        };
                        if (question !== "") {
                            formDataArr[element.name]['question'] = question;
                        }
                        if (category !== "") {
                            formDataArr[element.name]['category'] = category;
                        }
                    }

                    if (element.type === 'checkbox' && element.checked === true) {
                        console.log("Inside for checkbox ", element.name, element.checked);
                        formDataArr[element.name].value.push(element.value);
                        formDataArr[element.name].score += score;
                        hs += score;
                    } else if ((element.type === 'radio' || element.type === 'checkbox') && (element.checked === true)) {
                        console.log("Inside for radio/checkbox ", element.name, element.checked);
                        formDataArr[element.name].value = element.value;
                        formDataArr[element.name].score = score;
                        hs += score;
                    } else if (element.type === 'text' && element.name.includes('_specified')) {
                        const specifyJsonIndex = element.getAttribute('attr-json-index');
                        formDataArr[element.name] = {
                            value: element.value,
                            score: 0
                        };
                    } else if (element.type === 'text') {
                        formDataArr[element.name] = {
                            value: element.value,
                            score: 0
                        };
                    }
                }
            });

            formDataArr["bmi"] = {
                weight: weight,
                height: height,
                value: bmi,
                bmiObservation: bmiObservation
            };

            if (hs <= 0) hrao = "High Risk";
            else if (hs >= 1 && hs <= 7) hrao = "Moderate Risk";
            else if (hs >= 8 && hs <= 13) hrao = "Low Risk";
            else if (hs >= 14) hrao = "Healthy";

            //console.log(JSON.stringify(formDataArr))

            await saveHRA(userId, JSON.stringify(formDataArr), "basic", hs, hrao, "1.0", cancelToken)
                .then((response) => {
                    if (response.data.code === 200) {
                        getMemberBasicInfo();
                        setHRAScore(hs);
                        setHRAObservation(hrao);
                        formDataArr = {};
                        form.reset();
                        setWeight("");
                        setHeight("")
                        setBMI("");
                        setBMIObservation("");

                        setMessage(response.data.data.message);
                    } else {
                        setError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        } else {
            setError("There's nothing to save")
        }
    }
    return (
        <>
            <div className='col-md-12 px-0'>
                <div className='card card-hra'>
                    <div className='card-body'>
                        <div className='card-text'>
                            <form id="hrabasic" onSubmit={handleSubmit} className={`${hraObservation === "" ? "" : "hide"}`}>
                                <div className='row align-items-center'>
                                    <div className='col-md'>
                                        {/* <label htmlFor='fullName' className="form-label">Input weight in pounds</label> */}
                                        <input type="text" name="answer_1" className='form-control control-input-ui' placeholder='Input weight in kilograms' value={formData.answer_1.value} attr-json-index="0" attr-score="0" onChange={handleChange} />
                                    </div>
                                    <div className='col-md'>
                                        {/* <label htmlFor='fullName' className="form-label">Input height in cms</label> */}
                                        <input type="text" name="answer_2" className='form-control control-input-ui' placeholder='Input height in cms' attr-json-index="1" attr-score="0"
                                            value={formData.answer_2.value} onChange={handleChange} />
                                    </div>
                                    <div className='col-md'>
                                        <span className={`${bmiObservation !== "" ? "" : "hide"}`}>BMI: {bmi}  ({bmiObservation})</span>
                                    </div>
                                    <div class="col-12"><hr /></div>
                                </div>

                                <Row>
                                    <Col>
                                        <b>{formData.answer_3.question}</b>
                                    </Col>
                                </Row>
                                <Row className=''>
                                    <Col>
                                        <label>
                                            <input type="radio" name="answer_3" value="Loose Weight" attr-score="0" attr-json-index="2" checked={formData.answer_3.value === 'Loose Weight'}
                                                onChange={handleChange} />
                                            Loose Weight
                                        </label>
                                        <label>
                                            <input type="radio" name="answer_3" value="Gain Weight" attr-score="0" attr-json-index="2" checked={formData.answer_3.value === 'Gain Weight'}
                                                onChange={handleChange} />
                                            Gain Weight
                                        </label>
                                        <label>
                                            <input type="radio" name="answer_3" value="Maintain Weight in a healthier way" attr-score="0" attr-json-index="2" checked={formData.answer_3.value === 'Maintain Weight in a healthier way'}
                                                onChange={handleChange} />
                                            Maintain Weight in a healthier way
                                        </label>
                                    </Col>
                                    <div class="col-12"><hr /></div>
                                </Row>
                                <Row className={`${formData.answer_3.value !== "Loose Weight" ? "hide" : ""}`}>
                                    <Col>
                                        <b>{formData.answer_3_1.question}</b>
                                    </Col>
                                </Row>
                                <Row className={`${formData.answer_3.value !== "Loose Weight" ? "hide" : ""}`}>
                                    <Col>
                                        <label>
                                            <input type="radio" name="answer_3_1" value="Yes" attr-score="0" attr-json-index="3" checked={formData.answer_3_1.value === 'Yes'}
                                                onChange={handleChange} />
                                            Yes
                                        </label>
                                        <label>
                                            <input type="radio" name="answer_3_1" value="YES, I am thinking and would like to get advice" attr-score="0" attr-json-index="3" checked={formData.answer_3_1.value === 'YES, I am thinking and would like to get advice'}
                                                onChange={handleChange} />
                                            YES, I am thinking and would like to get advice
                                        </label>
                                        <label>
                                            <input type="radio" name="answer_3_1" value="NO, I am not interested I would like to reach my goal with healthy diet and exercise" attr-score="0" attr-json-index="3" checked={formData.answer_3_1.value === 'NO, I am not interested I would like to reach my goal with healthy diet and exercise'}
                                                onChange={handleChange} />
                                            NO, I am not interested I would like to reach my goal with healthy diet and exercise
                                        </label>
                                        {
                                            formData.answer_3_1.value === 'Yes' &&
                                            <div>
                                                <label>Specify</label>
                                                <input type="text" name="answer_3_1_specified" className='form-control specify' attr-json-index="3" attr-score="0" value={formData.answer_3_1_specified.value} onChange={handleChange} />
                                            </div>
                                        }

                                    </Col>
                                    <div class="col-12"><hr /></div>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>{formData.answer_4.question}</b>
                                    </Col>
                                </Row>
                                <Row className=''>
                                    <Col>
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_4" value="None" attr-score="0" attr-json-index="4" checked={formData.answer_4.value.includes('None')} onChange={handleChange} />
                                            <label class="form-check-label mt-1">
                                                None
                                            </label>
                                        </div>
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_4" value="Diabetes" attr-score="0" attr-json-index="4" checked={formData.answer_4.value.includes('Diabetes')} onChange={handleChange} />
                                            <label class="form-check-label mt-1"> Diabetes</label>

                                        </div>
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_4" value="Hypercholesterolemia" attr-score="0" attr-json-index="4" checked={formData.answer_4.value.includes('Hypercholesterolemia')} onChange={handleChange} />
                                            <label class="form-check-label mt-1"> Hypercholesterolemia</label>

                                        </div>
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_4" value="Hypertension" attr-score="0" attr-json-index="4" checked={formData.answer_4.value.includes('Hypertension')} onChange={handleChange} />
                                            <label class="form-check-label mt-1"> Hypertension</label>

                                        </div>
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_4" value="Heart Disease" attr-score="0" attr-json-index="4" checked={formData.answer_4.value.includes('Heart Disease')} onChange={handleChange} />
                                            <label class="form-check-label mt-1"> Heart Disease</label>

                                        </div>
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_4" value="Poly Cystic Ovarian Disease" attr-score="0" attr-json-index="4" checked={formData.answer_4.value.includes('Poly Cystic Ovarian Disease')} onChange={handleChange} />
                                            <label class="form-check-label mt-1"> Poly Cystic Ovarian Disease</label>

                                        </div>
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_4" value="Others" attr-score="0" attr-json-index="4" checked={formData.answer_4.value.includes('Others')} onChange={handleChange} />
                                            <label class="form-check-label mt-1"> Others</label>

                                        </div>
                                        {
                                            formData.answer_4.value.includes('Others') &&
                                            <div>
                                                <label class="form-label">Specify</label>
                                                <input type="text" name="answer_4_specified" className='form-control control-input-ui specify' attr-json-index="4" attr-score="0" value={formData.answer_4_specified.value} onChange={handleChange} />
                                            </div>
                                        }
                                    </Col>
                                    <div class="col-12"><hr /></div>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>{formData.answer_5.question}</b>
                                    </Col>
                                </Row>
                                <Row className=''>
                                    <Col>
                                        <label>
                                            <input type="radio" name="answer_5" value="2-4 Glasses" attr-score="0" attr-json-index="5" checked={formData.answer_5.value === '2-4 Glasses'} onChange={handleChange} />
                                            2-4 Glasses
                                        </label>
                                        <label>
                                            <input type="radio" name="answer_5" value="4-8 Glasses" attr-score="1" attr-json-index="5" checked={formData.answer_5.value === '4-8 Glasses'} onChange={handleChange} />
                                            4-8 Glasses
                                        </label>
                                        <label>
                                            <input type="radio" name="answer_5" value="8-12 Glasses or More" attr-score="2" attr-json-index="5" checked={formData.answer_5.value === '8-12 Glasses or More'} onChange={handleChange} />
                                            8-12 Glasses or More
                                        </label>
                                    </Col>
                                    <div class="col-12"><hr /></div>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>{formData.answer_6.question}</b>
                                    </Col>
                                </Row>
                                <Row className=''>
                                    <Col>
                                        <label>
                                            <input type="radio" name="answer_6" value="No exercises" attr-score="0" attr-json-index="6" checked={formData.answer_6.value === 'No exercises'} onChange={handleChange} />
                                            No exercises
                                        </label>
                                        <label>
                                            <input type="radio" name="answer_6" value="1-3 times per week" attr-score="1" attr-json-index="6" checked={formData.answer_6.value === '1-3 times per week'} onChange={handleChange} />
                                            1-3 times per week
                                        </label>
                                        <label>
                                            <input type="radio" name="answer_6" value="3-5 times per weeks or above" attr-score="2" attr-json-index="6" checked={formData.answer_6.value === '3-5 times per weeks or above'} onChange={handleChange} />
                                            3-5 times per weeks or above
                                        </label>
                                    </Col>
                                    <div class="col-12"><hr /></div>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>{formData.answer_7.question}</b>
                                    </Col>
                                </Row>
                                <Row className=''>
                                    <Col>
                                        <label>
                                            <input type="radio" name="answer_7" value="Only 1" attr-score="0" attr-json-index="7" checked={formData.answer_7.value === 'Only 1'} onChange={handleChange} />
                                            Only 1
                                        </label>
                                        <label>
                                            <input type="radio" name="answer_7" value="2-3 meals per day" attr-score="1" attr-json-index="7" checked={formData.answer_7.value === '2-3 meals per day'} onChange={handleChange} />
                                            2-3 meals per day
                                        </label>
                                        <label>
                                            <input type="radio" name="answer_7" value="4-5 meals per day or more" attr-score="2" attr-json-index="7" checked={formData.answer_7.value === '4-5 meals per day or more'} onChange={handleChange} />
                                            4-5 meals per day or more
                                        </label>
                                    </Col>
                                    <div class="col-12"><hr /></div>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>{formData.answer_8.question}</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Complex carbs (rice, oat, brown bread and brown pasta, quinoa)" attr-score="2" attr-json-index="8" checked={formData.answer_8.value.includes('Complex carbs (rice, oat, brown bread and brown pasta, quinoa)')} onChange={handleChange} />
                                            <label class="form-check-label mt-1"> Complex carbs (rice, oat, brown bread and brown pasta, quinoa)</label>

                                        </label>
                                        <br />
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Simple carbs (white bread, white flour, cakes, and sweets. etc.)" attr-score="0" attr-json-index="8" checked={formData.answer_8.value.includes('Simple carbs (white bread, white flour, cakes, and sweets. etc.)')} onChange={handleChange} />
                                            <label class="form-check-label mt-1"> Simple carbs (white bread, white flour, cakes, and sweets. etc.)</label>
                                        </label>
                                        <br />
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Fruit & vegetable (salad, cooked or raw)" attr-score="2" attr-json-index="8" checked={formData.answer_8.value.includes('Fruit & vegetable (salad, cooked or raw)')} onChange={handleChange} />
                                            <label class="form-check-label mt-1">Fruit & vegetable (salad, cooked or raw)</label>
                                        </label>
                                        <br />
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Animal source of protein (chicken, fish, meat, egg. etc.)" attr-score="2" attr-json-index="8" checked={formData.answer_8.value.includes('Animal source of protein (chicken, fish, meat, egg. etc.)')} onChange={handleChange} />
                                            <label class="form-check-label mt-1">Animal source of protein (chicken, fish, meat, egg. etc.)</label>
                                        </label>
                                        <br />
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Plant source of protein (beans, legume, tofu, nuts. etc.)" attr-score="2" attr-json-index="8" checked={formData.answer_8.value.includes('Plant source of protein (beans, legume, tofu, nuts. etc.)')} onChange={handleChange} />
                                            <label class="form-check-label mt-1">Plant source of protein (beans, legume, tofu, nuts. etc.)</label>
                                        </label>
                                        <br />
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Sugary drinks (fizzy drinks, juices, energy drinks)" attr-score="0" attr-json-index="8" checked={formData.answer_8.value.includes('Sugary drinks (fizzy drinks, juices, energy drinks)')} onChange={handleChange} />
                                            <label class="form-check-label mt-1">Sugary drinks (fizzy drinks, juices, energy drinks)</label>
                                        </label>
                                        <br />
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Healthy fat (nuts, olive oil, seeds)" attr-score="2" attr-json-index="8" checked={formData.answer_8.value.includes('Healthy fat (nuts, olive oil, seeds)')} onChange={handleChange} />
                                            <label class="form-check-label mt-1">Healthy fat (nuts, olive oil, seeds)</label>
                                        </label>
                                        <br />
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Fatty and processed food (fried food, fast food, chips, cakes, sausage, processed or creamy cheeses)" attr-score="0" attr-json-index="8" checked={formData.answer_8.value.includes('Fatty and processed food (fried food, fast food, chips, cakes, sausage, processed or creamy cheeses)')} onChange={handleChange} />
                                            <label class="form-check-label mt-1">Fatty and processed food (fried food, fast food, chips, cakes, sausage, processed or creamy cheeses)</label>
                                        </label>
                                        <br />
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Salty products (canned food, pretzels, crackers, and potato chips)" attr-score="0" attr-json-index="8" checked={formData.answer_8.value.includes('Salty products (canned food, pretzels, crackers, and potato chips)')} onChange={handleChange} />
                                            <label class="form-check-label mt-1">Salty products (canned food, pretzels, crackers, and potato chips)</label>
                                        </label>
                                        <br />
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Caffein (coffee, tea)" attr-score="1" attr-json-index="8" checked={formData.answer_8.value.includes('Caffein (coffee, tea)')} onChange={handleChange} />
                                            <label class="form-check-label mt-1">Caffein (coffee, tea)</label>
                                        </label>
                                        <br />
                                        <label>
                                            <input type="checkbox" className='form-check-input form-check-input-ui' name="answer_8" value="Others" attr-score="0" attr-json-index="8" checked={formData.answer_8.value.includes('Others')} onChange={handleChange} />
                                            <label class="form-check-label mt-1">Others</label>
                                        </label>
                                        <br />
                                        {
                                            formData.answer_8.value.includes('Others') &&
                                            <div>
                                                <label class="form-label">Specify</label>
                                                <input type="text" name="answer_8_specified" className='form-control control-input-ui specify' attr-json-index="8" attr-score="0" value={formData.answer_8_specified.value} onChange={handleChange} />
                                            </div>
                                        }
                                    </Col>
                                    <div className='col-md-12 text-center mt-2'>
                                        <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                                        <Button variant="primary" className="btn btn-primary save-btn" type="submit">Save HRA</Button>
                                    </div>
                                </Row>
                            </form>
                            <div className={`${hraObservation === "" ? "hide" : ""}`}>
                                <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>
                                <span>HRA Score: {hraScore} ({hraObservation})</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HRABasic