import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

function CorporateChallengesTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTitle, setSelectedTitle] = useState('All');

    const columns = [
        {
            name: 'Leader Board',
            selector: row => row.leaderBoard,
            sortable: true,
            cell: row => (
                <a href="#" dangerouslySetInnerHTML={{ __html: row.leaderBoard }}></a>
            ),
        },
        {
            name: 'Challenge Title',
            selector: row => row.challengeTitle,
            sortable: true,
        },
        {
            name: 'Challenge Description',
            selector: row => row.challengeDescription,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.endDate,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <div>
                    <button className="btn btn-Edit mx-2">
                        <i className="bi bi-pencil"></i>
                    </button> 
                    |
                    <button className="btn btn-Cancel mx-2">
                        <i className="bi bi-trash"></i>
                    </button>
                </div>
            ),
        },
    ];

    const data = [
        {
            id: 1,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 123',
            challengeDescription: 'Description of Walkathon Warriors 1',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 2,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 2',
            challengeDescription: 'Description of Walkathon Warriors 2',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 3,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 3',
            challengeDescription: 'Description of Walkathon Warriors 3',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 4,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 4',
            challengeDescription: 'Description of Walkathon Warriors 4',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 5,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 5',
            challengeDescription: 'Description of Walkathon Warriors 5',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 6,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 6',
            challengeDescription: 'Description of Walkathon Warriors 6',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 7,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 7',
            challengeDescription: 'Description of Walkathon Warriors 7',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 8,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 8',
            challengeDescription: 'Description of Walkathon Warriors 8',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 9,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 9',
            challengeDescription: 'Description of Walkathon Warriors 9',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 10,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 10',
            challengeDescription: 'Description of Walkathon Warriors 10',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedTitle('All');
    };

    const filteredData = data.filter(item => {
        const matchesSearchQuery =
            item.challengeTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.challengeDescription.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesTitle = selectedTitle === 'All' || item.challengeTitle === selectedTitle;

        return matchesSearchQuery && matchesTitle;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Challenge or Leader Board"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedTitle}
                            onChange={e => setSelectedTitle(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Challenge Title--</option>
                            <option value="Walkathon Warriors 123">Walkathon Warriors 1</option>
                            <option value="Walkathon Warriors 2">Walkathon Warriors 2</option>
                            <option value="Walkathon Warriors 3">Walkathon Warriors 3</option>
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default CorporateChallengesTable;
