import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import {Link} from 'react-router-dom';
import { LoaderLoader, exportExcelTableData, formatDate } from "../../utils/CommonLibrary";
import { fetch_uploaded_user_data_list } from '../../../services/met/Service';
import { FilterdashboardMember } from '../../met/utils/EngagementUtility';
import { Commingsoon } from '../../met/utils/CommonLibrary';

function MemberEngagementTable({startDate,endDate}) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRole, setSelectedRole] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [userDataList, setUserDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState('All');


    useEffect(() => {
        fetctUploadedUserDataList();
    }, [startDate,endDate]);

    const fetctUploadedUserDataList = async () =>{
        setIsLoading(true); //to show loader
    try {
        let sdate=formatDate(startDate);
        let edate=formatDate(endDate);
        let resp = await fetch_uploaded_user_data_list(sdate,edate);
        if (resp.data.code === 200) {
           let result =  resp.data.data;
            setUserDataList(result);
        }
    } catch (error) {
        console.error(error);
    }
    finally {
        setIsLoading(false);  // Will be called regardless of success or failure
    }
}

    const columns = [
        {
            name: 'Date Uploaded',
            selector: row => row.dateUploaded,
            sortable: true,
        },
        {
            name: 'Uploaded Members',
            selector: row => row.uploadedMembers,
            sortable: true,
            
        },
        {
            name: 'Enrolled Members',
            selector: row => row.enrolledMembers,
            sortable: true,
        },
        {
            name: 'Not Enrolled (%)',
            selector: row => row.notEnrolled,
            sortable: true,
        },
        {
            name: 'Program Name',
            selector: row => row.programName,
            sortable: true,
        },
        {
            name: 'Uploaded By',
            selector: row => row.uploadedBy,
            sortable: true,
        },
        {
            name: 'Progress',
            selector: row => row.progressStatus, // Changed to progressStatus for filtering
            sortable: true,
            cell: row => (
                <span>
                    {row.progressSteps} <br/>
                    <span className={row.progressStatus}>{row.progressStatus}</span>
                </span>
            ),
        },
        {
            name: 'Action',
            selector: row => row.action,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            
            cell: row => (
                <span>
                    <a data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal" style={{cursor: "pointer"}}><i className="bi bi-journal-text"></i></a>
                </span>
            ),
        },
    ];

    const data = userDataList;

    

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('All');
        setSelectedStatus('All');
    };

    const filteredData = data.filter(user => {
        // const matchesSearchQuery =
        //     item.dateUploaded.toLowerCase().includes(searchQuery.toLowerCase()) ||
        //     item.uploadedMembers.toLowerCase().includes(searchQuery.toLowerCase()) ||
        //     item.enrolledMembers.toLowerCase().includes(searchQuery.toLowerCase()) ||
        //     item.notEnrolled.toLowerCase().includes(searchQuery.toLowerCase()) ||
        //     item.programName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        //     item.uploadedBy.toLowerCase().includes(searchQuery.toLowerCase());

       

        const matchesSearchQuery = 
        (typeof user.programName === 'string' && user.programName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof user.uploadedMembers === 'number' && user.uploadedMembers.toString().includes(searchQuery)) ||
        (typeof user.dateUploaded === 'date' && user.uploadedMembers.toString().includes(searchQuery));
    
        const matchesStatus = selectedStatus === 'All' || user.progressStatus === selectedStatus;

        const matchProgram = selectedProgram === 'All' || user.programName === selectedProgram;

        return matchesSearchQuery && matchesStatus && matchProgram;
        
       
     

        //return matchesSearchQuery && matchesRole && matchesStatus;
    });


    const handleProgramChange = (event) => {
        setSelectedProgram(event.target.value);
        console.log('Selected Program:', event.target.value);
      };

      const handleExport = (downloadType) => {
        if(downloadType === 'excel'){

            const modifiedData = filteredData.map(item => {
                return {
                    'Date Uploaded': item.dateUploaded,
                    'Uploaded Members': item.uploadedMembers,
                    'Enroll Members': item.enrolledMembers,
                    'Not Enrolled': item.notEnrolled,
                    'Program Name': item.programName,
                    'Uploaded By': item.uploadedBy,
                    'Progress': item.progressSteps+'/'+item.progressStatus,
                };
            });
            // modifiedData.forEach(function(v){ 
            //     delete v.userId;
               
            //   });
            exportExcelTableData("member_engagement_automation",modifiedData);
        }
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        {/* <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="Search Icon" />
                        </div> */}
                    </div>
                    <div className='col-md-3 px-1'>
                        {/* <select
                            value={selectedRole}
                            onChange={e => setSelectedRole(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Program--</option>
                            <option value="Diabetes">Diabetes</option>
                            <option value="Hypertension">Hypertension</option>
                            <option value="Depression">Depression</option>
                        </select> */}
                         {/* <FilterdashboardMember onChange={handleProgramChange} /> */}

                    </div>
                    <div className='col-md-3 px-1'>
                        {/* <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Progress--</option>
                            <option value="In-progress">In-progress</option>
                            <option value="Completed">Completed</option>
                            <option value="Paused">Paused</option>
                            <option value="Not started">Not started</option>
                        </select> */}
                    </div>
                    <div className='col-md-auto px-1'>
                        {/* <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button> */}
                        <div className="dropdown">
                            <button className="btn btn-primary btn-primary clear-search-btn " type="button"  onClick={(e)=>handleExport('excel')}>
                                <i className="bi bi-download"> </i> Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <LoaderLoader isLoading={isLoading} />
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />

            <Commingsoon/>
        </>
    );
    
    
}

export default MemberEngagementTable;
