import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);

const MembersAcrossProgramsBar = (props) => {
  // Define your data
  // const programs = ['A', 'B', 'C', 'D', 'E'];
  // const membersEnrolled = [50, 75, 100, 125, 150];

  const programs= props.category;
  const membersEnrolled = props.series;
  const chartType = props.chartType;

  const yAxisJson = {
    title: {
      text: ''
    }
  };

  const tooltipJson = {
    shared: true,
    valueSuffix: ' members'
  };

  const columnData = [{
    name: 'Members',
    data: membersEnrolled,
    color: '#1279be'
  }];

  const options1 = {
    chart: {
      type: chartType,
      height: 220,
      spacing: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 15,
        depth: 50,
        viewDistance: 25 
      }
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    xAxis: {
      categories: programs,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    yAxis: {
      visible: false // Add the visible property to hide the y-axis
    },
    plotOptions: {
      column: {
        borderRadius: 20, // Set border radius for columns
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: columnData,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: true
    },
    legend: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options1}
      />
    </div>
  );
};

export default MembersAcrossProgramsBar;
