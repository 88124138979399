import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { fetchMembersDisenroll, fetchProgramList } from '../../../services/medengage/Service';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';

function PastRequestTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('All');
    const [selectedCondition, setSelectedCondition] = useState('All');
    const [programList, setProgramList] = useState([]);
    const [conditionList, setConditionList] = useState([]);
    const [newMembersDisenrollList, setNewMembersDisenrollList] = useState([])
    const [isNewLoading, setIsNewLoading] = useState(false);

    useEffect(() => {
        fetchNewMembersDisenrollList();
        fetchDropDownList();
    }, []);

    const fetchDropDownList = async () => {
        await fetchProgramList()
            .then((response) => {
                setProgramList(response.data.data.programList);
                setConditionList(response.data.data.conditionList);
            });
    }

    const fetchNewMembersDisenrollList = async () => {
        setIsNewLoading(true);
        try {
            const response = await fetchMembersDisenroll();

            if (response.data.status === 'success') {
                setNewMembersDisenrollList(response.data.data.filter(data => data.requestStatus !== 'Pending'));
            } else {
                setNewMembersDisenrollList([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsNewLoading(false);
        }
    }

    const formatDate = (inputDate) => {
        if (!inputDate) return '';
        const date = new Date(inputDate);

        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

        return formattedDate;
    }

    const columns = [
        {
            name: 'Request Date',
            selector: row => row.disEnrollRequestedDate,
            format: row => row.disEnrollRequestedDate,
            sortable: true,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership ID',
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Mobile No',
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: 'Program',
            selector: row => row.carePrograms,
            sortable: true,
        },
        {
            name: 'Condition',
            selector: row => row.chronicConditions,
            sortable: true,
        },
        {
            name: 'Enrollment Date',
            selector: row => row.enrollmentTime,
            format: row => row.enrollmentTime,
            sortable: true,
        },
        {
            name: 'Care Navigator Name',
            selector: row => row.careName,
            sortable: true,
        },
        {
            name: 'Supervisor',
            selector: row => row.supervisorName,
            sortable: true,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: true,
        },
        {
            name: 'Care Navigator Remarks',
            selector: row => row.disEnrollmentNote,
            sortable: true,
            cell: row => (
                <div dangerouslySetInnerHTML={{ __html: row.disEnrollmentNote }} />
            ),
        },
        {
            name: 'Action Taken By',
            selector: row => row.approvedBy,
            sortable: true,
        },
        {
            name: 'Date Actioned',
            id: 'approvedDate',
            selector: row => row.approvedDate,
            format: row => row.approvedDate,
            sortable: true,
        },
        {
            name: 'Dis-EnrollmentType',
            id: 'disEnrollmentType',
            selector: row => row.disEnrollmentType,
            format: row => row.disEnrollmentType,
            sortable: true,
        },
        {
            name: 'Care Coordinator Remarks',
            id: 'aprrovedNote',
            selector: row => row.aprrovedNote,
            format: row => row.aprrovedNote,
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: row => (
                <>
                    <span style={{ color: row.requestStatus.includes('Approved') ? '#1279be' : '#1279be' }}>
                        {row.requestStatus.includes('Approved') ? <i className="bi bi-check-lg"></i> : <i className="bi bi-x-lg"></i>} {row.requestStatus}
                    </span>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('All');
        setSelectedCondition('All');
    };

    const filteredData = newMembersDisenrollList.filter(user => {
        const matchesSearchQuery =
            user.memberName.toLowerCase().includes(searchQuery.toLowerCase()) || user.membershipNo == searchQuery;

        const matchesProgram = selectedProgram === 'All' || user.carePrograms.toLowerCase() === selectedProgram.toLowerCase();
        const matchesCondition = selectedCondition === 'All' || user.chronicConditions.toLowerCase() === selectedCondition.toLowerCase();
        return matchesSearchQuery && matchesProgram && matchesCondition;
    });

    const handleExport = () => {

        const modifiedData = filteredData.map(item => {
            return {
                'Request Date': item.disEnrollRequestedDate,
                'Member ID': item.membershipNo,
                'Main Membership ID': item.mainMembershipNo,
                'Member Name': item.memberName,
                'Email': item.email,
                'Mobile No': item.mobile,
                'Program': item.carePrograms,
                'Condition': item.chronicConditions,
                'Enrollment Date': item.enrollmentTime,
                'Care Navigator Name': item.careName,
                'Supervisor Name': item.supervisorName,
                'Reason': item.reason,
                'Care Navigator Remarks': item.disEnrollmentNote,
                'Action Taken By': item.approvedBy,
                'Date Actioned': item.approvedDate,
                'Dis-EnrollmentType': item.disEnrollmentType,
                'Care Coordinator Remarks': item.aprrovedNote,
                'Status': item.requestStatus
            };
        });


        modifiedData.forEach(function (v) { delete v.disEnrollBy; delete v.logId; delete v.membershipNo; delete v.mobile; delete v.userId; });

        exportTableData("De-EnrollPastRequest", modifiedData);
    };

    return (
        <>
            <LoaderLoader isLoading={isNewLoading} />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Member Name  or Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedProgram}
                            onChange={e => setSelectedProgram(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">All Programs</option>
                            {programList.map(option => (
                                <option key={option.programId} value={option.programName}>{option.programName}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedCondition}
                            onChange={e => setSelectedCondition(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">All Conditions</option>
                            {conditionList.map(option => (
                                <option key={option.conditionId} value={option.conditionName}>{option.conditionName}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleExport} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
                defaultSortFieldId='approvedDate'
                defaultSortAsc={false}
            />
        </>
    );
}

export default PastRequestTable;
